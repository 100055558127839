import React, { useState } from 'react';
import toast from 'react-hot-toast';
import $ from 'jquery';
import {
      EnquiryContainer,
      EnquiryModal
} from '../../../common/EnquiryBox/style';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import FormButton from '../../../forms/FormButton';
import FormTextarea from '../../../forms/FormTextarea';
import FormInput from '../../../forms/FormInput';
import axios from 'axios';

const EnquiryForm = ({ productId, handleClose, handleShow, show }) => {
      const [loading, setLoading] = useState(false);

      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [message, setMessage] = useState('');
      const [company, setCompany] = useState('');
      const [address, setAddress] = useState('');

      const handleSubmitForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone_number: phoneNumber,
                  message: message,
                  company: company,
                  address: address
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/product_enquiry/${productId}`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              handleClose();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.company) {
                                    toast.error(response.data.message.company);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <EnquiryContainer>
                        <EnquiryModal
                              className="enquiry__form"
                              show={show}
                              onHide={handleClose}
                              size="lg"
                        >
                              <Modal.Header closeButton>
                                    <Modal.Title>
                                          Product Enquiry Form
                                    </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                    <Form onSubmit={handleSubmitForm}>
                                          <Row className="g-3">
                                                <FormInput
                                                      title="First Name"
                                                      handleChange={
                                                            setFirstName
                                                      }
                                                      required={true}
                                                />
                                                <FormInput
                                                      title="Last Name"
                                                      handleChange={setLastName}
                                                      required={true}
                                                />
                                                <FormInput
                                                      title="Email Address"
                                                      handleChange={setEmail}
                                                      type="email"
                                                      required={true}
                                                />

                                                <FormInput
                                                      title="Phone No."
                                                      required={true}
                                                      handleChange={
                                                            setPhoneNumber
                                                      }
                                                />
                                                <FormInput
                                                      title="Company Name"
                                                      handleChange={setCompany}
                                                      type="text"
                                                      required={false}
                                                />
                                                <FormInput
                                                      title="Address"
                                                      handleChange={setAddress}
                                                      type="text"
                                                      required={true}
                                                />
                                                <FormTextarea
                                                      title="Your Query"
                                                      required={true}
                                                      classes={12}
                                                      handleChange={setMessage}
                                                />
                                                <FormButton loading={loading} />
                                          </Row>
                                    </Form>
                              </Modal.Body>
                        </EnquiryModal>
                  </EnquiryContainer>
            </>
      );
};

export default EnquiryForm;
