import React from 'react';

const FlowerIcon = () => {
      return (
            <>
                  <svg
                        version="1.1"
                        id="fi_53775"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="978.673px"
                        height="978.673px"
                        viewBox="0 0 978.673 978.673"
                        style={{ enableBackground:"new 0 0 978.673 978.673" }}
                        xmlSpace="preserve"
                  >
                        <g>
                              <path
                                    d="M64.126,214.123c35.6,82.801,95.7,164.7,179.8,200.001c9.6,4,20.5,7,31.7,9.5c22.4-33.4,48.1-60.501,78.2-82.7
		c25.899-19,53-33.9,80.899-45.601c-8-5.6-16.399-10.7-25.2-15.3c-35-18.3-72.899-34.4-109.8-51.7c34.7,9.4,74.5,12.9,107.7,25
		c20.7,7.601,39.8,18.101,57.6,30.601c53.101-18.101,108.601-26.801,164-33.4c-44.899-74.6-140.7-134.5-212.9-161.6
		c-75.4-28.301-160.8-31.801-244.7-40.5c-51.4-5.301-107.3-24.5-148.2-46.9c-9.3-5.1-19.2,3.4-16,13.7
		C27.326,79.523,37.526,152.323,64.126,214.123z"
                              ></path>
                              <path
                                    d="M950.627,204.323c-94.601,51.4-203.101,57.7-309.4,69.9c-51.899,6-103.3,13.399-152.201,28.2
		c-10.6,3.199-21.199,6.8-31.6,10.8c-30.9,11.8-60.7,27.3-88.8,48c-26.4,19.399-48,42.4-66.2,67.7
		c-29,40.399-49.2,86.899-65.8,135.2c-30.3,87.699-44.2,180.899-41.5,273.6c1.1,37.6,1.7,76.7,11,113.4
		c2.1,8.1,4.1,18.399,10.4,24.399c8.2,7.9,22.9-0.2,19.6-11.7c-23.8-81.1-9-175.899,23.9-253.399
		c37.8-89.101,110.8-142.101,200-174.4c23.7-8.6,47.901-16.1,72.5-23.2c0.5-0.199,1-0.199,1.5-0.199c4.9,0,7,7.199,1.9,9.5
		c-29,13.1-57.8,26.3-85.201,40.6c-134.2,70-192.8,233-142.5,233c4.101,0,8.9-1.1,14.4-3.4c77.6-32.1,169.8-32.399,255.1-48.199
		c30-5.5,59.2-13,86.601-24.4c108.8-45.4,186.6-134.2,232.899-241c34.5-79.7,47.9-173.8,74-256.601c3.4-10.6-4.1-19.699-13.3-19.699
		C955.627,202.323,953.127,202.924,950.627,204.323z"
                              ></path>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                  </svg>
            </>
      );
};

export default FlowerIcon;
