import React from 'react'
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../styles/components/globalstyle';
import { lightTheme } from '../styles/components/themes';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from '../layout/header';
import PublicRouter from '../router';
import Footer from '../layout/footer';
import './style.css';
import { useEffect, useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import { Toaster } from 'react-hot-toast';
import { MetaContextProvider } from '../stores/MetaContext';

const App = () => {
      const [show, setShow] = useState(true);

      useEffect(() => {
            const script = document.createElement('script');
            script.src = '/js/confetti.js'; // Replace with the correct path
            script.async = true;
            document.body.appendChild(script);
            return () => {
                  document.body.removeChild(script);
            };
      }, []);

      const handleClose = () => {
            setShow(false);

            const canvasElement = document.getElementById('confetti-canvas');
            if (canvasElement) {
                  document.body.removeChild(canvasElement);
            }
      };

      return (
            <>
                  {/* <BannerModal show={show} handleClose={handleClose} /> */}
                  <MetaContextProvider>
                        <GlobalStyle />
                        <ThemeProvider theme={lightTheme}>
                              <Toaster position="top-right" />
                              <Router>
                                    <ScrollToTop />
                                    <Header />
                                    <PublicRouter />
                                    <Footer />
                              </Router>
                        </ThemeProvider>
                  </MetaContextProvider>
            </>
      );
};
export default App;
