import { Container } from "react-bootstrap";
import styled from "styled-components";

export const AboutContainer = styled.div``;
export const AboutTitle = styled.h2`
      color: ${(props) => props.theme.primary};
      font-size: 30px;
      margin-bottom: 25px;
      font-weight: 500;
`;
export const AboutDescription = styled.div`
      font-size: 15px;
      text-align: justify;
      line-height: 26px;
`;
export const AboutBottomContainer = styled.div`
      margin-bottom: 3rem;

      @media screen and (max-width: 578px) {
            margin-bottom: 8rem;

            .row {
                  .col-lg-6:first-child {
                        order: 2;
                  }
                  .col-lg-6:last-child {
                        order: 1;
                        margin-top: 3rem;
                  }
            }
      }
`;
export const AboutBottomContent = styled.div`
      h3 {
            color: ${(props) => props.theme.primary};
            font-size: 28px;
      }
      p {
            font-size: 15px;
            text-align: justify;
            line-height: 26px;
      }
`;
export const AboutSupplier = styled.div`
      margin-bottom: 100px;
      padding: 50px 0px;
      background-color: ${(props) => props.theme.paint};

      @media screen and (max-width: 578px) {
            margin-bottom: 0px;
      }
`;

export const NepalMap = styled.div``;

export const AboutSupplierContent = styled.div`
      padding-top: 80px;

      @media screen and (max-width: 578px) {
            padding-top: 0px;
      }
`;
export const AboutSupplierTitle = styled.h3`
      color: ${(props) => props.theme.white};
      margin-bottom: 20px;
`;
export const AboutSupplierDescription = styled.p`
      color: #fbf6e9;
`;

// team member testimonial section
export const TeamMember = styled.div``;
export const TeamMemberContainer = styled.div`
      background: ${(props) => props.theme.primary};
      margin-bottom: 50px;
`;
export const TeamMemberDirector = styled(Container)`
      background: ${(props) => props.theme.paint};
      transform: translateY(50px);

      @media screen and (max-width: 578px) {
            .row {
                  .col-lg-6:first-child {
                        order: 2;
                  }
                  .col-lg-6:last-child {
                        order: 1;
                  }
            }
      }
`;
export const TeamMemberContent = styled.div.attrs(() => ({
      className: "d-flex justify-content-center flex-column w-100 h-100 ",
}))`
      padding: 0px 70px;
      p {
            color: #c9c9c9;
            font-size: 15px;
            font-weight: 400;
      }
      h3 {
            font-size: 17px;
            color: #fff;
      }
      h4 {
            font-size: 16px;
            color: #fff;
            font-weight: 300;
      }

      @media screen and (max-width: 578px) {
            padding: 20px;
            padding-top: 30px;
      }
`;

export const TeamMemberDirectorImage = styled.div``;

export const TeamMembers = styled(Container)`
      margin-top: 100px;
      h3 {
            color: ${(props) => props.theme.primary};
            margin-bottom: 20px;
      }
      p {
            font-size: 14px;
      }
`;
export const TeamMembersContent = styled.div`
      h3 {
            color: ${(props) => props.theme.paint};
      }
`;

export const TeamMembersImage = styled.div`
      position: relative;

      img {
            border-radius: 15px;
      }

      @media screen and (max-width: 578px) {
            height: 320px;

            img {
                  height: 100%;
                  object-fit: contain;
            }
      }
`;
