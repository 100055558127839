import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { ProductCategoryContainer } from './styles';
import { Container } from 'react-bootstrap';
import RightSidebar from './components/RightSidebar';
import LeftSidebar from './components/LeftSidebar';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const ProductCategory = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('products');

      return (
            <>
                  <BreadcrumbBox
                        title="Product Category"
                        background="product"
                  />
                  <ProductCategoryContainer>
                        <Container fluid="fluid" className="p-0">
                              {!loading
                                    ? categories?.length > 0
                                          ? categories?.map((category, index) =>
                                                  index % 2 === 0 ? (
                                                        <RightSidebar
                                                              num={`0${category.index}`}
                                                              image={
                                                                    category.image
                                                              }
                                                              link={`/${category.slug}`}
                                                              title={
                                                                    category.title
                                                              }
                                                              desc={
                                                                    <div
                                                                          dangerouslySetInnerHTML={{
                                                                                __html: category.description
                                                                          }}
                                                                    ></div>
                                                              }
                                                        />
                                                  ) : (
                                                        <LeftSidebar
                                                              image={
                                                                    category.image
                                                              }
                                                              link={`/${category.slug}`}
                                                              title={
                                                                    category.title
                                                              }
                                                              num={`0${category.index}`}
                                                              desc={
                                                                    <div
                                                                          className="d-flex justify-content-end"
                                                                          dangerouslySetInnerHTML={{
                                                                                __html: category.description
                                                                          }}
                                                                    ></div>
                                                              }
                                                        />
                                                  )
                                            )
                                          : null
                                    : null}
                        </Container>
                  </ProductCategoryContainer>
            </>
      );
};

export default ProductCategory;
