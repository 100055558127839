import styled from "styled-components";

export const PolicyContainer = styled.div`
      margin-top: 3rem;
      margin-bottom: 3rem;

      @media screen and (max-width: 578px) {
            .container {
                  padding: 0;
            }
      }
`;

export const PolicyContent = styled.div`
      margin-bottom: 3rem;
      background-color: #eee;
      padding: 30px 35px;

      h2 {
            font-size: 24px;
      }

      h3 {
            font-size: 20px;
      }

      p {
            text-align: justify;
      }
      a {
            color: ${(props) => props.theme.primary};
            text-decoration: none;
      }
`;
