import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { DownloadsContainer, SearchButton } from './styles';
import { Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SearchForm from './SearchForm';
import MetaContext from '../../stores/MetaContext';
import { HiddenText } from '../../layout/header/styles';

const Downloads = () => {
      const [downloads, setDownloads] = useState([]);
      const [categories, setCategories] = useState([]);
      const [loading, setLoading] = useState(false);
      const [search, setSearch] = useState('');
      const [updatedDownloads, setUpdatedDownloads] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/downloads`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDownloads(response.data.files);
                              setUpdatedDownloads(response.data.files);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = (event) => {
            event.preventDefault();
            setLoading(true);
            if (search === 'All') {
                  setDownloads(updatedDownloads);
            } else {
                  setDownloads(
                        updatedDownloads.filter(
                              (item) => item.category === search
                        )
                  );
            }
            setLoading(false);
      };

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('downloads');

      return (
            <>
                  <BreadcrumbBox title="Downloads" background="product" />
                  <DownloadsContainer>
                        <Container>
                              <Row className="d-flex justify-content-center flex-column">
                                    <Col lg={8}>
                                          <Col lg={12} className="mb-5">
                                                <Form onSubmit={handleForm}>
                                                      <Row>
                                                            <SearchForm
                                                                  categories={
                                                                        categories
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  setSearch={
                                                                        setSearch
                                                                  }
                                                            />

                                                            <Col
                                                                  lg={4}
                                                                  mg={4}
                                                                  className=" d-flex gap-3"
                                                            >
                                                                  <SearchButton type="submit">
                                                                        <div>
                                                                              <i className="bx bx-search-alt-2"></i>
                                                                        </div>
                                                                        <div>
                                                                              Search
                                                                        </div>
                                                                  </SearchButton>
                                                            </Col>
                                                      </Row>
                                                </Form>
                                          </Col>
                                          <Table hover>
                                                <thead>
                                                      <tr>
                                                            <th scope="col">
                                                                  S.N
                                                            </th>
                                                            <th scope="col">
                                                                  Title
                                                            </th>
                                                            <th scope="col">
                                                                  Type
                                                            </th>
                                                            <th scope="col">
                                                                  Download
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            downloads?.length >
                                                            0 ? (
                                                                  downloads?.map(
                                                                        (
                                                                              download,
                                                                              index
                                                                        ) => (
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <th scope="row">
                                                                                          {
                                                                                                download.index
                                                                                          }
                                                                                    </th>
                                                                                    <td>
                                                                                          {
                                                                                                download.title
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                download.category
                                                                                          }
                                                                                    </td>

                                                                                    <td className="text-center">
                                                                                          <Link
                                                                                                to={
                                                                                                      download.image
                                                                                                }
                                                                                                download={
                                                                                                      true
                                                                                                }
                                                                                          >
                                                                                                <HiddenText>
                                                                                                      Download
                                                                                                      Now
                                                                                                </HiddenText>
                                                                                                <i className="bx bx-cloud-download"></i>
                                                                                          </Link>
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    4
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              file
                                                                              has
                                                                              been
                                                                              found
                                                                        </td>
                                                                  </tr>
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              4
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        <Spinner />
                                                                  </td>
                                                            </tr>
                                                      )}
                                                </tbody>
                                          </Table>
                                    </Col>
                              </Row>
                        </Container>
                  </DownloadsContainer>
            </>
      );
};

export default Downloads;
