import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { PolicyContainer, PolicyContent } from '../PrivacyPolicy/styles';
import { Col, Container, Row } from 'react-bootstrap';
import PolicyHeader from '../../components/common/PolicyHeader';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const DealerPolicy = () => {
      const [loading, setLoading] = useState(false);
      const [page, setPage] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/dealer-policy`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPage(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>
                              {page.seo_title ? page.seo_title : page.title}
                        </title>
                        <link rel="canonical" href={window.location.href} />
                        {page.seo_description && (
                              <meta
                                    name="description"
                                    content={page.seo_description}
                              />
                        )}
                        {page.seo_keyword && (
                              <meta name="keyword" content={page.seo_keyword} />
                        )}
                  </Helmet>
                  <BreadcrumbBox title="Dealer Policy" background="product" />

                  <PolicyContainer>
                        <Container>
                              <PolicyHeader title="Privacy Policy" />
                              <Row className="align-items-center justify-content-center">
                                    <Col lg={10} md={12} sm={12}>
                                          <PolicyContent>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: page.description
                                                      }}
                                                ></div>
                                          </PolicyContent>
                                    </Col>
                              </Row>
                        </Container>
                  </PolicyContainer>
            </>
      );
};

export default DealerPolicy;
