import React, { useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { EnquiryContainer, EnquiryModal } from './style';
import axios from 'axios';
import FormInput from '../../forms/FormInput';
import FormTextarea from '../../forms/FormTextarea';
import FormButton from '../../forms/FormButton';
import toast from 'react-hot-toast';
import $ from 'jquery';

const Enquiry = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [loading, setLoading] = useState(false);

      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [message, setMessage] = useState('');

      const handleSubmitForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone_number: phoneNumber,
                  message: message
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer_enquiry`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              handleClose();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <EnquiryContainer>
                        <div className="contact__button">
                              <Button
                                    variant="btn"
                                    onClick={handleShow}
                                    className="d-flex justify-content-between align-items-center rotated__text gap-2 border-0"
                              >
                                    <div className="text-container">
                                          Enquiry Now
                                    </div>
                                    <span className="material-symbols-outlined">
                                          double_arrow
                                    </span>
                              </Button>
                        </div>
                        <EnquiryModal
                              className="enquiry__form"
                              show={show}
                              onHide={handleClose}
                              size="lg"
                        >
                              <Modal.Header closeButton>
                                    <Modal.Title>
                                          Fill Free to fill the form details
                                    </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                    <Form onSubmit={handleSubmitForm}>
                                          <Row className="g-3">
                                                <FormInput
                                                      title="First Name"
                                                      handleChange={
                                                            setFirstName
                                                      }
                                                      required={true}
                                                />
                                                <FormInput
                                                      title="Last Name"
                                                      handleChange={setLastName}
                                                      required={true}
                                                />
                                                <FormInput
                                                      title="Email Address"
                                                      handleChange={setEmail}
                                                      type="email"
                                                      required={true}
                                                />
                                                <FormInput
                                                      title="Phone No."
                                                      required={true}
                                                      handleChange={
                                                            setPhoneNumber
                                                      }
                                                />
                                                <FormTextarea
                                                      title="Your Query"
                                                      required={true}
                                                      classes={12}
                                                      handleChange={setMessage}
                                                />
                                                <FormButton loading={loading} />
                                          </Row>
                                    </Form>
                              </Modal.Body>
                        </EnquiryModal>
                  </EnquiryContainer>
            </>
      );
};

export default Enquiry;
