import styled from 'styled-components';

export const FaqContainer = styled.div`
      background-size: contain;
      ${
            '' /* background-image: url(/images/faqs/flower-parallax-color.webp); */
      }
      background-repeat: no-repeat;
      background-position: left center;
      padding-top: 80px;
      margin: 0px 0px 100px;
`;
export const FaqHeading = styled.div`
      position: relative;

      &:after {
            background: url(/images/faqs/title-border.webp) no-repeat;
            bottom: -40px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 32px;
            right: 0;
            margin: auto;
      }
`;
export const FaqQuestions = styled.div``;
