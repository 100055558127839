import React from 'react';
import {
      AboutButton,
      AboutContent,
      AboutTitle,
      ColorContainer,
      ColorContent,
      ColorFirstBottom,
      ColorFirstImageContainer,
      ColorFirstTop,
      ColorImage,
      ColorImageContainer,
      ColorSecondImage,
      ColorSecondImageContainer,
      ColorSection
} from '../styles';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ColorCollection from '../../../components/home/ColorSector/ColorCollection';

const ColorBox = () => {
      return (
            <>
                  <ColorSection>
                        <Container>
                              <Row>
                                    <Col lg={5} sm={12}>
                                          <ColorImage>
                                                <ColorFirstImageContainer>
                                                      <ColorFirstTop>
                                                            <img
                                                                  src="/images/img/color/Dark-Blue-Office-Red-Chair-416x520px.webp"
                                                                  alt="Yeti Paints Dark Blue Background"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </ColorFirstTop>
                                                      <ColorFirstBottom
                                                            horizontal="flex-end"
                                                            linearGradient="true"
                                                      >
                                                            <img
                                                                  src="/images/img/color/teal-paint-kitchen-cabinets-416x520px.webp"
                                                                  alt="Yeti Paints Teal Paints Background"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </ColorFirstBottom>
                                                </ColorFirstImageContainer>
                                                <ColorSecondImageContainer>
                                                      <ColorFirstBottom
                                                            linearAnimation="true"
                                                            vertical="flex-end"
                                                      >
                                                            <img
                                                                  src="/images/img/color/Purple-Blue-Green-Open-Paint-Brushes-Faces-Of-Design-416x520.webp"
                                                                  alt="yeti paints logo"
                                                                  className="bottom"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </ColorFirstBottom>
                                                </ColorSecondImageContainer>
                                          </ColorImage>
                                    </Col>
                                    <Col lg={7} sm={12}>
                                          <ColorContainer>
                                                <AboutContent>
                                                      <AboutTitle className="w-md-50">
                                                            <h2>
                                                                  [ Shade Finder
                                                                  ]
                                                            </h2>
                                                            <h3>
                                                                  Colours that
                                                                  suit your
                                                                  dream home.
                                                            </h3>
                                                      </AboutTitle>
                                                      <AboutButton>
                                                            <Link to="/color-shade">
                                                                  Find Your
                                                                  Shade
                                                                  <span class="material-symbols-outlined">
                                                                        arrow_right_alt
                                                                  </span>
                                                            </Link>
                                                      </AboutButton>
                                                </AboutContent>
                                                <ColorContent>
                                                      <ColorCollection />
                                                </ColorContent>
                                          </ColorContainer>
                                    </Col>
                              </Row>
                        </Container>
                  </ColorSection>
            </>
      );
};

export default ColorBox;
