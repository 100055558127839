import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.header``;
export const TopHeader = styled.div`
      border-top: 7px solid ${(props) => props.theme.primary};
      .expandable-element {
            height: 0px; /* Initial height */
            transition: height 0.3s; /* Smooth transition effect */
            overflow: hidden;
      }
      .expandable-element.expanded {
            height: 70px; /* Expanded height */
            overflow: visible;
      }
`;

export const BottomHeader = styled.div`
      position: relative;
`;

export const MenuContainer = styled.ul`
      display: inline-block;
      margin-bottom: 0;
      li {
            a {
                  color: ${({ isVisible }) => (isVisible ? '#fff' : '#fff')};
            }
      }
`;

export const MenuItemContainer = styled.li`
      a {
            position: relative;
            overflow: hidden;
            display: block;
            transition: all 0.3s ease 0s;
            &:after {
                  content: attr(data-title);
                  position: absolute;
                  left: 0;
                  top: 0;
                  display: block;
                  width: 100%;
                  transform: translateY(-100%);
                  transition: all 0.4s;
                  color: #fff;
            }
            span {
                  display: block;
                  transform: translateY(0);
                  transition: all 0.4s;
            }
            &:hover {
                  span {
                        transform: translateY(100%);
                        transition: all 0.4s;
                  }
                  &:after {
                        transform: translateY(0);
                  }
            }
      }
`;

export const HeaderTool = styled.div`
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 568px) {
            display: none;
      }
`;

export const EntryTool = styled.div`
      position: relative;
      svg {
            width: 200px;
            fill: ${(props) => props.theme.primary};
      }
      a {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
      }
`;
export const HeaderWrapper = styled.div`
      width: 100%;
      height: 100%;
      background: ${(props) => props.theme.primary};
      ${'' /* display: none; */}
`;

export const HeaderContent = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;

      @media screen and (max-width: 568px) {
            padding: 10px 0;
      }
`;
export const HeaderLogo = styled(Link)`
      img {
            width: 150px;
            cursor: pointer;
      }
`;
export const HeaderInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 1.5rem;
      justify-content: space-around;

      @media screen and (max-width: 568px) {
            display: none;
      }
`;

export const HeaderInfoContent = styled(Link)`
      color: #212121;
      text-decoration: none;
`;
export const HeaderInfoContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      img {
            width: 27px;
            height: 27px;
      }
      h4 {
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 600;
      }
      p {
            margin-bottom: 0px;
            font-size: 13px;
      }
      &:hover {
            h4 {
                  color: ${(props) => props.theme.primary};
            }
            p {
                  color: ${(props) => props.theme.primary};
            }
      }
`;
export const HeaderInfoTitle = styled.h4``;
export const HeaderContact = styled.div`
      position: relative;

      img {
            width: 150px;
            height: auto;
      }
      a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            text-align: center;
      }
`;

export const DropdownContent = styled.div`
      position: absolute;
      top: 113px;
      left: -17px;
      z-index: 10000;
      background-color: #ffffff;
      width: 188px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all linear;
      ul li {
            padding: 15px 10px 15px 20px !important;
            list-style: none;
            border-bottom: 1px solid ${(props) => props.theme.primary};
            a {
                  font-size: 15px;
                  color: #585660;
                  padding: 0;
                  transition: 0.2s ease-in-out;
            }
            &:hover {
                  a {
                        padding-left: 3px;
                        transition: 0.2s ease-in-out;
                        color: var(--primary__color);
                  }
            }
      }
`;
export const HeaderNav = styled.div.attrs(() => ({
      className: 'd-flex align-items-center justify-content-center w-100 h-100'
}))`
      ${MenuItemContainer} {
            &:hover {
                  ${DropdownContent} {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(-35px);
                        transition: 0.3s all linear;
                  }
            }
      }
`;

export const HiddenText = styled.div`
      position: absolute;
      top: -10000em;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
`;
