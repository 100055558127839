import React from 'react';

const InformationIcon = () => {
   return (
      <>
         <svg
            id="fi_13243769"
            enableBackground="new 0 0 60 60"
            viewBox="0 0 60 60"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path d="m12.1103516 44h25.4755859l7.7070313 7.7070313c.1914062.1914062.4472656.2929687.7070312.2929687.1289063 0 .2587891-.0249023.3828125-.0761719.3740234-.1547851.6171875-.5195312.6171875-.9238281v-7h.8896484c3.3691407 0 6.1103516-2.7949219 6.1103516-6.2299805v-23.540039c0-3.4350586-2.7412109-6.2299805-6.1103516-6.2299805h-35.7792968c-3.3691407 0-6.1103516 2.7949219-6.1103516 6.2299805v23.5400391c0 3.4350585 2.7412109 6.2299804 6.1103516 6.2299804zm-4.1103516-29.7700195c0-2.3325196 1.84375-4.2299805 4.1103516-4.2299805h35.7792969c2.2666015 0 4.1103515 1.8974609 4.1103515 4.2299805v23.5400391c0 2.3325195-1.84375 4.2299804-4.1103516 4.2299804h-1.8896484c-.5527344 0-1 .4477539-1 1v5.5859375l-6.2929688-6.2929688c-.1874999-.1874999-.4414062-.2929687-.7070312-.2929687h-25.8896484c-2.2666016 0-4.1103516-1.8974609-4.1103516-4.2299805z"></path>
            <path d="m27 40h6c.5527344 0 1-.4477539 1-1v-16c0-.5522461-.4472656-1-1-1h-6c-.5527344 0-1 .4477539-1 1v16c0 .5522461.4472656 1 1 1zm1-16h4v14h-4z"></path>
            <path d="m30 20c2.2060547 0 4-1.7944336 4-4s-1.7939453-4-4-4-4 1.7944336-4 4 1.7939453 4 4 4zm0-6c1.1025391 0 2 .8969727 2 2s-.8974609 2-2 2-2-.8969727-2-2 .8974609-2 2-2z"></path>
         </svg>
      </>
   );
};

export default InformationIcon;
