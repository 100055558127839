import React, { useContext } from 'react';
import {
      CategoryBox,
      ColorBox,
      SubcriptionBox,
      VisualizerBox
} from './components';
import AboutBox from './components/AboutBox';

import HomeBanner from '../../components/sliders/HomeBanner';
import TestimonialBox from './components/TestimonialBox';
import Enquiry from '../../components/common/EnquiryBox';
import BlogBox from './components/BlogBox';
import MetaContext from '../../stores/MetaContext';

const Home = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('home');

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta && metaCtx.meta.seo_title}
                  </h1>
                  <Enquiry />
                  <HomeBanner />
                  {/* <BannerBox /> */}
                  <AboutBox />
                  <CategoryBox />
                  <VisualizerBox />
                  <ColorBox />
                  <TestimonialBox />
                  <BlogBox />
                  <SubcriptionBox />
            </>
      );
};

export default Home;
