import styled from 'styled-components';

export const ColorShadeTabContainer = styled.div``;
export const ColorCodeWrapper = styled.div``;

export const ColorCode = styled.span`
      width: 60px;
      height: 45px;
      background: ${(props) => props.colorCode};
`;
export const ColorCodeName = styled.div``;

export const ColorCodeCataglogue = styled.div`
      ${ColorCodeWrapper} {
            border-radius: 5px;
      }
      ${ColorCode} {
            &:first-child {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
            }
            &:last-child {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
            }
      }
      ${ColorCodeName} {
            font-size: 14px;
            color: #555;
      }
`;

export const ColorShadeCodeWrapper = styled.div`
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: 14% 14% 14% 14% 14% 14%;
      grid-gap: 45px;
`;
export const ColorShadeCode = styled.div`
      width: 11%;
      border-radius: 4px;
      ${'' /* max-width: 200px; */}
      height: 170px;
`;

export const ColorShadeCodeInner = styled.div`
      height: 100%;
      width: 100%;
      border-radius: 130px 0px 130px 0px;
      background: ${(props) => props.colorCode};
`;

export const ColorShadeCodeName = styled.h2`
      margin-top: 20px;
      font-size: 14px;
      color: #666;
      text-align: center;
`;
