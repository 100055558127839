import React, { useState } from 'react';
import { EnquiryModal } from '../../../components/common/EnquiryBox/style';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import FormInput from '../../../components/forms/FormInput';
import FormTextarea from '../../../components/forms/FormTextarea';
import FormButton from '../../../components/forms/FormButton';
import axios from 'axios';
import toast from 'react-hot-toast';
import $ from 'jquery';
import FormFile from '../../../components/forms/FormFile';

const CareerForm = ({ show, handleClose, career, careerId }) => {
      const [loading, setLoading] = useState(false);
      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [dateOfBirth, setDataOfBirth] = useState('');
      const [experience, setExperience] = useState('');
      const [education, setEducation] = useState('');
      const [aboutYourself, setAboutYourself] = useState('');
      const [gender, setGender] = useState('');
      const [permanentAddress, setPermanentAddress] = useState('');
      const [temporaryAddress, setTemporaryAddress] = useState('');
      const [resume, setResume] = useState('');

      const handleForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone_number: phoneNumber,
                  date_of_birth: dateOfBirth,
                  experience: experience,
                  education: education,
                  about_yourself: aboutYourself,
                  gender: gender,
                  permanent_address: permanentAddress,
                  current_address: temporaryAddress,
                  resume: resume
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`,
                        data,
                        {
                              headers: {
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              $('form').trigger('reset');
                              handleClose();
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.gender) {
                                    toast.error(response.data.message.gender);
                              }
                              if (response.data.message.date_of_birth) {
                                    toast.error(
                                          response.data.message.date_of_birth
                                    );
                              }
                              if (response.data.message.education) {
                                    toast.error(
                                          response.data.message.education
                                    );
                              }
                              if (response.data.message.experience) {
                                    toast.error(
                                          response.data.message.experience
                                    );
                              }
                              if (response.data.message.permanent_address) {
                                    toast.error(
                                          response.data.message
                                                .permanent_address
                                    );
                              }
                              if (response.data.message.current_address) {
                                    toast.error(
                                          response.data.message.current_address
                                    );
                              }
                              if (response.data.message.resume) {
                                    toast.error(response.data.message.resume);
                              }
                              if (response.data.message.about_yourself) {
                                    toast.error(
                                          response.data.message.about_yourself
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <EnquiryModal show={show} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                              <Modal.Title>
                                    Apply for {career.title}
                              </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <section className="career_form-section">
                                    <Container>
                                          <Row className="justify-content-center">
                                                <Col lg={12}>
                                                      <div className="career-form__inner">
                                                            <Form
                                                                  onSubmit={
                                                                        handleForm
                                                                  }
                                                            >
                                                                  <Row className="g-3">
                                                                        <FormInput
                                                                              title="First Name"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setFirstName
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              title="Last Name"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setLastName
                                                                              }
                                                                        />

                                                                        <FormInput
                                                                              title="Email Address"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setEmail
                                                                              }
                                                                        />

                                                                        <FormInput
                                                                              title="Phone No."
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setPhoneNumber
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              title="Date of Birth"
                                                                              type="date"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setDataOfBirth
                                                                              }
                                                                        />
                                                                        <Col
                                                                              lg={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <label
                                                                                    for=""
                                                                                    className="form-label"
                                                                              >
                                                                                    Gender
                                                                                    <span className="text-danger">
                                                                                          *
                                                                                    </span>
                                                                              </label>
                                                                              <select
                                                                                    className="form-select"
                                                                                    onChange={(
                                                                                          event
                                                                                    ) =>
                                                                                          setGender(
                                                                                                event
                                                                                                      .target
                                                                                                      .value
                                                                                          )
                                                                                    }
                                                                              >
                                                                                    <option>
                                                                                          Select
                                                                                          One
                                                                                    </option>

                                                                                    <option value="Male">
                                                                                          Male
                                                                                    </option>
                                                                                    <option value="Female">
                                                                                          Female
                                                                                    </option>
                                                                              </select>
                                                                        </Col>
                                                                        <FormInput
                                                                              title="Current Address"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setTemporaryAddress
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              title="Permanent Address"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setPermanentAddress
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              title="Education"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setEducation
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              title="Experience"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleChange={
                                                                                    setExperience
                                                                              }
                                                                        />
                                                                        <FormFile
                                                                              title="Resume"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="file"
                                                                              handleChange={
                                                                                    setResume
                                                                              }
                                                                        />
                                                                        <FormTextarea
                                                                              title="About Yourself"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              classes={
                                                                                    12
                                                                              }
                                                                              handleChange={
                                                                                    setAboutYourself
                                                                              }
                                                                        />
                                                                        <FormButton
                                                                              title="Submit"
                                                                              loading={
                                                                                    loading
                                                                              }
                                                                        />
                                                                  </Row>
                                                            </Form>
                                                      </div>
                                                </Col>
                                          </Row>
                                    </Container>
                              </section>
                        </Modal.Body>
                  </EnquiryModal>
            </>
      );
};

export default CareerForm;
