import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const EnquiryContainer = styled.div`
      .contact__button {
            position: fixed;
            top: 50%;
            left: -60px;
            z-index: 100;
            background: #d90117;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            padding: 0px 7px;
            border-radius: 10px 10px 0px 0px;

            button {
                  color: ${(props) => props.theme.white};

                  span {
                        font-size: 16px;
                  }
            }

            .material-symbols-outlined {
                  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0,
                        'opsz' 24;
            }
      }

      @media screen and (max-width: 578px) {
            display: none;
      }
`;

export const EnquiryModal = styled(Modal)`
      .modal-header {
            align-items: center;

            .modal-title {
                  font-size: 18px;
            }

            .btn-close {
                  margin: 0 !important;
                  padding-top: 0;
                  position: static;
                  --bs-btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");

                  &:focus {
                        box-shadow: none;
                  }
            }
      }
`;
