import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import BlogItemBox from '../../components/blog/BlogItemBox';

const BlogCategoryPage = () => {
      const { categoryId } = useParams();
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);
      const [category, setCategory] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${categoryId}/category`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                              setCategory(response.data.category);
                        }
                  })
                  .catch((error) => {
                        console.log(error);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [categoryId]);

      return (
            <>
                  <BreadcrumbBox title={`Blogs By ${category.title}`} />

                  <Container className="my-5 pb-5">
                        <Row className="g-4">
                              {blogs?.length &&
                                    blogs.map((blog, index) => (
                                          <Col lg={4} key={index}>
                                                <BlogItemBox blog={blog} />
                                          </Col>
                                    ))}
                        </Row>
                  </Container>
            </>
      );
};

export default BlogCategoryPage;
