import React, { useEffect, useState } from 'react';
import {
      AboutButton,
      AboutTitle,
      CategoryHeading,
      CategoryImage,
      CategorySection
} from '../styles';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import ProductCategorySlider from '../../../components/home/CategorySector/ProductCategorySlider';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CategoryBox = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`)
                  .then((response) => {
                        if (response.data.result == 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <CategorySection>
                        <Container className="px-0">
                              <Row className="mt-5  g-0">
                                    <Col lg={12}>
                                          <CategoryHeading>
                                                <AboutTitle>
                                                      <h2>[ Our Product ] </h2>
                                                      <h3>
                                                            Discover colours for
                                                            your walls
                                                      </h3>
                                                </AboutTitle>
                                                <AboutButton>
                                                      <Link to="/products">
                                                            Explore More
                                                            <span class="material-symbols-outlined">
                                                                  arrow_right_alt
                                                            </span>
                                                      </Link>
                                                </AboutButton>
                                          </CategoryHeading>
                                          <ProductCategorySlider
                                                loading={loading}
                                                categories={categories}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </CategorySection>
            </>
      );
};

export default CategoryBox;
