import React, { useState } from 'react';
import { ContactUsForm } from '../styles';
import { Form, Row } from 'react-bootstrap';
import UserIcon from '../../../components/icons/UserIcon';
import MailIcon from '../../../components/icons/MailIcon';
import TelephoneIcon from '../../../components/icons/TelephoneIcon';
import InformationIcon from '../../../components/icons/InformationIcon';
import PenIcon from '../../../components/icons/PenIcon';
import ContactFormItem from '../ContactFormItem';
import FormButton from '../../../components/forms/FormButton';
import axios from 'axios';
import toast from 'react-hot-toast';
import $ from 'jquery';

const ContactForm = () => {
      const [loading, setLoading] = useState(false);

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [subject, setSubject] = useState('');
      const [message, setMessage] = useState('');

      const handleSubmitForm = async (event) => {
            setLoading(true);
            event.preventDefault();
            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  subject: subject,
                  message: message
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.subject) {
                                    toast.error(response.data.message.subject);
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      return (
            <>
                  <ContactUsForm>
                        <Form onSubmit={handleSubmitForm}>
                              <Row className="g-3">
                                    <ContactFormItem
                                          title="Name"
                                          icon={<UserIcon />}
                                          handleChange={setName}
                                    />
                                    <ContactFormItem
                                          title="Email Address"
                                          icon={<MailIcon />}
                                          type="email"
                                          handleChange={setEmail}
                                    />
                                    <ContactFormItem
                                          title="Phone Number"
                                          icon={<TelephoneIcon />}
                                          handleChange={setPhoneNumber}
                                    />

                                    <ContactFormItem
                                          title="Subject "
                                          icon={<InformationIcon />}
                                          handleChange={setSubject}
                                    />
                                    <ContactFormItem
                                          title="How can we help you? Feel free to get in touch!"
                                          classes={12}
                                          type="textarea"
                                          icon={<PenIcon />}
                                          handleChange={setMessage}
                                    />
                                    <FormButton
                                          title="Send Enquiry"
                                          loading={loading}
                                    />
                              </Row>
                        </Form>
                  </ContactUsForm>
            </>
      );
};

export default ContactForm;
