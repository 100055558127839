import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { useParams } from 'react-router-dom';
import {
      ProductListContainer,
      ProductListFilter,
      ProductListSection,
      ResultContainer
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import ProductFilter from '../../components/product/ProductFilter';
import ProductCard from '../../components/product/ProductCard';
import axios from 'axios';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import MetaContext from '../../stores/MetaContext';
import { Helmet } from 'react-helmet';

const Product = () => {
      const { categoryId } = useParams();
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [categories, setCategories] = useState([]);
      const [category, setCategory] = useState([]);
      const [loading, setLoading] = useState(false);

      const [selectedCuisines, setSelectedCuisines] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);

      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/categories/${categoryId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setCategory(response.data.category);
                              setCategories(response.data.categories);
                              setTotalProducts(response.data.totalProducts);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [categoryId]);

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === 'category') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === 'subCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === 'childCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };

      const changePrice = async () => {
            const filters = [];

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'category'
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: 'subCategory'
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: 'childCategory'
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCuisines(selectedCuisinesIds);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(',')}`);
            }

            if (search) {
                  filters.push(`search=${search}`);
            }

            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/categories/${categoryId}${
                  filters.length > 0 ? '?' + filters.join('&') : '?'
            }`;

            await axios
                  .get(apiUrl)
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);

                        const queryParams = new URLSearchParams();

                        selectedCuisinesIds.length &&
                              queryParams.append(
                                    'categories',
                                    selectedCuisinesIds.join(',')
                              );

                        search && queryParams.append('search', search);

                        const newUrl = `${
                              window.location.pathname
                        }?${queryParams.toString()}`;
                        window.history.replaceState(null, '', newUrl);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            changePrice();
      }, [categories]);

      const handleSearch = (event) => {
            event.preventDefault();
            changePrice();
      };

      return (
            <>
                  <Helmet>
                        <title>{category.title}</title>
                        <link rel="canonical" href={window.location.href} />
                  </Helmet>
                  <BreadcrumbBox
                        title={category.title}
                        linkTitle="Products"
                        background="product"
                        link="/products"
                  />
                  <ProductListSection>
                        <Container className="mt-5">
                              <Row>
                                    <Col lg="3">
                                          <ProductListFilter>
                                                <ProductFilter
                                                      loading={loading}
                                                      categories={categories}
                                                      handleChangeCategory={
                                                            handleChangeCategory
                                                      }
                                                      search={search}
                                                      setSearch={setSearch}
                                                      handleSearch={
                                                            handleSearch
                                                      }
                                                />
                                          </ProductListFilter>
                                    </Col>
                                    <Col lg="9">
                                          <ProductListContainer>
                                                <ResultContainer>
                                                      Found Total :{' '}
                                                      <span>
                                                            {totalProducts}{' '}
                                                            products
                                                      </span>
                                                </ResultContainer>
                                                <Row className="g-5">
                                                      {products?.length > 0 &&
                                                            products.map(
                                                                  (
                                                                        product,
                                                                        index
                                                                  ) => (
                                                                        <Col
                                                                              lg={
                                                                                    4
                                                                              }
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <ProductCard
                                                                                    product={
                                                                                          product
                                                                                    }
                                                                              />
                                                                        </Col>
                                                                  )
                                                            )}
                                                </Row>
                                          </ProductListContainer>
                                    </Col>
                              </Row>
                        </Container>
                  </ProductListSection>
            </>
      );
};

export default Product;
