import styled from "styled-components";

export const BranchContainer = styled.div`
      margin: 3rem 0;
`;

export const BranchHeaderTitle = styled.div`
      font-size: 40px;
      font-weight: 500;

      @media screen and (max-width: 578px) {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 10px;
      }
`;
