import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProductCategoryContent, ProductCategoryImage } from '../styles';
import { AboutButton } from '../../home/styles';
import { Link } from 'react-router-dom';

const RightSidebar = ({ image, title, link, desc, num }) => {
      return (
            <>
                  <Row className="g-0">
                        <Col lg={5}>
                              <ProductCategoryImage>
                                    <img
                                          src={image}
                                          alt={title}
                                          className="img-fluid rounded-5"
                                          width="100%"
                                          height="100%"
                                    />
                              </ProductCategoryImage>
                        </Col>
                        <Col lg={7}>
                              <ProductCategoryContent>
                                    <span>{num}</span>
                                    <h3>{title}</h3>
                                    <p>{desc}</p>
                                    <AboutButton>
                                          <Link to={link}>
                                                View Products
                                                <span class="material-symbols-outlined">
                                                      arrow_right_alt
                                                </span>
                                          </Link>
                                    </AboutButton>
                              </ProductCategoryContent>
                        </Col>
                  </Row>
            </>
      );
};

export default RightSidebar;
