import React, { useEffect, useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import {
      ColorCollectionContainer,
      ColorShadeBox
} from '../../../frontend/home/styles';

import gsap from 'gsap';
import {
      spline,
      pointsInPath,
      createCoordsTransformer
} from '@georgedoescode/generative-utils';

const ColorCollection = () => {
      const circlePathRef = useRef(null);

      useEffect(() => {
            const circlePath = circlePathRef.current;

            if (!circlePath) return;

            const createLiquidPath = (path, options) => {
                  const svgPoints = pointsInPath(path, options.detail);
                  const originPoints = svgPoints.map(({ x, y }) => ({ x, y }));
                  const liquidPoints = svgPoints.map(({ x, y }) => ({ x, y }));

                  const mousePos = { x: 0, y: 0 };
                  const transformCoords = createCoordsTransformer(
                        path.closest('svg')
                  );

                  const pointDistance = Math.hypot(
                        originPoints[0].x - originPoints[1].x,
                        originPoints[0].y - originPoints[1].y
                  );
                  const maxDist = {
                        x: options.axis.includes('x') ? pointDistance / 2 : 0,
                        y: options.axis.includes('y') ? pointDistance / 2 : 0
                  };

                  gsap.ticker.add(() => {
                        gsap.set(path, {
                              attr: {
                                    d: spline(
                                          liquidPoints,
                                          options.tension,
                                          options.close
                                    )
                              }
                        });
                  });

                  window.addEventListener('mousemove', (e) => {
                        const { x, y } = transformCoords(e);

                        mousePos.x = x;
                        mousePos.y = y;

                        liquidPoints.forEach((point, index) => {
                              const pointOrigin = originPoints[index];
                              const distX = Math.abs(
                                    pointOrigin.x - mousePos.x
                              );
                              const distY = Math.abs(
                                    pointOrigin.y - mousePos.y
                              );

                              if (
                                    distX <= options.range.x &&
                                    distY <= options.range.y
                              ) {
                                    const difference = {
                                          x: pointOrigin.x - mousePos.x,
                                          y: pointOrigin.y - mousePos.y
                                    };

                                    const target = {
                                          x: pointOrigin.x + difference.x,
                                          y: pointOrigin.y + difference.y
                                    };

                                    const x = gsap.utils.clamp(
                                          pointOrigin.x - maxDist.x,
                                          pointOrigin.x + maxDist.x,
                                          target.x
                                    );

                                    const y = gsap.utils.clamp(
                                          pointOrigin.y - maxDist.y,
                                          pointOrigin.y + maxDist.y,
                                          target.y
                                    );

                                    gsap.to(point, {
                                          x: x,
                                          y: y,
                                          ease: 'sine',
                                          overwrite: true,
                                          duration: 0.175,
                                          onComplete() {
                                                gsap.to(point, {
                                                      x: pointOrigin.x,
                                                      y: pointOrigin.y,
                                                      ease: 'elastic.out(1, 0.3)',
                                                      duration: 1.25
                                                });
                                          }
                                    });
                              }
                        });
                  });
            };

            const prefersReducedMotionQuery = window.matchMedia(
                  '(prefers-reduced-motion: reduce)'
            );

            if (
                  prefersReducedMotionQuery &&
                  !prefersReducedMotionQuery.matches
            ) {
                  createLiquidPath(circlePath, {
                        detail: 16,
                        tension: 1,
                        close: true,
                        range: {
                              x: 20,
                              y: 20
                        },
                        axis: ['x', 'y']
                  });
            }
      }, []);

      return (
            <>
                  <Swiper
                        slidesPerView={4}
                        spaceBetween={10}
                        breakpoints={{
                              640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20
                              },
                              768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40
                              },
                              1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 50
                              }
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                  >
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    {/* <svg viewBox="0 0 200 200">
                     <defs>
                        <mask id="blob-mask">
                           <path
                              id="mask-path"
                              d="M149.5 100C149.5 127.338 127.338 149.5 100 149.5C72.6619 149.5 50.5 127.338 50.5 100C50.5 72.6619 72.6619 50.5 100 50.5C127.338 50.5 149.5 72.6619 149.5 100Z"
                              fill="#fff"
                              stroke="none"
                           />
                        </mask>
                     </defs>
                     <image
                        width="100%"
                        height="100%"
                        href="https://images.unsplash.com/photo-1620193276870-4beb07a2e0c9?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYyMTgzMzk3Mw&ixlib=rb-1.2.1&q=85"
                        mask="url(#blob-mask)"
                     ></image>
                  </svg> */}
                                    <ColorShadeBox shade="#B38750"></ColorShadeBox>
                                    <h4>Golden Stone</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#D3DCD0"></ColorShadeBox>
                                    <h4>Air Bubble</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#7ECBDB"></ColorShadeBox>
                                    <h4>Kerala Blackwater</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#766862"></ColorShadeBox>
                                    <h4>Grounded Red</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#809392"></ColorShadeBox>
                                    <h4>Wind's Whistle</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#809392"></ColorShadeBox>
                                    <h4>Tea Shop</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#D3DCD0"></ColorShadeBox>
                                    <h4>Air Bubble</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#D3DCD0"></ColorShadeBox>
                                    <h4>Air Bubble</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <ColorCollectionContainer>
                                    <ColorShadeBox shade="#D3DCD0"></ColorShadeBox>
                                    <h4>Air Bubble</h4>
                              </ColorCollectionContainer>
                        </SwiperSlide>
                  </Swiper>
            </>
      );
};

export default ColorCollection;
