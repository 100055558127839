import React from 'react';
import {
      ColorCode,
      ColorCodeCataglogue,
      ColorCodeName,
      ColorCodeWrapper,
      ColorShadeCode,
      ColorShadeCodeInner,
      ColorShadeCodeName,
      ColorShadeCodeWrapper,
      ColorShadeTabContainer
} from './styles';

const colorShadeHex = [
      {
            id: '1',
            title: 'Bone White 600',
            hexCode: '#FBFAE2'
      },
      {
            id: '2',
            title: 'Jaipuri Pink 849',
            hexCode: '#DEA3A0'
      },
      {
            id: '3',
            title: 'Mid Cream 612',
            hexCode: '#FCEE81'
      },
      {
            id: '4',
            title: 'Candy 825',
            hexCode: '#FFD4BF'
      },
      {
            id: '5',
            title: 'Magnolia 628',
            hexCode: '#F9F0D1'
      },
      {
            id: '6',
            title: 'Dark Marrie Pink',
            hexCode: '#851'
      },
      {
            id: '7',
            title: 'Ivory 608',
            hexCode: '#F9E9B8'
      },
      {
            id: '8',
            title: 'Rose Pink 840',
            hexCode: '#FFE2D4'
      },
      {
            id: '9',
            title: 'Sandalwood 512',
            hexCode: '#FFCD8C'
      },
      {
            id: '10',
            title: 'Pale rose 832',
            hexCode: '#FFB894'
      },
      {
            id: '11',
            title: 'Daffodil 513',
            hexCode: '#FCEC71'
      },
      {
            id: '12',
            title: 'Brick Red 800',
            hexCode: '#B5725B'
      },
      {
            id: '13',
            title: 'Tangerine 514',
            hexCode: '#FF9B6A'
      },
      {
            id: '14',
            title: 'Summer Blush 854',
            hexCode: '#FFF4CE'
      },
      {
            id: '15',
            title: 'Sunrise 836',
            hexCode: '#E27138'
      },
      {
            id: '16',
            title: 'Light Biscuit 824',
            hexCode: '#FFE2D4'
      }
];
const ColorShadeTab = () => {
      return (
            <>
                  <ColorShadeTabContainer>
                        <ul
                              class="nav nav-tabs"
                              id="myTab"
                              role="tablist"
                              className="border-bottom-0 d-flex list-inline gap-4"
                        >
                              <li class="nav-item" role="presentation">
                                    <button
                                          class="nav-link active border-0"
                                          id="home-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#home"
                                          type="button"
                                          role="tab"
                                          aria-controls="home"
                                          aria-selected="true"
                                    >
                                          <ColorCodeCataglogue>
                                                <ColorCodeWrapper className="d-flex gap-1 border ">
                                                      <ColorCode colorCode="#FFFF00"></ColorCode>
                                                      <ColorCode colorCode="#FFFDD0"></ColorCode>
                                                </ColorCodeWrapper>
                                                <ColorCodeName className="mt-2">
                                                      Yellows & Creams
                                                </ColorCodeName>
                                          </ColorCodeCataglogue>
                                    </button>
                              </li>
                              <li class="nav-item" role="presentation">
                                    <button
                                          class="nav-link"
                                          id="profile-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#profile"
                                          type="button"
                                          role="tab"
                                          aria-controls="profile"
                                          aria-selected="false"
                                    >
                                          <ColorCodeCataglogue>
                                                <ColorCodeWrapper className="d-flex gap-1 border ">
                                                      <ColorCode colorCode="#800080"></ColorCode>
                                                      <ColorCode colorCode="#FFC0CB"></ColorCode>
                                                </ColorCodeWrapper>
                                                <ColorCodeName className="mt-2">
                                                      Purple & Pink
                                                </ColorCodeName>
                                          </ColorCodeCataglogue>
                                    </button>
                              </li>
                              <li class="nav-item" role="presentation">
                                    <button
                                          class="nav-link"
                                          id="contact-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#contact"
                                          type="button"
                                          role="tab"
                                          aria-controls="contact"
                                          aria-selected="false"
                                    >
                                          <ColorCodeCataglogue>
                                                <ColorCodeWrapper className="d-flex gap-1 border ">
                                                      <ColorCode colorCode="#000"></ColorCode>
                                                      <ColorCode colorCode="#FFFDD0"></ColorCode>
                                                </ColorCodeWrapper>
                                                <ColorCodeName className="mt-2">
                                                      Red & Oranges
                                                </ColorCodeName>
                                          </ColorCodeCataglogue>
                                    </button>
                              </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                              <div
                                    class="tab-pane fade show active"
                                    id="home"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                              >
                                    <ColorShadeCodeWrapper>
                                          {colorShadeHex?.map((hex, index) => (
                                                <ColorShadeCode key={index}>
                                                      <ColorShadeCodeInner
                                                            colorCode={
                                                                  hex.hexCode
                                                            }
                                                      />
                                                      <ColorShadeCodeName>
                                                            {hex.title}
                                                      </ColorShadeCodeName>
                                                </ColorShadeCode>
                                          ))}
                                    </ColorShadeCodeWrapper>
                              </div>
                              <div
                                    class="tab-pane fade"
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                              >
                                    ...
                              </div>
                              <div
                                    class="tab-pane fade"
                                    id="contact"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                              >
                                    ...
                              </div>
                        </div>
                  </ColorShadeTabContainer>
            </>
      );
};

export default ColorShadeTab;
