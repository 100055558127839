import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { WarrentyClaim, WarrentyClaimContent, WarrentySection } from './styles';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap/esm';
import axios from 'axios';
import { Link } from 'react-router-dom';
import MetaContext from '../../stores/MetaContext';

const WarrentyPage = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/warranty_products`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('warranty');

      return (
            <>
                  <BreadcrumbBox title="Warranty" />
                  <WarrentySection className="my-5">
                        <Container>
                              <Row className="g-5">
                                    {!loading &&
                                          products?.map((product, index) => (
                                                <Col lg={3} key={index}>
                                                      <div className="warrenty__content">
                                                            <img
                                                                  src={
                                                                        product.image
                                                                  }
                                                                  alt={
                                                                        product.title
                                                                  }
                                                                  className="img-fluid border"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                            <h2>
                                                                  {
                                                                        product.title
                                                                  }
                                                            </h2>
                                                            <p>
                                                                  Performance
                                                                  warranty :{' '}
                                                                  {
                                                                        product.warranty
                                                                  }
                                                            </p>
                                                            <Link to="/warranty-policy">
                                                                  Read More
                                                            </Link>
                                                      </div>
                                                </Col>
                                          ))}
                              </Row>
                        </Container>

                        <WarrentyClaim>
                              <img
                                    src="/images/img/products/imgback.png"
                                    alt="lease Contact Our Nearest Dealer to Claim Your Warranty."
                                    className="w-100"
                                    width="100%"
                                    height="100%"
                              />
                              <WarrentyClaimContent>
                                    <h3>
                                          Please Contact Our Nearest Dealer to
                                          <br />
                                          <span>Claim Your Warranty.</span>
                                    </h3>
                              </WarrentyClaimContent>
                        </WarrentyClaim>
                  </WarrentySection>
            </>
      );
};

export default WarrentyPage;
