import styled from 'styled-components';

export const ProductContentContainer = styled.div`
      padding: 52px 44px;
      border-radius: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const ProductContentDescription = styled.div`
      p {
            font-size: 16px;
            color: #424242;
      }
`;
export const ProductContentFeatures = styled.div``;
export const ProductContentFeaturesItem = styled.div`
      margin-bottom: 20px;
      disp
`;
export const ProductContentFeaturesImage = styled.div`
      width: 60px;
      height: 60px;
      border: 1px solid #000;
      border-radius: 50%;
      img {
            width: 50px;
            height: 50px;
            object-fit: contain;
      }
      svg {
            width: 30px;
            height: 30px;
      }
`;
export const ProductContentFeaturesContent = styled.div`
      color: #999;
      text-align: center;
      font-size: 14px;
`;

export const EnquiryButton = styled.button`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      border: 0;
      padding: 10px 20px;
      border-radius: 5px;
      transition: all 0.3s ease-in;

      &:hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
                  rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
            background-color: ${(props) => props.theme.paint};
      }
`;
