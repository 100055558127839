import React from 'react';
import {
      CategoryFilterBody,
      CategoryFilterContainer,
      CategoryFilterHeader,
      FilterCategory,
      FilterTitle,
      ProductFilterContainer,
      ProductSearch
} from './styles';
import { Accordion, Button, Form } from 'react-bootstrap';
import CategoryCheck from './CategoryCheck';

const ProductFilter = ({
      categories,
      loading,
      handleChangeCategory,
      setSearch,
      search,
      handleSearch
}) => {
      return (
            <>
                  <ProductFilterContainer>
                        <ProductSearch>
                              <Form
                                    onSubmit={handleSearch}
                                    className="d-flex align-items-center justify-content-between border-1"
                              >
                                    <Form.Control
                                          type="text"
                                          placeholder="Search product"
                                          className=" rounded-0 "
                                          value={search}
                                          onChange={(event) =>
                                                setSearch(event.target.value)
                                          }
                                    />
                                    <Button
                                          type="submit"
                                          className="border-0 d-flex align-items-center justify-content-center px-3 py-3 rounded-0"
                                    >
                                          <i class="bx bx-search"></i>
                                    </Button>
                              </Form>
                        </ProductSearch>
                        <FilterCategory className="mt-4 filter mb-5">
                              <FilterTitle className="ps-3">
                                    Product Category
                              </FilterTitle>
                              <hr />

                              {!loading &&
                                    categories?.length > 0 &&
                                    categories?.map((category, index) =>
                                          category?.subCategory?.length > 0 ? (
                                                <CategoryFilterContainer
                                                      flush
                                                      defaultActiveKey="nothing"
                                                >
                                                      <Accordion.Item
                                                            eventKey={
                                                                  category.id
                                                            }
                                                      >
                                                            <CategoryFilterHeader>
                                                                  {
                                                                        category.title
                                                                  }
                                                            </CategoryFilterHeader>
                                                            <CategoryFilterBody>
                                                                  <ul>
                                                                        {category
                                                                              ?.subCategory
                                                                              ?.length >
                                                                        0 ? (
                                                                              category.subCategory.map(
                                                                                    (
                                                                                          subCategory,
                                                                                          key
                                                                                    ) =>
                                                                                          subCategory
                                                                                                .childCategory
                                                                                                .length >
                                                                                          0 ? (
                                                                                                <CategoryFilterContainer
                                                                                                      flush
                                                                                                      defaultActiveKey="nothing"
                                                                                                >
                                                                                                      <Accordion.Item
                                                                                                            eventKey={
                                                                                                                  subCategory.id
                                                                                                            }
                                                                                                      >
                                                                                                            <CategoryFilterHeader>
                                                                                                                  {
                                                                                                                        subCategory.title
                                                                                                                  }
                                                                                                            </CategoryFilterHeader>
                                                                                                            <CategoryFilterBody>
                                                                                                                  <ul>
                                                                                                                        {subCategory.childCategory.map(
                                                                                                                              (
                                                                                                                                    childCategory,
                                                                                                                                    indexKey
                                                                                                                              ) => (
                                                                                                                                    <li
                                                                                                                                          key={
                                                                                                                                                indexKey
                                                                                                                                          }
                                                                                                                                    >
                                                                                                                                          <CategoryCheck
                                                                                                                                                category={
                                                                                                                                                      childCategory
                                                                                                                                                }
                                                                                                                                                handleChangeCategory={
                                                                                                                                                      handleChangeCategory
                                                                                                                                                }
                                                                                                                                                type="childCategory"
                                                                                                                                          />
                                                                                                                                    </li>
                                                                                                                              )
                                                                                                                        )}
                                                                                                                  </ul>
                                                                                                            </CategoryFilterBody>
                                                                                                      </Accordion.Item>
                                                                                                </CategoryFilterContainer>
                                                                                          ) : (
                                                                                                <li
                                                                                                      key={
                                                                                                            key
                                                                                                      }
                                                                                                >
                                                                                                      <CategoryCheck
                                                                                                            category={
                                                                                                                  subCategory
                                                                                                            }
                                                                                                            type="subCategory"
                                                                                                            handleChangeCategory={
                                                                                                                  handleChangeCategory
                                                                                                            }
                                                                                                      />
                                                                                                </li>
                                                                                          )
                                                                              )
                                                                        ) : (
                                                                              <li
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <CategoryCheck
                                                                                          category={
                                                                                                category
                                                                                          }
                                                                                          type="category"
                                                                                          handleChangeCategory={
                                                                                                handleChangeCategory
                                                                                          }
                                                                                    />
                                                                              </li>
                                                                        )}
                                                                  </ul>
                                                            </CategoryFilterBody>
                                                      </Accordion.Item>
                                                </CategoryFilterContainer>
                                          ) : (
                                                <ul
                                                      style={{
                                                            paddingRight: '20px'
                                                      }}
                                                >
                                                      {
                                                            <li key={index}>
                                                                  <CategoryCheck
                                                                        category={
                                                                              category
                                                                        }
                                                                        type="category"
                                                                        handleChangeCategory={
                                                                              handleChangeCategory
                                                                        }
                                                                  />
                                                            </li>
                                                      }
                                                </ul>
                                          )
                                    )}
                              {/* {!loading &&
                                    categories?.map((category, index) => (
                                          <CategoryCheck
                                                category={category}
                                                type="category"
                                                key={index}
                                                handleChangeCategory={
                                                      handleChangeCategory
                                                }
                                          />
                                    ))} */}
                        </FilterCategory>
                        {/* <FilterClass className=" filter mb-5">
                              <FilterTitle className="ps-3">
                                    Product Class
                              </FilterTitle>
                              <hr />
                              <Form>
                                    <Form.Group className="mb-2 ms-3">
                                          <Form.Check
                                                type="checkbox"
                                                id="checkbox"
                                                label="Luxury"
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-2 ms-3">
                                          <Form.Check
                                                type="checkbox"
                                                id="checkbox"
                                                label="Premium"
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-2 ms-3">
                                          <Form.Check
                                                type="checkbox"
                                                id="checkbox"
                                                label="Economy"
                                          />
                                    </Form.Group>
                              </Form>
                        </FilterClass> */}
                        {/* <FilterFinish className=" filter mb-5">
                              <FilterTitle className="ps-3">
                                    Product Finish
                              </FilterTitle>
                              <hr />
                              <Form>
                                    <Form.Group className="mb-2 ms-3">
                                          <Form.Check
                                                type="checkbox"
                                                id="checkbox"
                                                label="Sheen"
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-2 ms-3">
                                          <Form.Check
                                                type="checkbox"
                                                id="checkbox"
                                                label="Matt"
                                          />
                                    </Form.Group>
                              </Form>
                        </FilterFinish> */}
                  </ProductFilterContainer>
            </>
      );
};

export default ProductFilter;
