import React, { useEffect, useState } from 'react';
import { TestimonialSection, TestimonialSummary } from '../styles';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
const TestimonialBox = () => {
      const [loading, setLoading] = useState(false);
      const [testimonials, setTestimonials] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/testimonials`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials(response.data.testimonials);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <TestimonialSection>
                        <section className="testimonial">
                              <div className="container py-5">
                                    <h2 className=" product__heading text-center">
                                          [ Clients Testimonial ]
                                    </h2>
                                    <div className="product__heading--bar "></div>
                                    <Row className="mb-3 justify-content-center">
                                          <Col lg={12}>
                                                {!loading && (
                                                      <Swiper
                                                            slidesPerView={1}
                                                            spaceBetween={10}
                                                            breakpoints={{
                                                                  640: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 20
                                                                  },
                                                                  768: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 40
                                                                  },
                                                                  1024: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 50
                                                                  }
                                                            }}
                                                            autoplay
                                                            // modules={[Pagination]}
                                                            className="mySwiper testimonial__slide-container"
                                                      >
                                                            <div className="testimonial__slide-content">
                                                                  <div className="testimonial_wrapper swiper-wrapper">
                                                                        {testimonials.length >
                                                                              0 &&
                                                                              testimonials?.map(
                                                                                    (
                                                                                          testimonial,
                                                                                          index
                                                                                    ) => (
                                                                                          <SwiperSlide
                                                                                                className="mb-5"
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                          >
                                                                                                <div className="testimonial_card">
                                                                                                      <div className="testimonial__overlay">
                                                                                                            <img
                                                                                                                  src="images/png/yeti-banner3.png"
                                                                                                                  alt={`Review from our client - ${testimonial.name}`}
                                                                                                                  className="img-fluid"
                                                                                                                  width="100%"
                                                                                                                  height="100%"
                                                                                                            />
                                                                                                      </div>
                                                                                                      <div className="testimonial__image-container">
                                                                                                            <div className="testimonial__img">
                                                                                                                  <img
                                                                                                                        src={
                                                                                                                              testimonial.image
                                                                                                                        }
                                                                                                                        alt={
                                                                                                                              testimonial.name
                                                                                                                        }
                                                                                                                        className="img-fluid"
                                                                                                                        width="100%"
                                                                                                                        height="100%"
                                                                                                                  />
                                                                                                            </div>

                                                                                                            <div className="testimonial__label">
                                                                                                                  <h3>
                                                                                                                        {
                                                                                                                              testimonial.name
                                                                                                                        }
                                                                                                                  </h3>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <div className="testimonial__body">
                                                                                                            <p>
                                                                                                                  {
                                                                                                                        testimonial.message
                                                                                                                  }
                                                                                                            </p>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </SwiperSlide>
                                                                                    )
                                                                              )}
                                                                  </div>
                                                            </div>
                                                      </Swiper>
                                                )}
                                          </Col>
                                    </Row>
                              </div>
                        </section>
                  </TestimonialSection>
            </>
      );
};

export default TestimonialBox;
