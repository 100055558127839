import React from 'react';
import { Col } from 'react-bootstrap';
import { MapContainer } from '../styles';

const ContactMap = () => {
      return (
            <>
                  <Col lg={12}>
                        <MapContainer>
                              <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3542.1391790536527!2d85.02306317524243!3d27.402587076358643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1868f08b004b%3A0x9b91f7a68182be3e!2sYeti%20Paints%20Nepal%20Pvt.%20Ltd!5e0!3m2!1sen!2snp!4v1702633531468!5m2!1sen!2snp"
                                    style={{ border: '0' }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                    className="w-100 h-100"
                              ></iframe>
                        </MapContainer>
                  </Col>
            </>
      );
};

export default ContactMap;
