import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ContactBranchItem from '../ContactBranchItem';
import { BranchContainer, BranchHeaderTitle } from './styles';

const ContactBranch = () => {
      return (
            <>
                  <Col lg={12}>
                        <BranchContainer>
                              <BranchHeaderTitle>Our Branch</BranchHeaderTitle>
                              <Row className="g-4">
                                    <ContactBranchItem
                                          title="Kathmandu Office"
                                          address="Bafal, Kathmandu"
                                          phone="01-5376437"
                                          email="yetipaintsktm@gmail.com"
                                    />
                                    <ContactBranchItem
                                          title="Pokhara Office"
                                          address="Shantinagar Chowk, Pokhara"
                                          phone="06-1588172"
                                          email="yetipaintspkr@gmail.com"
                                    />
                                    <ContactBranchItem
                                          title="Butwal Office"
                                          address="Kalika Nagar-11, Butwal"
                                          phone="07-1415209"
                                          email="yetipaintsbutwal@gmail.com"
                                    />
                                    <ContactBranchItem
                                          title="Birannagar Office"
                                          address="Chadani Chowk, Biratnagar"
                                          phone="9802351477 / 9802351461"
                                          email="yetipaintsbrt@gmail.com"
                                    />
                                    <ContactBranchItem
                                          title="Nepalgunj Office"
                                          address="Chadani Chowk, Biratnagar"
                                          phone="9802351457 / 9802351455 / 9802351452"
                                          email="yetipaintsnpj@gmail.com"
                                    />
                              </Row>
                        </BranchContainer>
                  </Col>
            </>
      );
};

export default ContactBranch;
