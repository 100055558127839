import React from 'react';
import { Accordion } from 'react-bootstrap';
import { FaqAccordionContainer } from './styles';
import QuestionIcon from '../../../../components/icons/QuestionIcon';

const FaqAccordion = ({ faqs, loading }) => {
      console.log(faqs);
      return (
            <>
                  {!loading && faqs?.length > 0 && (
                        <FaqAccordionContainer>
                              <Accordion defaultActiveKey="0">
                                    {faqs?.map((faq, index) => (
                                          <Accordion.Item
                                                eventKey={`${index}`}
                                                key={index}
                                          >
                                                <Accordion.Header>
                                                      <QuestionIcon />
                                                      {faq.question}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                      {faq.answer}
                                                </Accordion.Body>
                                          </Accordion.Item>
                                    ))}
                              </Accordion>
                        </FaqAccordionContainer>
                  )}
            </>
      );
};

export default FaqAccordion;
