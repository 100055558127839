import React from 'react';
import { Link } from 'react-router-dom';

const FooterMenuItem = ({ title, link }) => {
      return (
            <>
                  <li>
                        <Link
                              to={link ? link : '#'}
                              className="text-decoration-none"
                              data-title={title}
                        >
                              <span>{title}</span>
                        </Link>
                  </li>
            </>
      );
};

export default FooterMenuItem;
