import React, { useContext, useEffect, useState } from "react";
import BreadcrumbBox from "../../components/common/BreadcrumbBox";
import { GalleryContainer, GalleryHeading } from "./styles";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import MetaContext from "../../stores/MetaContext";
import { giftDistribution, goaTours } from "../../data/goaTours";

const Gallery = () => {
      const [galleries, setGalleries] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/galleries`)
                  .then((response) => {
                        if (response.data.result === "success") {
                              setGalleries(response.data.galleries);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("our-gallery");

      return (
            <>
                  <BreadcrumbBox title="Our Gallery" />
                  <GalleryContainer className="my-5">
                        <GalleryHeading className="my-4">
                              <h2>
                                    Goa Tour <span>Memories</span>
                              </h2>
                        </GalleryHeading>
                        <hr className="mb-5" />
                        <Row className="g-4">
                              {!loading &&
                                    goaTours?.map((gallery, index) => (
                                          <Col lg={4} key={index}>
                                                <a
                                                      href={gallery.image}
                                                      data-lightbox="image-1"
                                                      data-title="My caption"
                                                      className="d-inline-block"
                                                >
                                                      <img
                                                            src={gallery.image}
                                                            alt={gallery.title}
                                                            className="img-fluid"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </a>
                                          </Col>
                                    ))}
                        </Row>
                  </GalleryContainer>
                  <GalleryContainer className="my-5">
                        <GalleryHeading className="my-4">
                              <h2>
                                    Gift Distribution <span>Memories</span>
                              </h2>
                        </GalleryHeading>
                        <hr className="mb-5" />
                        <Row className="g-4">
                              {!loading &&
                                    giftDistribution?.map((gallery, index) => (
                                          <Col lg={4} key={index}>
                                                <a
                                                      href={gallery.image}
                                                      data-lightbox="image-1"
                                                      data-title="My caption"
                                                      className="d-inline-block"
                                                >
                                                      <img
                                                            src={gallery.image}
                                                            alt={gallery.title}
                                                            className="img-fluid"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </a>
                                          </Col>
                                    ))}
                        </Row>
                  </GalleryContainer>
                  <GalleryContainer className="my-5">
                        <GalleryHeading className="my-4">
                              <h2>
                                    Our Beautiful <span>Memories</span>
                              </h2>
                        </GalleryHeading>
                        <hr className="mb-5" />
                        <Row className="g-4">
                              {!loading &&
                                    galleries?.map((gallery, index) => (
                                          <Col lg={4} key={index}>
                                                <a
                                                      href={gallery.image}
                                                      data-lightbox="image-1"
                                                      data-title="My caption"
                                                      className="d-inline-block"
                                                >
                                                      <img
                                                            src={gallery.image}
                                                            alt={gallery.title}
                                                            className="img-fluid"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </a>
                                          </Col>
                                    ))}
                        </Row>
                  </GalleryContainer>
            </>
      );
};

export default Gallery;
