import { Container } from "react-bootstrap";
import styled from "styled-components";

export const WarrentySection = styled.section`
      h2 {
            font-size: 18px;
            margin-top: 20px;
            font-weight: 500;
      }

      p {
            margin-bottom: 0.8rem;
      }

      a {
            color: ${(props) => props.theme.paint};
      }

      .warrenty__content {
            img {
                  border-radius: 15px;
            }
      }
`;

export const WarrentyClaim = styled.div`
      position: relative;
      margin-bottom: -30px;

      @media screen and (max-width: 578px) {
            img {
                  height: 280px;
                  object-fit: cover;
            }
      }
`;
export const WarrentyClaimContent = styled.div`
      position: absolute;
      width: 43%;
      top: 55%;
      left: 10%;
      transform: translateY(-50%);
      z-index: 1;

      h3 {
            width: 100%;
            color: ${(props) => props.theme.paint};
            font-size: 30px;
            text-transform: capitalize;
            /* line-height: 1.6; */
            span {
                  ${"" /* color: ${(props) => props.theme.primary}; */}
            }
      }

      @media screen and (max-width: 578px) {
            width: calc(100% - 40px);
            left: 20px;

            h3 {
                  font-size: 24px;
            }
      }
`;
export const WarrentyContainer = styled(Container)`
      padding: 50px 0px 150px 0px;
      .nav {
            position: sticky;
            top: 30px;
      }
      .nav-item {
            border: 1px solid #ced4da;

            a {
                  color: #555;
            }
      }
      .nav-pills .nav-link {
            border-radius: 0;
            padding: 10px 20px;
      }
      .nav-pills .nav-link.active {
            color: var(--bs-nav-pills-link-active-color);
            background-color: ${(props) => props.theme.primary};
            position: relative;
            &:after {
                  content: "";
                  position: absolute;
                  background: ${(props) => props.theme.primary};
                  width: 13px;
                  height: 13px;
                  top: 50%;
                  transform: translateY(-50%) rotate(135deg);
                  right: -5px;
                  transition: background 0.15s ease-in-out;
            }
      }

      @media screen and (max-width: 578px) {
            .col-sm-9 {
                  margin-top: 3rem;
            }
      }
`;

export const WarrentyContent = styled.div``;
export const WarrentyContentTitle = styled.div`
      background: ${(props) => props.theme.primary};
      padding: 12px 26px;
      h2 {
            color: ${(props) => props.theme.white};
            font-size: 18px;
            margin-bottom: 0px;
            margin-top: 0;
      }
`;

export const WarrentyContentDesc = styled.div`
      background: #f1f1f1;
      padding: 20px 26px;
      h2 {
            font-size: 17px;
      }
      p {
            text-align: justify;
      }

      table {
            width: 100%;
            margin-bottom: 1rem;
            vertical-align: top;
            border-color: #dee2e6;

            & > thead {
                  vertical-align: bottom;
            }

            & > :not(caption) > * {
                  border-width: 1px 0;
            }

            & > :not(caption) > * > * {
                  padding: 0.5rem 0.5rem;
                  color: initial;
                  background-color: ${(props) => props.theme.white};
                  border-bottom-width: 0 1px;
                  box-shadow: inset 0 0 0 9999px initial;
            }
      }
`;

export const WarrentyContentlist = styled.div`
      margin-bottom: 20px;
      ul {
            li {
                  position: relative;
                  svg {
                        width: 10px;
                        height: 10px;
                  }
            }
      }
`;
