import React, { useEffect, useState } from "react";
import { ProductDetailContainer } from "./styles";
import BreadcrumbBox from "../../components/common/BreadcrumbBox";
import { Col, Container, Row } from "react-bootstrap";
import ProductContent from "../../components/product/ProductContent";
import ProductSlider from "../../components/product/ProductSlider";
import ProductAdditional from "../../components/product/ProductAdditional";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const ProductDetail = () => {
      const { productId } = useParams();
      const [product, setProduct] = useState([]);
      const [sizes, setSizes] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${productId}`
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProduct(response.data.product);
                              setSizes(JSON.parse(response.data.product.sizes));
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [productId]);

      return (
            <>
                  <Helmet>
                        <title>
                              {`${product.seo_title || product.title} by Yeti
                              Paints Nepal`}
                        </title>
                        {product.seo_description && (
                              <meta
                                    name="description"
                                    content={product.seo_description}
                              />
                        )}
                        {product.seo_keyword && (
                              <meta
                                    name="keyword"
                                    content={product.seo_keyword}
                              />
                        )}
                        <link rel="canonical" href={window.location.href} />

                        <meta
                              property="og:site_name"
                              content={`${
                                    product.seo_title || product.title
                              } by Yeti Paints Nepal`}
                        />
                        <meta property="og:type" content="article" />
                        <meta
                              property="og:title"
                              content={`${
                                    product.seo_title || product.title
                              } by Yeti Paints Nepal`}
                        />
                        {product.seo_description && (
                              <meta
                                    property="og:description"
                                    content={product.seo_description}
                              />
                        )}
                        <meta
                              property="og:url"
                              content={window.location.href}
                        />
                        <meta property="og:image" content={product.image} />
                        <meta
                              property="og:image:secure_url"
                              content={product.image}
                        />
                        <meta property="og:image:width" content="1000" />
                        <meta property="og:image:height" content="500" />
                        <meta
                              property="twitter:card"
                              content="summary_large_image"
                        />
                        <meta
                              property="twitter:domain"
                              content="yetipaints.com"
                        />
                        <meta
                              property="twitter:title"
                              content={`${
                                    product.seo_title || product.title
                              } by Yeti Paints Nepal`}
                        />
                        {product.seo_description && (
                              <meta
                                    property="twitter:description"
                                    content={product.seo_description}
                              />
                        )}
                        <meta
                              property="twitter:image"
                              content={product.image}
                        />
                  </Helmet>
                  <ProductDetailContainer>
                        <BreadcrumbBox
                              title={product.title}
                              link={`/${product.category_slug}`}
                              linkTitle={product.category_name}
                        >
                              {product.parent_category && (
                                    <li className="breadcrumb-item">
                                          <Link
                                                to={`/${product.parent_category}`}
                                          >
                                                {product.parent_category_name}
                                          </Link>
                                    </li>
                              )}
                        </BreadcrumbBox>
                        <Container className="my-5 ">
                              <Row className="g-5 align-items-center justify-content-center">
                                    <Col lg={4} md={6}>
                                          <ProductSlider
                                                product={product}
                                                loading={loading}
                                                sizes={sizes}
                                          />
                                    </Col>
                                    <Col lg={7}>
                                          <ProductContent
                                                product={product}
                                                productId={productId}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                        <ProductAdditional product={product} />
                  </ProductDetailContainer>
            </>
      );
};
export default ProductDetail;
