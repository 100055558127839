import React, { useState } from 'react';
import {
      EnquiryButton,
      ProductContentContainer,
      ProductContentDescription,
      ProductContentFeatures
} from './styles';
import { ProductSliderTitle } from '../ProductSlider/styles';
import ProductFeaturesSlider from './components/ProductFeaturesSlider';
import EnquiryForm from './EnquiryForm';

const ProductContent = ({ product, productId }) => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <>
                  <ProductContentContainer>
                        <ProductSliderTitle className="mb-4 d-flex align-items-center justify-content-between">
                              {product.title}
                        </ProductSliderTitle>
                        <ProductContentDescription className="mb-5">
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: product.description
                                    }}
                              ></div>
                        </ProductContentDescription>
                        <ProductContentFeatures>
                              <ProductFeaturesSlider product={product} />
                        </ProductContentFeatures>
                        <EnquiryButton type="button" onClick={handleShow}>
                              Get Quote
                        </EnquiryButton>
                  </ProductContentContainer>
                  <EnquiryForm
                        handleClose={handleClose}
                        show={show}
                        handleShow={handleShow}
                        productId={productId}
                  />
            </>
      );
};

export default ProductContent;
