import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const ProductFilterContainer = styled.div`
      .filter {
            label {
                  margin-left: 10px;
            }
      }
`;

export const ProductSearch = styled.div`
      form {
      }
      input {
            height: 50px;
            border: 1px solid #eee;
            padding-left: 20px;
            &:focus {
                  color: var(--bs-body-color);
                  background-color: transparent;
                  border-color: ${(props) => props.theme.primary};
                  outline: 0;
                  box-shadow: none;
            }
      }
      button {
            height: 50px;
            background: ${(props) => props.theme.primary};
            &:hover {
                  color: var(--bs-btn-hover-color);
                  background-color: #a50414;
                  border-color: #a50414;
            }
      }
`;
export const FilterCategory = styled.div`
      ul {
            padding: 0;
            list-style: none;
            margin: 0;
      }
`;
export const FilterTitle = styled.h2`
      font-size: 18px;
      color: #74277b;
`;

export const FilterClass = styled.div``;
export const FilterFinish = styled.div``;

export const CategoryFilterContainer = styled(Accordion)`
      padding-right: 10px;
`;

export const CategoryFilterHeader = styled(Accordion.Header)`
      button {
            padding: 0;
            background-color: transparent !important;
            box-shadow: none !important;
            font-weight: 400;
            font-size: 1rem;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            padding: 10px;
      }
`;

export const CategoryFilterBody = styled(Accordion.Body)`
      padding: 0;

      ul {
            padding-left: 16px;

            li {
                  border-bottom: 1px solid #dee2e6;
                  padding-bottom: 5px;
                  padding-top: 5px;
                  list-style-type: none;

                  label {
                        padding: 0 10px;
                  }

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }

            .accordion {
                  border-bottom: 1px solid #dee2e6 !important;

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }
      }
`;
