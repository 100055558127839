import styled from 'styled-components';

export const CareerContainer = styled.div`
      padding: 50px 0px 100px 0px;

      .career-section .career__item {
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 5px;
            text-decoration: none;
            background-color: transparent;
            border-left: 5px solid #d90117;
            display: block;
            color: #3d3d3d;
            transition: box-shadow 0.3s ease;

            &:hover {
                  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
      }
      .career__item-header .career__item-title h2 {
            font-size: 18px;
            color: #d90117;
            font-weight: 600;
      }
      .career__item-date {
            display: flex;
            gap: 8px;
            margin-bottom: 5px;
            font-size: 14px;
            color: rgb(58, 58, 58);
            align-items: center;
      }

      .career__item-body li {
            list-style-type: square;
            font-size: 14px;
            color: #404040;
      }
      /* career-info */
      .career-body__inner,
      .career-form__inner {
            box-shadow: #3c40434d 0px 1px 2px 0px,
                  rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            border-radius: 10px;
            padding: 20px;
            border-left: 5px solid #d9001b;
      }
      .career-body__inner thead tr th {
            color: #404040;
            font-size: 18px;
            font-weight: 500;
      }
      .career-body__inner tbody tr td {
            color: #404040;
      }
      .btnbtn,
      .apply-btn {
            padding: 10px 16px;
            border-radius: 5px;
            display: inline-block;
            border: 0;
            background-color: #d9001b;
            text-decoration: none;
            color: #222;
            font-size: 14px;
            transition: color 0.5s ease;
      }
      .btnbtn:hover,
      .apply-btn:hover {
            background-color: #222;
            color: #f2f2f2;
      }

      /* career form */
      .custom-select .select-selected {
            display: block;
            padding: 0.375rem 0.75rem;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            color: #212529;
            background-color: transparent;
            border: 1px solid #dee2e6;
            border-radius: 0.375rem;
            cursor: pointer;
            position: relative;
      }
      .custom-select {
            position: relative;

            .select-selected::after {
                  position: absolute;
                  content: '';
                  border: 6px solid transparent;
                  border-color: #222 transparent transparent transparent;
                  top: 16px;
                  right: 10px;
            }

            .select-items {
                  display: none;
                  position: absolute;
                  background-color: #fff;
                  border: 1px solid #dee2e6;
                  border-top: none;
                  width: 100%;
                  /* padding: 12px; */
                  border-radius: 0 0 4px 4px;
                  z-index: 1;

                  div {
                        padding: 7px 16px;

                        &:nth-child(1) {
                              border-bottom: 1px solid #dee2e6;
                        }
                  }
            }
      }
      .select-selected.select-arrow-active::after {
            border-color: transparent transparent #222 transparent;
            top: 7px;
      }
      .select-items.open {
            display: block;
      }
      .form-control:focus {
            outline: none;
            box-shadow: none;
            border-color: #d9001b;
      }
      .close-btn svg {
            width: 24px;
            height: 24px;
            fill: #d9001b;
      }
`;

export const CareerDetailContainer = styled.div`
      padding: 50px 0px 100px 0px;
      .career-section .career__item {
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 5px;
            text-decoration: none;
            background-color: transparent;
            border-left: 5px solid #d9001b;
            display: block;
            color: #3d3d3d;

            transition: box-shadow 0.3s ease;

            &:hover {
                  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
      }
      .career__item-header .career__item-title h3 {
            font-size: 18px;
            color: #d9001b;
      }
      .career__item-date {
            display: flex;
            gap: 8px;
            margin-bottom: 5px;
            font-size: 14px;
            color: rgb(58, 58, 58);
      }
      .career__item-body li {
            list-style-type: square;
            font-size: 14px;
            color: #404040;
      }
      /* career-info */
      .career-body__inner,
      .career-form__inner {
            box-shadow: #3c40434d 0px 1px 2px 0px,
                  rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            border-radius: 10px;
            padding: 20px;
            border-left: 5px solid #d9001b;
      }
      .career-body__inner thead tr th {
            color: #404040;
            font-size: 18px;
            font-weight: 500;
      }
      .career-body__inner tbody tr td {
            color: #404040;
      }
      .btnbtn,
      .apply-btn {
            padding: 10px 16px;
            border-radius: 5px;
            display: inline-block;
            border: 0;
            background-color: #d9001b;
            text-decoration: none;
            color: #222;
            font-size: 14px;
            transition: color 0.5s ease;
      }
      .btnbtn:hover,
      .apply-btn:hover {
            background-color: #222;
            color: #f2f2f2;
      }

      /* career form */
      .custom-select .select-selected {
            display: block;
            padding: 0.375rem 0.75rem;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            color: #212529;
            background-color: transparent;
            border: 1px solid #dee2e6;
            border-radius: 0.375rem;
            cursor: pointer;
            position: relative;
      }
      .custom-select {
            position: relative;

            .select-selected::after {
                  position: absolute;
                  content: '';
                  border: 6px solid transparent;
                  border-color: #222 transparent transparent transparent;
                  top: 16px;
                  right: 10px;
            }

            .select-items {
                  display: none;
                  position: absolute;
                  background-color: #fff;
                  border: 1px solid #dee2e6;
                  border-top: none;
                  width: 100%;
                  /* padding: 12px; */
                  border-radius: 0 0 4px 4px;
                  z-index: 1;

                  div {
                        padding: 7px 16px;

                        &:nth-child(1) {
                              border-bottom: 1px solid #dee2e6;
                        }
                  }
            }
      }
      .select-selected.select-arrow-active::after {
            border-color: transparent transparent #222 transparent;
            top: 7px;
      }
      .select-items.open {
            display: block;
      }
      .form-control:focus {
            outline: none;
            box-shadow: none;
            border-color: #d9001b;
      }
      /* pop-up */
      .career_form-section {
            display: none;
      }
      .career_form-section.active {
            display: block;
      }
      .career__content-section.none {
            display: none;
      }
      .close-btn svg {
            width: 24px;
            height: 24px;
            fill: #d9001b;
      }

      .w-3 {
            width: 25%;
      }

      .w-6 {
            width: calc(100% - 25%);
      }
`;
