import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const ColorShadeContainer = styled(Container)`
      padding: 60px 0px 100px 0px;
`;

export const ColorImage = styled.div`
      width: 100%;
      margin-bottom: 3rem;

      img {
            border-radius: 15px;
      }
`;
