import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CatalogueContainer = styled.div`
   margin: 70px 0px 100px 0px;
`;
export const CatalogueContent = styled.div``;
export const CatalogueImage = styled.div``;
export const CatalogueDetail = styled.div`
   background: #e1e1e1;
   padding: 15px 20px;
`;
export const CatalogueTitle = styled.div`
   font-size: 18px;
   margin-bottom: 5px;
   a {
      color: ${(props) => props.theme.primary};
   }
`;
export const CatalogueItem = styled(Link)`
   text-decoration: none;
   color: ${(props) => (props.button ? props.theme.primary : '#68798b')};
`;
export const CatalogueIcon = styled.div``;
export const CatalogueAction = styled.div``;
