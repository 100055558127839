export const lightTheme = {
      body: '#212529',
      primary: '#D90117',
      secondary: '#E14D49',
      white: '#FFFFFF',
      purple: '#412D74',
      paint: '#7E2C2E'
};

export const DarkTheme = {
      body: '#000000',
      text: '#FCF6F4',
      fontFamily: "'Manrope', sans-serif",
      textRgba: '252, 246, 244',
      bodyRgba: '0,0,0'
};
