import React from 'react';
import {
      BlogDetailContent,
      BlogDetailTitle,
      RelatedBlogContent
} from '../BlogDetail/styles';

const BlogWidget = ({ title, children }) => {
      return (
            <>
                  <BlogDetailContent>
                        <BlogDetailTitle className="mb-3">
                              {title}
                        </BlogDetailTitle>
                        <RelatedBlogContent>{children}</RelatedBlogContent>
                  </BlogDetailContent>
            </>
      );
};

export default BlogWidget;
