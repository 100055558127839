import React, { useEffect, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import BreadcrumbBox from "../../../components/common/BreadcrumbBox";
import { Col, Row } from "react-bootstrap";
import {
      BlogCategory,
      BlogDetailContainer,
      BlogDetailContent,
      BlogDetailDescription,
      BlogDetailImage,
      BlogExtra,
      BlogExtraItem,
      RealtedBlogItem,
      RealtedBlogItemTitle,
} from "./styles";
import { BlogItemTitle } from "../../../components/blog/BlogItemBox/styles";
import axios from "axios";
import BlogWidget from "../BlogWidget";
import { WidgetCategoryCount, WidgetCategoryItem } from "../BlogWidget/styles";
import { Helmet } from "react-helmet";

const BlogDetail = () => {
      const [loading, setLoading] = useState(false);
      const [blog, setBlog] = useState([]);
      const [categories, setCategories] = useState([]);
      const [blogs, setBlogs] = useState([]);

      const { blogId } = useParams();

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}`
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBlog(response.data.blog);
                              setBlogs(response.data.blogs);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [useLocation().pathname]);
      return (
            <>
                  <Helmet>
                        <title>{blog.seo_title || blog.title}</title>
                        <link rel="canonical" href={window.location.href} />
                        {blog.seo_description && (
                              <meta
                                    name="description"
                                    content={blog.seo_description}
                              />
                        )}
                        {blog.seo_keyword && (
                              <meta name="keyword" content={blog.seo_keyword} />
                        )}

                        <meta
                              property="og:site_name"
                              content={blog.seo_title || blog.title}
                        />
                        <meta property="og:type" content="article" />
                        <meta
                              property="og:title"
                              content={blog.seo_title || blog.title}
                        />
                        {blog.seo_description && (
                              <meta
                                    property="og:description"
                                    content={blog.seo_description}
                              />
                        )}
                        <meta
                              property="og:url"
                              content={window.location.href}
                        />
                        <meta property="og:image" content={blog.image} />
                        <meta
                              property="og:image:secure_url"
                              content={blog.image}
                        />
                        <meta property="og:image:width" content="1000" />
                        <meta property="og:image:height" content="500" />

                        <meta
                              property="twitter:card"
                              content="summary_large_image"
                        />
                        <meta
                              property="twitter:domain"
                              content="yetipaints.com"
                        />
                        <meta
                              property="twitter:title"
                              content={blog.seo_title || blog.title}
                        />
                        {blog.seo_description && (
                              <meta
                                    property="twitter:description"
                                    content={blog.seo_description}
                              />
                        )}
                        <meta property="twitter:image" content={blog.image} />
                  </Helmet>
                  <BreadcrumbBox
                        linkTitle="Blogs"
                        link="/blogs"
                        title={blog.title}
                  />

                  <BlogDetailContainer>
                        <Row className="mb-5 pb-5">
                              <Col lg={8}>
                                    <BlogDetailContent>
                                          <BlogDetailImage>
                                                <img
                                                      src={blog.image}
                                                      alt={blog.title}
                                                      width="100%"
                                                      height="100%"
                                                />
                                                <BlogCategory
                                                      to={`/blogs/${blog.category_slug}/category`}
                                                >
                                                      {blog.category}
                                                </BlogCategory>
                                          </BlogDetailImage>

                                          <BlogExtra>
                                                <BlogExtraItem>
                                                      <i className="bx bx-user"></i>
                                                      <div>By: Admin</div>
                                                </BlogExtraItem>
                                                <BlogExtraItem>
                                                      <i className="bx bx-calendar"></i>
                                                      <div> {blog.date}</div>
                                                </BlogExtraItem>
                                          </BlogExtra>
                                          <BlogItemTitle>
                                                <h2>{blog.title}</h2>
                                          </BlogItemTitle>
                                          <BlogDetailDescription>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: blog.description,
                                                      }}
                                                ></div>
                                          </BlogDetailDescription>
                                    </BlogDetailContent>
                              </Col>
                              <Col>
                                    <BlogWidget title="Categories">
                                          {categories?.map(
                                                (category, index) => (
                                                      <WidgetCategoryItem
                                                            key={index}
                                                            to={`/blogs/${category.slug}/category`}
                                                      >
                                                            <div>
                                                                  {
                                                                        category.title
                                                                  }
                                                            </div>
                                                            <WidgetCategoryCount>
                                                                  {`( ${category.blogs} )`}
                                                            </WidgetCategoryCount>
                                                      </WidgetCategoryItem>
                                                )
                                          )}
                                    </BlogWidget>
                                    <BlogWidget title="Latest Blogs">
                                          {blogs?.map((blog, index) => (
                                                <RealtedBlogItem key={index}>
                                                      <Link
                                                            to={`/blogs/${blog.slug}`}
                                                            className="text-decoration-none"
                                                      >
                                                            <img
                                                                  src={
                                                                        blog.image
                                                                  }
                                                                  alt={
                                                                        blog.title
                                                                  }
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </Link>

                                                      <RealtedBlogItemTitle
                                                            to={`/blogs/${blog.slug}`}
                                                            className="text-decoration-none"
                                                      >
                                                            <div>
                                                                  {blog.title}
                                                            </div>
                                                            <BlogExtraItem
                                                                  color={true}
                                                            >
                                                                  <i className="bx bx-calendar"></i>
                                                                  <div>
                                                                        {
                                                                              blog.date
                                                                        }
                                                                  </div>
                                                            </BlogExtraItem>
                                                      </RealtedBlogItemTitle>
                                                </RealtedBlogItem>
                                          ))}
                                    </BlogWidget>
                              </Col>
                        </Row>
                  </BlogDetailContainer>
            </>
      );
};

export default BlogDetail;
