import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const GalleryContainer = styled(Container)`
      margin-bottom: 100px !important;
`;
export const GalleryHeading = styled.div`
      h2 {
            font-size: 28px;
            span {
                  color: #ff7906;
            }
      }
`;
