import styled from 'styled-components';
import { Container } from 'react-bootstrap';
export const ProductListSection = styled.section`
   margin-bottom: 100px;
`;
export const ProductListFilter = styled.div``;
export const ProductListContainer = styled(Container)``;

export const ResultContainer = styled.div`
   padding: 15px 30px;
   border: 1px solid #eee;
   font-size: 16px;
   line-height: 22px;
   font-weight: 600;
   span {
      color: ${(props) => props.theme.primary};
   }
   margin-bottom: 30px;
`;


// product detail page css

export const ProductDetailContainer = styled.div`
`;