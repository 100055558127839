import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules

import { FeaturesSliderContainer } from './styles';
import {
      ProductContentFeaturesContent,
      ProductContentFeaturesImage,
      ProductContentFeaturesItem
} from '../styles';
import AntiAlgalIcon from '../../../icons/AntiAlgalIcon';
import FlowerIcon from '../../../icons/FlowerIcon';
// Import Swiper React components

const ProductFeaturesSlider = ({ product }) => {
      return (
            <>
                  <FeaturesSliderContainer>
                        <Swiper
                              slidesPerView={1}
                              spaceBetween={10}
                              breakpoints={{
                                    640: {
                                          slidesPerView: 2,
                                          spaceBetween: 20
                                    },
                                    768: {
                                          slidesPerView: 4,
                                          spaceBetween: 40
                                    },
                                    1024: {
                                          slidesPerView: 4,
                                          spaceBetween: 50
                                    }
                              }}
                              className="mySwiper"
                        >
                              <SwiperSlide>
                                    <ProductContentFeaturesItem className="d-flex flex-column gap-3 align-items-center ">
                                          <ProductContentFeaturesImage className="d-flex align-items-center justify-content-center">
                                                <AntiAlgalIcon />
                                          </ProductContentFeaturesImage>
                                          <ProductContentFeaturesContent className="text-algin-center">
                                                Anti Algal
                                          </ProductContentFeaturesContent>
                                    </ProductContentFeaturesItem>
                              </SwiperSlide>
                              <SwiperSlide>
                                    <ProductContentFeaturesItem className="d-flex flex-column gap-3 align-items-center ">
                                          <ProductContentFeaturesImage className="d-flex align-items-center justify-content-center">
                                                <FlowerIcon />
                                          </ProductContentFeaturesImage>
                                          <ProductContentFeaturesContent className="text-algin-center">
                                                No Added lead or Mercury
                                          </ProductContentFeaturesContent>
                                    </ProductContentFeaturesItem>
                              </SwiperSlide>
                              {product.warranty && (
                                    <SwiperSlide>
                                          <ProductContentFeaturesItem className="d-flex flex-column gap-3 align-items-center ">
                                                <ProductContentFeaturesImage className="d-flex align-items-center justify-content-center">
                                                      <img
                                                            src="/images/products/warranty.png"
                                                            style={{
                                                                  padding: '6px'
                                                            }}
                                                            width="100%"
                                                            height="100%"
                                                            alt="Yeti Paints Nepal"
                                                      />
                                                </ProductContentFeaturesImage>
                                                <ProductContentFeaturesContent className="text-algin-center">
                                                      {product.warranty}
                                                </ProductContentFeaturesContent>
                                          </ProductContentFeaturesItem>
                                    </SwiperSlide>
                              )}
                        </Swiper>
                  </FeaturesSliderContainer>
            </>
      );
};

export default ProductFeaturesSlider;
