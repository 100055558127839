import React from 'react';
import {
      FooterAnimation,
      FooterBody,
      FooterContainer,
      FooterContent,
      FooterCredential,
      FooterCredentialContainer,
      FooterHeading,
      FooterItem,
      FooterRow,
      FooterSocialMedia
} from './styles';
import FooterMenuItem from './FooterMenuItem';
import { Link } from 'react-router-dom';

const Footer = () => {
      const currentYear = new Date().getFullYear();

      return (
            <>
                  <FooterContainer>
                        <FooterAnimation>
                              <svg
                                    class="waves"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 24 150 28"
                                    preserveAspectRatio="none"
                                    shape-rendering="auto"
                              >
                                    <defs>
                                          <path
                                                id="gentle-wave"
                                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                                          ></path>
                                    </defs>
                                    <g class="parallax">
                                          <use
                                                xlinkHref="#gentle-wave"
                                                x="48"
                                                y="0"
                                                fill="rgba(218, 0, 27, 1)"
                                          ></use>
                                          <use
                                                xlinkHref="#gentle-wave"
                                                x="48"
                                                y="3"
                                                fill="rgba(218, 0, 27, 0.4)"
                                          ></use>
                                          <use
                                                xlinkHref="#gentle-wave"
                                                x="48"
                                                y="5"
                                                fill="rgba(218, 0, 27, 0.2)"
                                          ></use>
                                          <use
                                                xlinkHref="#gentle-wave"
                                                x="48"
                                                y="7"
                                                fill="rgba(218, 0, 27, 1)"
                                          ></use>
                                    </g>
                              </svg>
                        </FooterAnimation>
                        <FooterBody>
                              <FooterRow>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Quick Links</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            link="/faqs"
                                                            title="FAQs"
                                                      />
                                                      <FooterMenuItem
                                                            link="/blogs"
                                                            title="Blogs and News"
                                                      />
                                                      <FooterMenuItem
                                                            link="/terms-and-condition"
                                                            title="Terms & Condition"
                                                      />
                                                      <FooterMenuItem
                                                            link="/warranty"
                                                            title="Warranty "
                                                      />
                                                      <FooterMenuItem
                                                            link="/warranty-policy"
                                                            title="Warranty Policy"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>

                                    <FooterItem lg={5}>
                                          <FooterHeading>
                                                <h4>
                                                      <img
                                                            src="/images/png/logo/logo.png"
                                                            alt="Yeti Paints Official logo"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </h4>
                                          </FooterHeading>
                                          <FooterContent marginbottom={true}>
                                                <FooterSocialMedia>
                                                      <Link to="https://www.facebook.com/yetipaints/">
                                                            <img
                                                                  src="/images/img/footer/facebook.png"
                                                                  alt="Yeti Paints Official Facebook Page"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </Link>
                                                      <Link to="https://www.instagram.com/yetipaints.com.np/">
                                                            <img
                                                                  src="/images/img/footer/instagram.png"
                                                                  alt="Yeti Paints Official Instagram Page"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </Link>
                                                      <Link to="https://www.linkedin.com/company/yetipaints/">
                                                            <img
                                                                  src="/images/img/footer/linked.png"
                                                                  alt="Yeti Paints Official Linkedin Page"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </Link>
                                                </FooterSocialMedia>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Useful Links</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            title="Downloads"
                                                            link="downloads"
                                                      />
                                                      <FooterMenuItem
                                                            title="Catalogue"
                                                            link="catalogue"
                                                      />
                                                      <FooterMenuItem
                                                            title="Our Gallery"
                                                            link="/gallery"
                                                      />
                                                      <FooterMenuItem
                                                            title="Achievement"
                                                            link="achievements"
                                                      />
                                                      <FooterMenuItem
                                                            title="Careers"
                                                            link="careers"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                              </FooterRow>
                        </FooterBody>
                        <FooterCredential>
                              <FooterCredentialContainer>
                                    <div className="footer__copyright">
                                          Copyright © {currentYear} by{' '}
                                          <Link
                                                to="https://yetipaints.com/"
                                                className="text-decoration-none"
                                          >
                                                Yeti Paint Nepal Pvt. Ltd.
                                          </Link>
                                    </div>
                                    <div className="footer__credit">
                                          Powered By:{' '}
                                          <Link
                                                to="https://infinityinfosys.com/"
                                                className="text-decoration-none"
                                          >
                                                Infinity Infosys Pvt. Ltd.
                                          </Link>
                                    </div>
                              </FooterCredentialContainer>
                        </FooterCredential>
                  </FooterContainer>
            </>
      );
};

export default Footer;
