import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const AchievementContainer = styled(Container)`
margin-bottom: 100px !important`;
export const AchievementContent = styled.div``;
export const AchievementImage = styled.div``;
export const AchievementTitle = styled.h2`
      font-size: 18px;
`;
