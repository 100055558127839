import styled from "styled-components";

export const ProductAdditionalContainer = styled.div`
      background: #f3f3f3;
      padding-top: 50px;
      padding-bottom: 70px;

      .border-end {
            border-right: 1px solid #c3c3c3 !important;
      }

      ul li {
            margin-right: 20px;
      }
      ul {
            border-bottom: 0;
      }
      ul li button {
            width: 200px;
            padding: 12px 0px;
      }
      h3 {
            font-size: 20px;
            color: #3f275b;
      }
      .nav-tabs .nav-link.active {
            color: var(--bs-nav-tabs-link-active-color);
            background-color: var(--bs-nav-tabs-link-active-bg);
            background: ${(props) => props.theme.primary};
            color: #fff;
            position: relative;
            border-color: transparent;
      }

      .nav-tabs .nav-link {
            margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
            border: var(--bs-nav-tabs-border-width) solid transparent;
            background: #3f275b;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-radius: 5px;
            color: #fff;
      }

      @media screen and (max-width: 578px) {
            ul {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  width: 100%;
                  gap: 10px;

                  li {
                        margin-right: 0;
                        max-width: fit-content;

                        button {
                              padding: 10px 16px;
                              width: 100%;
                              max-width: fit-content;
                        }
                  }
            }
      }
`;

export const ProductAdditionalItem = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 578px) {
            width: 100%;
            flex: 100%;
            text-align: left !important;
            align-items: start;
            margin-bottom: 1rem;
            border-bottom: 1px solid #ccc;

            &:last-child {
                  border-bottom: 0;
            }
      }
`;

export const ProductAdditionalContent = styled.div`
      display: flex;
      gap: 1.5rem;

      @media screen and (max-width: 578px) {
            flex-direction: column;
      }
`;
export const ProductAdditionalTitle = styled.div`
      h4,
      h3 {
            font-size: 19px;
            color: #412d74;
      }
`;
export const ProductAdditionalDesc = styled.p`
      font-size: 15px;
      color: #555;
      text-align: justify;
`;

export const ProductAdditionalImage = styled.div`
      img {
            width: 130px;
            height: 130px;
            object-fit: cover;
            border-radius: 50%;
      }
`;
export const ProductAdditionalWrapper = styled.div``;
