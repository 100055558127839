import { Col, Container, Row } from 'react-bootstrap';
import { keyframes, styled } from 'styled-components';

export const FooterContainer = styled.footer`
   background: rgba(218, 0, 27, 1);
   ${'' /* margin-top: 90px; */}
`;

export const FooterRow = styled(Row)`
   gap: 3rem;

   @media screen and (max-width: 568px) {
      gap: 0;
      margin: 0;
      padding: 0 20px;
      width: 100%;

      & > div:first-child {
         order: 1;
         margin: 0;
         padding: 0;
      }

      & > div:nth-child(3) {
         order: 2;
         margin: 0;
         padding: 0;
      }

      & > div:nth-child(2) {
         order: 3;
      }
   }
`;

export const FooterBody = styled(Container)`
   padding: 40px 0px 60px;

   @media screen and (max-width: 568px) {
      padding-bottom: 40px;
   }
`;

export const FooterHeading = styled.div`
   margin-bottom: 1.6rem;

   h4 {
      color: #fff;
      font-size: 24px;

      img {
         width: 200px;
      }
   }
`;

export const FooterItem = styled(Col)`
   &:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url('/images/img/footer/white-banner.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 224px;

      ${FooterHeading} {
         margin-bottom: 1rem;
         margin-top: -16px;
      }

      @media screen and (max-width: 568px) {
         height: 170px;
         margin-top: 20px;
      }
   }

   &:last-child {
      text-align: end;
   }
`;

const LinkColorAnimation = keyframes`
0% {
    background-size: 400% 400%;
    background-position: 0% 100%;
}
15% {
    background-size: 170% 400%;
    background-position: 100% 100%;
}
100% {
    background-size: 240% 400%;
    background-position: 100% 100%;
}
`;
export const FooterContent = styled.div`
   margin-bottom: ${(props) => props.marginbottom && '1.5rem'};

   p {
      color: #797f83;
      text-align: justify;
      font-size: 14px;
   }

   ul {
      padding: 0;

      li {
         list-style-type: none;
         margin-bottom: 10px;

         a {
            color: #f1f1f1;
            font-size: 14px;
            position: relative;
            overflow: hidden;
            display: block;
            transition: all 0.3s ease 0s;

            &:after {
               content: attr(data-title);
               position: absolute;
               left: 0;
               top: 0;
               display: block;
               width: 100%;
               transform: translateY(-100%);
               transition: all 0.4s;
               color: #fff;
            }
            span {
               display: block;
               transform: translateY(0);
               transition: all 0.4s;
            }
            &:hover {
               span {
                  transform: translateY(100%);
                  transition: all 0.4s;
               }
               &:after {
                  transform: translateY(0);
               }
            }
         }
      }
   }

   form {
      margin-top: 30px;
   }

   .form__group {
      background: #fff;
      padding: 2px 15px;
      .form-control {
         border: none;
         background-color: transparent;
         font-size: 13px;
         &:focus {
            box-shadow: none;
         }
      }
      .btn-primary {
         background: transparent;
         color: #000;
         border: none;
         font-size: 22px;
      }
   }
`;

export const FooterContact = styled.div`
   display: flex;
   align-items: center;
   gap: 1rem;
   margin-bottom: 1.5rem;

   span {
      &:first-child {
         color: ${(props) => props.theme.white};
      }

      color: #a5a5a5;
   }
`;

export const FooterCredential = styled.div`
   color: #fff;
   font-size: 14px;
   font-family: 'Rajdhani', sans-serif;
`;

export const FooterCredentialContainer = styled(Container)`
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 1rem 0;
   border-top: 1px solid #c1c1c1;
   a {
      color: ${(props) => props.theme.white};
      &:hover {
         color: ${(props) => props.theme.white};
      }
   }

   @media screen and (max-width: 568px) {
      display: block;
      padding: 1rem 20px;
   }
`;
const MoveForever = keyframes`
0% {
    transform: translate3d(-90px,0,0);
}
100% {
    transform: translate3d(85px,0,0);
}
`;
export const FooterAnimation = styled.div`
   .waves {
      margin-top: -90px;
      position: relative;
      width: 100%;
      height: 60px;
      margin-bottom: -7px;
      min-height: 60px;
      max-height: 100px;
      &:not(svg) {
         transform-origin: 0px 0px;
      }
      .parallax > use:nth-child(1) {
         animation-delay: -2s;
         animation-duration: 7s;
      }
      .parallax > use {
         animation: ${MoveForever} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5)
            infinite;
      }
   }
`;

export const FooterSocialMedia = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 27px;

   a {
      img {
         width: 25px;
         height: auto;
      }
   }
`;
