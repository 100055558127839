import React, { useEffect, useState } from 'react';
import {
      BlogSection,
      BlogSlider,
      BlogSliderImage,
      BlogSliderTitle
} from './style';
import axios from 'axios';
import { SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay } from 'swiper/modules';
import { Container } from 'react-bootstrap';
import { AboutButton, AboutTitle, CategoryHeading } from '../../styles';
import { Link } from 'react-router-dom';

const BlogBox = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                        }
                  })
                  .catch((error) => {
                        console.log(error);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BlogSection>
                        <Container>
                              <CategoryHeading>
                                    {/* <h2>Our Products</h2> */}
                                    <AboutTitle>
                                          <h2>[ Blogs & News ] </h2>
                                          <h3>Latest from our blogs</h3>
                                    </AboutTitle>
                                    <AboutButton>
                                          <Link to="/blogs">
                                                Explore More
                                                <span class="material-symbols-outlined">
                                                      arrow_right_alt
                                                </span>
                                          </Link>
                                    </AboutButton>
                              </CategoryHeading>
                              <BlogSlider
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    loop={true}
                                    modules={[Autoplay]}
                                    autoplay={{
                                          delay: 3000,
                                          disableOnInteraction: false
                                    }}
                                    pagination={{
                                          clickable: true
                                    }}
                                    breakpoints={{
                                          640: {
                                                slidesPerView: 1,
                                                spaceBetween: 20
                                          },
                                          768: {
                                                slidesPerView: 2,
                                                spaceBetween: 40
                                          },
                                          1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30
                                          }
                                    }}
                                    className="mySwiper"
                              >
                                    {blogs?.length &&
                                          blogs.map((banner, index) => (
                                                <SwiperSlide key={index}>
                                                      <BlogSliderImage>
                                                            <Link
                                                                  to={`/blogs/${banner.slug}`}
                                                            >
                                                                  <img
                                                                        src={
                                                                              banner.image
                                                                        }
                                                                        alt="title"
                                                                        className="h-100 w-100"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </Link>
                                                      </BlogSliderImage>
                                                      <BlogSliderTitle>
                                                            <Link
                                                                  to={`/blogs/${banner.slug}`}
                                                            >
                                                                  {banner.title}
                                                            </Link>
                                                      </BlogSliderTitle>
                                                </SwiperSlide>
                                          ))}
                              </BlogSlider>
                        </Container>
                  </BlogSection>
            </>
      );
};

export default BlogBox;
