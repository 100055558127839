import React from 'react';
import {
      HeaderInfoContainer,
      HeaderInfoContent,
      HeaderInfoTitle
} from './styles';

const HeaderInfoItem = ({ icon, title, info, to }) => {
      return (
            <>
                  <HeaderInfoContainer>
                        <img
                              src={icon}
                              alt="call log svg"
                              width="100%"
                              height="100%"
                        />
                        <HeaderInfoContent to={to}>
                              <HeaderInfoTitle>{title}</HeaderInfoTitle>
                              <p>{info}</p>
                        </HeaderInfoContent>
                  </HeaderInfoContainer>
            </>
      );
};

export default HeaderInfoItem;
