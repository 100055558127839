import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade } from 'swiper/modules';

import { Autoplay } from 'swiper/modules';
import { BannerSlider } from './styles';
import axios from 'axios';

const HomeBanner = () => {
      const [loading, setLoading] = useState(false);
      const [banners, setBanner] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/banners`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBanner(response.data.banners);
                        }
                  })
                  .catch((error) => {
                        console.log(error);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);
      return (
            <>
                  <BannerSlider
                        effect={'fade'}
                        spaceBetween={30}
                        loop={true}
                        modules={[EffectFade, Autoplay]}
                        autoplay={{
                              delay: 3000,
                              disableOnInteraction: false
                        }}
                        className="mySwiper"
                  >
                        {banners?.length &&
                              banners.map((banner, index) => (
                                    <SwiperSlide key={index}>
                                          <img
                                                src={banner.image}
                                                alt="title"
                                                className="h-100"
                                                width="100%"
                                                height="100%"
                                          />
                                    </SwiperSlide>
                              ))}
                  </BannerSlider>
            </>
      );
};

export default HomeBanner;
