import React from 'react';
import {
      AchievementContent,
      AchievementImage,
      AchievementTitle
} from '../styles';

const AchievementItemBox = ({ title, img }) => {
      return (
            <AchievementContent>
                  <AchievementImage>
                        <img
                              src={img}
                              alt={title}
                              className="img-fluid"
                              width="100%"
                              height="100%"
                        />
                  </AchievementImage>
                  <AchievementTitle className="mt-4">{title}</AchievementTitle>
            </AchievementContent>
      );
};

export default AchievementItemBox;
