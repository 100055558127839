import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import BlogItemBox from '../../components/blog/BlogItemBox';

import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const Blog = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                        }
                  })
                  .catch((error) => {
                        console.log(error);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('blogs');

      return (
            <>
                  <BreadcrumbBox title="Blogs and News" />
                  <Container className="my-5 pb-5">
                        <Row className="g-4">
                              {blogs?.length > 0 &&
                                    blogs.map((blog, index) => (
                                          <Col lg={4} key={index}>
                                                <BlogItemBox blog={blog} />
                                          </Col>
                                    ))}
                        </Row>
                  </Container>
            </>
      );
};

export default Blog;
