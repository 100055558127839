import React from 'react';
import {
      VisualizerButton,
      VisualizerContent,
      VisualizerDescription,
      VisualizerHeading,
      VisualizerImage,
      VisualizerSection
} from '../styles';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const VisualizerBox = () => {
      return (
            <>
                  <VisualizerSection>
                        <Container>
                              <Row>
                                    <Col lg={5}>
                                          <VisualizerImage>
                                                <img
                                                      src="/images/img/category/visualizer/visualizer.png"
                                                      className="img-fluid "
                                                      alt="Visualize Your Home with Yeti Paints"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </VisualizerImage>
                                    </Col>
                                    <Col lg={6}>
                                          <VisualizerContent>
                                                <VisualizerHeading>
                                                      <h2 className="text-white">
                                                            Visualize Your Home
                                                      </h2>
                                                </VisualizerHeading>
                                                <VisualizerDescription>
                                                      <p>
                                                            Choose a soothing
                                                            and neutral color
                                                            palette as the base,
                                                            such as light gray,
                                                            beige, or pastel
                                                            shades. Add pops of
                                                            color with
                                                            accessories or
                                                            accent pieces like
                                                            pillows, rugs, or
                                                            artwork.
                                                            <br />
                                                            inviting atmosphere.
                                                            Consider a
                                                            combination of
                                                            ceiling lights,
                                                            floor lamps, and
                                                            table lamps. Use
                                                            dimmers to adjust
                                                            the lighting
                                                            intensity based on
                                                            your mood and needs.
                                                      </p>
                                                </VisualizerDescription>
                                                <VisualizerButton>
                                                      <Link
                                                            to="/visualizer"
                                                            className="text-decoration-none"
                                                      >
                                                            Paint Your Room
                                                            Online
                                                      </Link>
                                                </VisualizerButton>
                                          </VisualizerContent>
                                    </Col>
                              </Row>
                        </Container>
                  </VisualizerSection>
            </>
      );
};

export default VisualizerBox;
