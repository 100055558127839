import React from 'react';
import { Col, Form } from 'react-bootstrap';

const FormTextarea = ({ classes, title, required, handleChange }) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Label>
                              {title}{' '}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </Form.Label>
                        <Form.Control
                              required={true}
                              as="textarea"
                              rows={4}
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                        />
                  </Col>
            </>
      );
};

export default FormTextarea;
