import styled from 'styled-components';

export const FaqAccordionContainer = styled.div`
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 0px 20px;
      .accordion-item {
            border: 0;
      }
      svg {
            width: 27px;
            height: 27px;
            margin-right: 20px;
            fill: #565656;
      }
      button {
            border-bottom: 1px solid #edeef2;
      }

      .accordion-button:not(.collapsed) {
            color: #d90117;
            background-color: transparent;
            box-shadow: none;
            border-bottom: 1px solid #d90117;

            svg {
                  g {
                        fill: #d90117;
                  }
                  fill: #d90117;
            }
      }
      .accordion-button:focus {
            z-index: 3;
            border-color: #d90117;
            outline: 0;
            box-shadow: none;
      }
      .accordion-button::after {
            flex-shrink: 0;
            width: 0px;
            height: 0px;
      }
`;
