import React from 'react';

const DownloadIcon = () => {
   return (
      <>
         <svg
            width="20px"
            height="18px"
            viewBox="0 0 20 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
         >
            <title>FC6AF43C-DD99-458F-BE2E-11C141CB5111</title>{' '}
            <defs>
               {' '}
               <filter color-interpolation-filters="auto" id="filter-1">
                  {' '}
                  <feColorMatrix
                     in="SourceGraphic"
                     type="matrix"
                     values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                  ></feColorMatrix>{' '}
               </filter>{' '}
            </defs>{' '}
            <g
               id="UI"
               stroke="none"
               stroke-width="1"
               fill="none"
               fill-rule="evenodd"
            >
               {' '}
               <g
                  id="003_Competition_Events"
                  transform="translate(-1120.000000, -1449.000000)"
               >
                  {' '}
                  <g
                     id="Texto"
                     transform="translate(525.000000, 1340.000000)"
                     filter="url(#filter-1)"
                  >
                     {' '}
                     <g transform="translate(241.000000, 108.000000)">
                        {' '}
                        <path
                           d="M373.259259,9.92955556 C373.668889,9.92955556 374,10.2606667 374,10.6702963 L374,10.6702963 L374,18.0777037 C374,18.4873333 373.668889,18.8184444 373.259259,18.8184444 L373.259259,18.8184444 L354.740741,18.8184444 C354.331111,18.8184444 354,18.4873333 354,18.0777037 L354,18.0777037 L354,10.6702963 C354,10.2606667 354.331111,9.92955556 354.740741,9.92955556 C355.15037,9.92955556 355.481481,10.2606667 355.481481,10.6702963 L355.481481,10.6702963 L355.481481,17.336963 L372.518519,17.336963 L372.518519,10.6702963 C372.518519,10.2606667 372.84963,9.92955556 373.259259,9.92955556 Z M364.016444,1 C364.466815,1 364.832,1.36444444 364.832,1.81555556 L364.832,1.81555556 L364.832,10.9311111 L367.936444,7.82666667 C368.254963,7.50814815 368.770519,7.50814815 369.089037,7.82666667 C369.408296,8.14592593 369.408296,8.66148148 369.089037,8.98 L369.089037,8.98 L364.593481,13.4762963 C364.517926,13.5518519 364.427556,13.6111111 364.328296,13.6518519 C364.228296,13.6933333 364.123111,13.7148148 364.016444,13.7148148 C363.910519,13.7148148 363.804593,13.6933333 363.705333,13.6518519 C363.605333,13.6111111 363.514963,13.5511111 363.439407,13.4755556 L363.439407,13.4755556 L358.910519,8.94592593 C358.591259,8.62740741 358.591259,8.11185185 358.910519,7.79333333 C359.228296,7.47407407 359.744593,7.47407407 360.06237,7.79333333 L360.06237,7.79333333 L363.20163,10.9311111 L363.20163,1.81555556 C363.20163,1.36444444 363.566815,1 364.016444,1 Z"
                           id="Combined-Shape"
                           fill="#3F689D"
                        ></path>{' '}
                     </g>{' '}
                  </g>{' '}
               </g>{' '}
            </g>{' '}
         </svg>
      </>
   );
};

export default DownloadIcon;
