import { Container } from "react-bootstrap";
import styled from "styled-components";

export const ContactUsContainer = styled(Container)`
      padding: 100px 0px 100px 0px;

      @media screen and (max-width: 578px) {
            padding: 50px 20px;
      }
`;

export const ContactUsHeading = styled.div`
      h2 {
            font-size: 40px;
            line-height: 48px;
            font-weight: 500;
      }

      @media screen and (max-width: 578px) {
            h2 {
                  font-size: 24px;
                  line-height: 28px;
            }
      }
`;

export const ContactUsContentInfo = styled.div``;
export const ContactUsContentIcon = styled.div`
      svg {
            width: 25px;
            height: 25px;
            ${"" /* fill: ${(props) => props.theme.primary}; */}
            fill: #F26209;
      }
`;
export const ContactUsContentWrapper = styled.div.attrs(() => ({
      className: "d-flex align-items-center gap-2",
}))`
      margin-bottom: 20px;
`;

export const ContactUsForm = styled.div``;
export const FormIcon = styled.div`
      width: 27px;
      height: 27px;
`;
export const ContactFormWrapper = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      gap: 2px;
      align-items: center;
      padding: 10px 0px;
      border-bottom: 1px solid rgba(1, 3, 20, 0.2);
      position: relative;
      transition: all 0.3s linear;
      input {
            ${"" /* padding: 0px 0px 0px 40px; */}
            color: #010314;
      }
      .form-control {
            &:focus {
                  background-color: transparent;
                  border-color: none;
                  outline: 0;
                  box-shadow: none;
            }
      }
      &:after {
            position: absolute;
            bottom: -1px;
            left: 0;
            content: "";
            height: 1px;
            width: 0%;
            background: #010314;
            transition: all 0.3s linear;
      }
      &:hover {
            &:after {
                  transition: all 0.3s linear;
                  width: 100%;
            }
      }
`;
export const ContactUsContent = styled.div`
      ${ContactUsHeading} {
            span {
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 700;
                  font-style: normal;
                  text-transform: uppercase;
                  letter-spacing: 1.8px;
                  opacity: 0.9;
                  color: #f26209;
            }
            p {
                  color: #61676f;
            }
      }
      ${ContactUsContentInfo} {
            font-size: 18px;
            color: #61676f;
      }
`;

export const FormButton = styled.div`
      button {
            width: 200px;
            background-color: ${(props) => props.theme.primary};
            padding: 10px 20px;
            svg {
                  width: 20px;
                  height: 20px;
                  fill: ${(props) => props.theme.white};
                  margin-right: 5px;
            }
            &:hover {
                  background-color: #f16108;
            }
      }
`;
export const MapContainer = styled.div`
      height: 400px;
      margin-top: 70px;
`;
