import styled from 'styled-components';

export const ProductSliderContainer = styled.div`
      margin: 0px 4px 8px 4px;
`;
export const ProductSliderImage = styled.div.attrs(() => ({
      className: 'd-flex align-items-center justify-content-center'
}))`
      position: relative;
      width: 100%;

      img {
            width: 320px;
            border-radius: 15px;
      }
      ${'' /* height: 150px; */}
      ${
            '' /* img {
            width: 180px;
            height: auto;
            position: absolute;
            top: -75px;
            left: 50%;
            transform: translateX(-50%);
      } */
      }
`;
export const ProductSliderContent = styled.div`
      padding: 34px 32px;
      img {
            height: 80px;
      }
`;
export const ProductSliderAvailableBox = styled.div`
      display: flex;
      align-items: center;
      gap: 1.5rem;
      justify-content: start;
`;
export const ProductSliderAvailableItem = styled.div`
      border: 1px solid ${(props) => props.theme.primary};
      padding: 10px 30px;
`;
export const ProductSliderTitle = styled.h2`
      font-size: 25px;
      color: ${(props) => props.theme.paint};
      font-weight: 500;
`;

export const ProductSliderFeature = styled.div.attrs(() => ({
      className: 'd-flex gap-3'
}))``;

export const ProductSliderFeatureItem = styled.div`
      img {
            height: 50px;
      }
`;

export const ProductSliderQuality = styled.div``;
export const ProductSliderQualityItem = styled.div``;
export const ProductSliderQualityIcon = styled.div.attrs(() => ({
      className: 'd-flex align-items-center justify-content-center rounded'
}))`
      background: #fecd57;
      padding: 10px;
      height: 35px;
      width: 35px;
      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
      }
`;
export const ProductSliderQualityContent = styled.div`
      h4 {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 500;
      }
      p {
            font-size: 14px;
            margin-bottom: 0;
            color: #d12027;
            font-weight: 400;
      }
`;

export const Availability = styled.div``;
