import React from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import PaperPlaneIcon from '../../icons/PaperPlaneIcon';

const FormButton = ({ loading, title }) => {
      return (
            <>
                  <Col lg={12} className={!title && 'text-center pt-2'}>
                        <Button
                              type="submit"
                              className="custom-button border-0"
                        >
                              <PaperPlaneIcon />
                              {title ? title : 'Submit'}
                              {loading && <Spinner />}
                        </Button>
                  </Col>
            </>
      );
};

export default FormButton;
