import React from 'react';
import {
      ProductSliderAvailableBox,
      ProductSliderAvailableItem,
      ProductSliderContainer,
      ProductSliderContent,
      ProductSliderImage
} from './styles';

export default function ProductSlider({ product, loading, sizes }) {
      return (
            <>
                  <ProductSliderContainer>
                        <ProductSliderImage>
                              <img
                                    src={product.image}
                                    alt={product.title}
                                    className="img-fluid"
                                    width="100%"
                                    height="100%"
                              />
                        </ProductSliderImage>
                        {!loading && sizes?.length > 0 && (
                              <ProductSliderContent className=" ">
                                    <h5 className="mb-3">Available in: </h5>
                                    <ProductSliderAvailableBox>
                                          {sizes?.map((size, index) => (
                                                <ProductSliderAvailableItem
                                                      key={index}
                                                >
                                                      {size}
                                                </ProductSliderAvailableItem>
                                          ))}
                                    </ProductSliderAvailableBox>
                              </ProductSliderContent>
                        )}
                  </ProductSliderContainer>
            </>
      );
}
