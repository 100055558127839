import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const BlogSection = styled.div`
      padding: 5rem 0;
`;

export const BlogSlider = styled(Swiper)``;

export const BlogSliderImage = styled.div`
      width: 100%;
      height: 280px;
      border-radius: 15px;

      a {
            display: inline-block;
            overflow: hidden;
            border-radius: 15px;

            img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 15px;
                  transition: transform 1.8s ease-in;

                  &:hover {
                        transform: scale(1.1);
                        transition: transform 2s ease-in-out;
                  }
            }
      }

      @media screen and (max-width: 578px) {
            height: auto;
      }
`;

export const BlogSliderTitle = styled.h2`
      font-size: 20px;
      margin-top: 10px;
      padding: 0 10px;

      a {
            color: #222;
            text-decoration: none;
            transition: all 0.3s ease-in;

            &:hover {
                  color: ${(props) => props.theme.paint};
                  transition: all 0.3s ease-in;
            }
      }
`;
