import styled from 'styled-components';

export const ProductCardContainer = styled.div`
      text-align: center;

      .card-body {
            .card-title {
                  font-size: 18px;
                  color: #1c1c1c;

                  &:hover {
                        color: ${(props) => props.theme.paint};
                  }
            }
      }

      p {
            color: #666;
      }

      img {
            border-radius: 15px;
      }
`;
