import React from 'react';

const MenuIcon = () => {
      return (
            <svg viewBox="0 0 100 25">
                  <path
                        id="curve_open"
                        d="M0, 0 C37.5, 0, 37.5, 25, 50, 25 S62.5, 0, 100, 0"
                        data-start-d="M0, 0 C37.5, 0, 37.5, 25, 50, 25 S62.5, 0, 100, 0"
                        data-end-d="M0, 0 C37.5, 0, 37.5, 0, 50, 0 S62.5, 0, 100, 0"
                  ></path>
            </svg>
      );
};

export default MenuIcon;
