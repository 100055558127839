import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../frontend/home';
import Product from '../frontend/product';
import ProductDetail from '../frontend/product/ProductDetail';
import FaqPage from '../frontend/faq';
import Blog from '../frontend/blog';

import BlogDetail from '../frontend/blogDetail/BlogDetail';
import AboutUs from '../frontend/about';
import ProductCategory from '../frontend/productcategory';
import ContactPage from '../frontend/contact';
import PrivacyPolicy from '../frontend/PrivacyPolicy';
import Achievement from '../frontend/achievements';
import Gallery from '../frontend/gallery';
import WarrentyPage from '../frontend/warrenty';
import ColorShade from '../frontend/colorShade';
import TermsAndConditions from '../frontend/TermsAndCondition';
import DealerPolicy from '../frontend/DealerPolicy';
import Downloads from '../frontend/download';
import WarrentyPolicy from '../frontend/warrenty/WarrentyPolicy';
import Visualizer from '../frontend/visualizer';
import Dealers from '../frontend/dealer';
import Catalogue from '../frontend/catalogue';
import Career from '../frontend/career';
import CareerDetail from '../frontend/career/components/CareerDetail';
import Inspiration from '../frontend/inspiration';
import BlogCategoryPage from '../frontend/blogCategory';
import CatalogueDetail from '../frontend/catalogureShow/CatalogueShow';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route exact path="/" element={<Home />} />

                        {/* About Us Page */}
                        <Route path="/about-us" element={<AboutUs />} />

                        {/* Product Page */}
                        <Route path=":categoryId" element={<Product />} />
                        <Route
                              path="/products/:productId"
                              element={<ProductDetail />}
                        />
                        <Route path="/products" element={<ProductCategory />} />

                        {/* Faq page  */}
                        <Route path="/faqs" element={<FaqPage />} />

                        {/* Privacy Policy */}
                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />

                        {/* Blog Page  */}
                        <Route path="/blogs" element={<Blog />} />
                        <Route path="/blogs/:blogId" element={<BlogDetail />} />
                        <Route
                              path="/blogs/:categoryId/category"
                              element={<BlogCategoryPage />}
                        />

                        {/* Contact Us Page  */}
                        <Route path="/contact-us" element={<ContactPage />} />

                        {/* Achievement Page  */}
                        <Route path="/achievements" element={<Achievement />} />

                        {/* Gallery Page  */}
                        <Route path="/gallery" element={<Gallery />} />

                        {/* Color Shade Page  */}
                        <Route path="/color-shade" element={<ColorShade />} />

                        {/* Policy Pages */}
                        <Route path="/warranty" element={<WarrentyPage />} />
                        <Route
                              path="/terms-and-condition"
                              element={<TermsAndConditions />}
                        />
                        <Route
                              path="/dealer-policy"
                              element={<DealerPolicy />}
                        />
                        <Route
                              path="/warranty-policy"
                              element={<WarrentyPolicy />}
                        />

                        {/* Catalogue page */}
                        <Route path="/catalogue" element={<Catalogue />} />
                        <Route
                              path="/catalogues/:catalogueId"
                              element={<CatalogueDetail />}
                        />

                        {/* Download Page */}
                        <Route path="/downloads" element={<Downloads />} />

                        {/* dealer page */}
                        <Route path="/dealer" element={<Dealers />} />

                        {/* visualizer page */}
                        <Route path="/visualizer" element={<Visualizer />} />

                        {/* career page  */}
                        <Route path="/careers" element={<Career />} />
                        <Route
                              path="/careers/:careerId"
                              element={<CareerDetail />}
                        />

                        {/* inspiration page */}
                        <Route
                              exact
                              path="/inspiration"
                              element={<Inspiration />}
                        />
                  </Routes>
            </>
      );
};

export default PublicRouter;
