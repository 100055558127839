import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { BreadcrumbContainer } from './styles';
import { Link } from 'react-router-dom';

const BreadcrumbBox = ({ title, link, linkTitle, children, background }) => {
      return (
            <>
                  <BreadcrumbContainer backgroundShow={background}>
                        <Container>
                              <Row>
                                    <div className="col-lg-12">
                                          <div className="ltn__breadcrumb-inner">
                                                <h1 className="page-title text-end text-white">
                                                      {title}
                                                </h1>
                                                <div className="ltn__breadcrumb-list d-flex justify-content-end">
                                                      <ol className="breadcrumb">
                                                            <li className="breadcrumb-item">
                                                                  <i className="bx bx-home"></i>{' '}
                                                                  <Link to="/">
                                                                        Home
                                                                  </Link>
                                                            </li>
                                                            {children &&
                                                                  children}
                                                            {linkTitle && (
                                                                  <li className="breadcrumb-item">
                                                                        <Link
                                                                              to={
                                                                                    link
                                                                              }
                                                                        >
                                                                              {
                                                                                    linkTitle
                                                                              }
                                                                        </Link>
                                                                  </li>
                                                            )}
                                                            <li
                                                                  className="breadcrumb-item active"
                                                                  aria-current="page"
                                                            >
                                                                  {title}
                                                            </li>
                                                      </ol>
                                                </div>
                                          </div>
                                    </div>
                              </Row>
                        </Container>
                  </BreadcrumbContainer>
            </>
      );
};

export default BreadcrumbBox;
