import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const WidgetCategoryItem = styled(Link)`
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      color: #222;
      margin-bottom: 10px;
      transition: all 0.3s ease-in;

      &:hover {
            color: ${(props) => props.theme.paint};
            margin-left: 10px;
      }

      &:last-child {
            margin-bottom: 0;
      }
`;

export const WidgetCategoryCount = styled.div``;
