import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Row, Toast } from 'react-bootstrap';
import { AchievementContainer } from './styles';
import AchievementItemBox from './components/AchievementItemBox';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const Achievement = () => {
      const [achievements, setAchievements] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/achievements`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setAchievements(response.data.achievements);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('our-achievements');

      return (
            <>
                  <BreadcrumbBox
                        title="Our Achievements"
                        background="product"
                  />
                  <AchievementContainer className="my-5">
                        <Row className="g-5">
                              {!loading &&
                                    achievements?.map((achievement, index) => (
                                          <Col lg={3} key={index}>
                                                <AchievementItemBox
                                                      img={achievement.image}
                                                      title={achievement.title}
                                                />
                                          </Col>
                                    ))}
                        </Row>
                  </AchievementContainer>
            </>
      );
};

export default Achievement;
