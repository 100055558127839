import React, { useEffect, useState } from 'react';
import { CareerDetailContainer } from '../styles';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import { products } from '../../../data/productList';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { EnquiryModal } from '../../../components/common/EnquiryBox/style';
import CareerForm from '../CareerForm';
import { Helmet } from 'react-helmet';

const CareerDetail = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const { careerId } = useParams();
      const [loading, setLoading] = useState(false);
      const [career, setCareer] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareer(response.data.job);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>
                              {career.seo_title
                                    ? career.seo_title
                                    : career.title}
                        </title>
                        <link rel="canonical" href={window.location.href} />
                        {career.seo_description && (
                              <meta
                                    name="description"
                                    content={career.seo_description}
                              />
                        )}
                        {career.seo_keyword && (
                              <meta
                                    name="keyword"
                                    content={career.seo_keyword}
                              />
                        )}
                  </Helmet>
                  <BreadcrumbBox
                        link="/careers"
                        linkTitle="Careers"
                        title={career.title}
                        background="product"
                  />
                  <CareerDetailContainer>
                        <section className="career__content-section my-5">
                              <Container>
                                    <Row className="justify-content-center">
                                          <Col lg={8}>
                                                <div className="career__inner">
                                                      <div className="career-body__inner">
                                                            <Table borderless>
                                                                  <thead className="">
                                                                        <tr>
                                                                              <th colspan="3">
                                                                                    <strong>
                                                                                          Basic
                                                                                          Information
                                                                                    </strong>
                                                                              </th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    Job
                                                                                    Title
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.title
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    Job
                                                                                    Type
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.type
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    No
                                                                                    of
                                                                                    Vacancy
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.vacancy
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    Salary
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.salary
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    Education
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.education
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    Experience
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.experience
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td className="w-3">
                                                                                    Deadline
                                                                              </td>
                                                                              <td>
                                                                                    :
                                                                              </td>
                                                                              <td className="w-6">
                                                                                    {
                                                                                          career.deadline
                                                                                    }
                                                                              </td>
                                                                        </tr>
                                                                        <thead>
                                                                              <tr>
                                                                                    <th colspan="3">
                                                                                          <strong>
                                                                                                Other
                                                                                                Details
                                                                                          </strong>
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tr>
                                                                              <td colspan="3">
                                                                                    <div
                                                                                          dangerouslySetInnerHTML={{
                                                                                                __html: career.description
                                                                                          }}
                                                                                    ></div>
                                                                              </td>
                                                                        </tr>
                                                                  </tbody>
                                                            </Table>
                                                            <Button
                                                                  variant="primary"
                                                                  onClick={
                                                                        handleShow
                                                                  }
                                                                  className="apply-btn text-white"
                                                            >
                                                                  Apply Now
                                                            </Button>

                                                            <CareerForm
                                                                  career={
                                                                        career
                                                                  }
                                                                  show={show}
                                                                  handleClose={
                                                                        handleClose
                                                                  }
                                                                  careerId={
                                                                        careerId
                                                                  }
                                                            />
                                                      </div>
                                                </div>
                                          </Col>
                                    </Row>
                              </Container>
                        </section>
                  </CareerDetailContainer>
            </>
      );
};

export default CareerDetail;
