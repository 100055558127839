import React, { useState } from 'react';
import { FaqFormContainer, FaqFormTitle } from './styles';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import ContactFormItem from '../../../contact/ContactFormItem';
import UserIcon from '../../../../components/icons/UserIcon';
import MailIcon from '../../../../components/icons/MailIcon';
import TelephoneIcon from '../../../../components/icons/TelephoneIcon';
import PenIcon from '../../../../components/icons/PenIcon';
import $ from 'jquery';

const FaqForm = () => {
      const [loading, setLoading] = useState(false);
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [message, setMessage] = useState('');

      const handleSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();
            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  message: message
            };
            await axios
                  .post(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`, data)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  });
            setLoading(false);
      };

      return (
            <>
                  <FaqFormContainer>
                        <FaqFormTitle className="text-center mb-4">
                              Ask Us Your Questions ?
                        </FaqFormTitle>
                        <Form onSubmit={handleSubmit}>
                              <Row className="g-4">
                                    <ContactFormItem
                                          title="Your Name"
                                          icon={<UserIcon />}
                                          classes={12}
                                          handleChange={setName}
                                    />
                                    <ContactFormItem
                                          title="Your Email Address"
                                          icon={<MailIcon />}
                                          classes={12}
                                          handleChange={setEmail}
                                    />
                                    <ContactFormItem
                                          title="Your Phone Number"
                                          icon={<TelephoneIcon />}
                                          classes={12}
                                          handleChange={setPhoneNumber}
                                    />
                                    <ContactFormItem
                                          title="Your Questions"
                                          icon={<PenIcon />}
                                          type="textarea"
                                          classes={12}
                                          handleChange={setMessage}
                                    />
                                    <Col lg={12}>
                                          <Button
                                                type="submit"
                                                className="w-100"
                                          >
                                                Send Your Inquiry
                                                {loading && <Spinner />}
                                          </Button>
                                    </Col>
                              </Row>
                        </Form>
                  </FaqFormContainer>
            </>
      );
};

export default FaqForm;
