import React, { useContext } from 'react';
// import { Breadcrumb } from 'react-bootstrap';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { ColorImage, ColorShadeContainer } from './styles';
import ColorShadeTab from './components/ColorShadeTab';
import MetaContext from '../../stores/MetaContext';

const ColorShade = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('explore-colors');

      return (
            <>
                  <BreadcrumbBox title="Explore Colors" background="product" />
                  <ColorShadeContainer>
                        <ColorImage>
                              <img
                                    src="/images/color-shade.jpg"
                                    className="w-100"
                                    alt="Explore Colors - Yeti Paints Nepal Pvt. Ltd."
                                    width="100%"
                                    height="100%"
                              />
                        </ColorImage>
                        <ColorShadeTab />
                  </ColorShadeContainer>
            </>
      );
};

export default ColorShade;
