import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'AcuminPro-ExtraLight';
  src: url('/fonts/AcuminPro-ExtraLight.ttf') format('truetype');
  font-family: 'Acumin-Variable-Concept';
  src: url('/fonts/Acumin-Variable-Concept.ttf') format('truetype');
} 
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family:'AcuminPro-ExtraLight';
    font-family: 'Brygada 1918', serif;
    font-family:'Acumin-Variable-Concept'; 
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  --italicFont: 'Inria Serif', serif;;
}
.bottom{
  margin-bottom: -50px;
}
`;
