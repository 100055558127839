import React from "react";
import {
      ProductAdditionalContainer,
      ProductAdditionalContent,
      ProductAdditionalDesc,
      ProductAdditionalImage,
      ProductAdditionalItem,
      ProductAdditionalTitle,
      ProductAdditionalWrapper,
} from "./styles";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";

const ProductAdditional = ({ product }) => {
      return (
            <>
                  <ProductAdditionalContainer>
                        <Container>
                              <Tabs
                                    defaultActiveKey="how-to-apply"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                              >
                                    <Tab
                                          eventKey="how-to-apply"
                                          title="Directions For Use"
                                    >
                                          <Row className="g-5 pt-4">
                                                <Col lg={6}>
                                                      <ProductAdditionalContent>
                                                            <ProductAdditionalImage>
                                                                  <img
                                                                        src="/images/img/products/cleaning.png"
                                                                        alt="Yeti paints Cleaning"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </ProductAdditionalImage>
                                                            <ProductAdditionalWrapper>
                                                                  <ProductAdditionalTitle>
                                                                        <h3>
                                                                              CLEANING
                                                                        </h3>
                                                                  </ProductAdditionalTitle>
                                                                  <ProductAdditionalDesc>
                                                                        Ensure
                                                                        that the
                                                                        surface
                                                                        to be
                                                                        painted
                                                                        is free
                                                                        from any
                                                                        loose
                                                                        particles,
                                                                        ev sand,
                                                                        paint,
                                                                        dust &
                                                                        grease.
                                                                        Any
                                                                        previous
                                                                        growth
                                                                        of
                                                                        fangus,
                                                                        algal &
                                                                        moss
                                                                        needs to
                                                                        be
                                                                        removed
                                                                        thoroughly
                                                                        by
                                                                        vigorous
                                                                        wire
                                                                        brush.
                                                                        Then it
                                                                        is re
                                                                        quired
                                                                        to be
                                                                        cleaned
                                                                        with tap
                                                                        water.
                                                                  </ProductAdditionalDesc>
                                                            </ProductAdditionalWrapper>
                                                      </ProductAdditionalContent>
                                                </Col>
                                                <Col lg={6}>
                                                      <ProductAdditionalContent>
                                                            <ProductAdditionalImage>
                                                                  <img
                                                                        src="/images/products/filling.jpg"
                                                                        alt="Yeti Paints Filling"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </ProductAdditionalImage>
                                                            <ProductAdditionalWrapper>
                                                                  <ProductAdditionalTitle>
                                                                        <h4>
                                                                              FILLING
                                                                        </h4>
                                                                  </ProductAdditionalTitle>
                                                                  <ProductAdditionalDesc>
                                                                        Surface
                                                                        imperfection
                                                                        like
                                                                        holes,
                                                                        dents
                                                                        and fine
                                                                        cracks
                                                                        are to
                                                                        be
                                                                        filled
                                                                        with
                                                                        Yeti
                                                                        Paints
                                                                        wall
                                                                        putty or
                                                                        a
                                                                        mixture.
                                                                  </ProductAdditionalDesc>
                                                            </ProductAdditionalWrapper>
                                                      </ProductAdditionalContent>
                                                </Col>
                                                <Col lg={6}>
                                                      <ProductAdditionalContent>
                                                            <ProductAdditionalImage>
                                                                  <img
                                                                        src="/images/products/primer.png"
                                                                        alt="Yeti Paints Primer"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </ProductAdditionalImage>
                                                            <ProductAdditionalWrapper>
                                                                  <ProductAdditionalTitle>
                                                                        <h4>
                                                                              PRIMER
                                                                        </h4>
                                                                  </ProductAdditionalTitle>
                                                                  <ProductAdditionalDesc>
                                                                        Before
                                                                        application
                                                                        of the
                                                                        topcoat,
                                                                        it is
                                                                        necessary
                                                                        le apply
                                                                        a coat
                                                                        of Yeti
                                                                        paints
                                                                        Exterior
                                                                        Wall
                                                                        Primer.
                                                                        The
                                                                        primer
                                                                        should
                                                                        be mixed
                                                                        with tap
                                                                        water at
                                                                        the
                                                                        ratio of
                                                                        2:1.Allow
                                                                        the
                                                                        exterior
                                                                        primer
                                                                        coat to
                                                                        dry for
                                                                        4-6
                                                                        hours.
                                                                  </ProductAdditionalDesc>
                                                            </ProductAdditionalWrapper>
                                                      </ProductAdditionalContent>
                                                </Col>
                                                <Col lg={6}>
                                                      <ProductAdditionalContent>
                                                            <ProductAdditionalImage>
                                                                  <img
                                                                        src="/images/products/top-coat.jpg"
                                                                        alt="Yeti Paints Top Coat"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </ProductAdditionalImage>
                                                            <ProductAdditionalWrapper>
                                                                  <ProductAdditionalTitle>
                                                                        <h4>
                                                                              TOP
                                                                              COAT
                                                                        </h4>
                                                                  </ProductAdditionalTitle>
                                                                  <ProductAdditionalDesc>
                                                                        Apply
                                                                        2-3
                                                                        coats by
                                                                        diluting
                                                                        1 Liter
                                                                        of Yeti
                                                                        Paints
                                                                        exterior
                                                                        paints
                                                                        with
                                                                        maximum
                                                                        400 ml
                                                                        of tap
                                                                        water.
                                                                        Keep an
                                                                        interval
                                                                        of 4-6
                                                                        hr
                                                                        between
                                                                        the two
                                                                        coats.
                                                                        Ensure
                                                                        that the
                                                                        horizontal
                                                                        surface
                                                                        should
                                                                        be
                                                                        painted
                                                                        with an
                                                                        additional
                                                                        third
                                                                        coat for
                                                                        better
                                                                        performance.
                                                                        If the
                                                                        paint
                                                                        being
                                                                        used is
                                                                        of dark
                                                                        shade
                                                                        applying
                                                                        three
                                                                        coats is
                                                                        recommended.
                                                                  </ProductAdditionalDesc>
                                                            </ProductAdditionalWrapper>
                                                      </ProductAdditionalContent>
                                                </Col>
                                                <Col lg={6}>
                                                      <ProductAdditionalContent>
                                                            <ProductAdditionalImage>
                                                                  <img
                                                                        src="/images/products/maintainance.jpg"
                                                                        alt="Yeti Paints Maintanance"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </ProductAdditionalImage>
                                                            <ProductAdditionalWrapper>
                                                                  <ProductAdditionalTitle>
                                                                        <h4>
                                                                              MAINTANANCE
                                                                        </h4>
                                                                  </ProductAdditionalTitle>
                                                                  <ProductAdditionalDesc>
                                                                        Painted
                                                                        surface
                                                                        should
                                                                        be
                                                                        properly
                                                                        washed &
                                                                        cleaned
                                                                        of all
                                                                        algal
                                                                        and
                                                                        fungus
                                                                        growth
                                                                        at a
                                                                        regular
                                                                        interval
                                                                        of six
                                                                        months.
                                                                  </ProductAdditionalDesc>
                                                            </ProductAdditionalWrapper>
                                                      </ProductAdditionalContent>
                                                </Col>
                                          </Row>
                                    </Tab>
                                    <Tab
                                          eventKey="additional-information"
                                          title="Additional Information"
                                    >
                                          <ProductAdditionalContainer>
                                                <Row className="d-flex list-inline">
                                                      <ProductAdditionalItem className="border-end">
                                                            <h3>
                                                                  Area of
                                                                  application
                                                            </h3>
                                                            <p>
                                                                  {
                                                                        product?.application
                                                                  }
                                                            </p>
                                                      </ProductAdditionalItem>
                                                      <ProductAdditionalItem className="border-end">
                                                            <h3>
                                                                  Covering
                                                                  capacity
                                                            </h3>
                                                            <p>
                                                                  {
                                                                        product?.covering_capacity
                                                                  }
                                                            </p>
                                                      </ProductAdditionalItem>
                                                      <ProductAdditionalItem className="border-end">
                                                            <h3>surface dry</h3>
                                                            <p>
                                                                  {
                                                                        product?.surface_dry
                                                                  }
                                                            </p>
                                                      </ProductAdditionalItem>
                                                      <ProductAdditionalItem className="border-end">
                                                            <h3>Hard Dry</h3>
                                                            <p>
                                                                  {
                                                                        product?.hard_dry
                                                                  }
                                                            </p>
                                                      </ProductAdditionalItem>
                                                      <ProductAdditionalItem>
                                                            <h3>
                                                                  Recoating Time
                                                            </h3>
                                                            <p>
                                                                  {
                                                                        product?.coating_time
                                                                  }
                                                            </p>
                                                      </ProductAdditionalItem>
                                                </Row>
                                          </ProductAdditionalContainer>
                                    </Tab>
                              </Tabs>
                        </Container>
                  </ProductAdditionalContainer>
            </>
      );
};

export default ProductAdditional;
