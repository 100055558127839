import React, { useContext, useEffect, useState } from 'react';
import {
      InspirationContainer,
      InspirationItem,
      InspirationTab,
      InspirationTabNav
} from './styles';
import Isotope from 'isotope-layout';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Row } from 'react-bootstrap';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const Inspiration = () => {
      const [filterKey, setFilterKey] = useState('*');
      const [loading, setLoading] = useState(false);
      const [inspirations, setInspirations] = useState([]);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  try {
                        const response = await axios.get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/inspirations`
                        );
                        if (response.data.result === 'success') {
                              setInspirations(response.data.categories);
                        }
                  } catch (error) {
                        console.error(error.message);
                  }
                  setLoading(false);
            };

            fetchData();
      }, []);

      useEffect(() => {
            const iso = new Isotope('.filter-container', {
                  itemSelector: '.filter-item',
                  layoutMode: 'fitRows'
            });

            return () => {
                  iso.destroy();
            };
      }, []);

      useEffect(() => {
            const iso = new Isotope('.filter-container', {
                  itemSelector: '.filter-item',
                  layoutMode: 'fitRows'
            });

            filterKey === '*'
                  ? iso.arrange({ filter: `*` })
                  : iso.arrange({ filter: `.${filterKey}` });

            return () => {
                  iso.destroy();
            };
      }, [filterKey]);

      const handleFilterKeyChange = (key) => () => setFilterKey(key);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('inspiration');

      return (
            <>
                  <BreadcrumbBox title="Inspiration" />
                  {!loading && (
                        <InspirationContainer>
                              <InspirationTab className="d-flex list-inline gap-3 mb-4">
                                    <InspirationTabNav
                                          onClick={handleFilterKeyChange('*')}
                                    >
                                          All Inspiration
                                    </InspirationTabNav>
                                    {!loading &&
                                          inspirations?.map(
                                                (inspiration, index) => (
                                                      <InspirationTabNav
                                                            onClick={handleFilterKeyChange(
                                                                  `${inspiration.index}`
                                                            )}
                                                            key={index}
                                                      >
                                                            {inspiration.title}
                                                      </InspirationTabNav>
                                                )
                                          )}
                              </InspirationTab>

                              <InspirationTab className="filter-container ">
                                    <Row className="g-3">
                                          {!loading &&
                                                inspirations?.map(
                                                      (inspiration, index) =>
                                                            inspiration?.items
                                                                  ?.length >
                                                                  0 &&
                                                            inspiration?.items?.map(
                                                                  (
                                                                        item,
                                                                        key
                                                                  ) => (
                                                                        <InspirationItem
                                                                              className={`filter-item ${inspiration.index} col-lg-3`}
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <img
                                                                                    src={
                                                                                          item.image
                                                                                    }
                                                                                    alt={
                                                                                          item.title
                                                                                    }
                                                                                    key={
                                                                                          key
                                                                                    }
                                                                                    className="img-fluid mb-4"
                                                                                    width="100%"
                                                                                    height="100%"
                                                                              />
                                                                        </InspirationItem>
                                                                  )
                                                            )
                                                )}
                                    </Row>
                              </InspirationTab>
                        </InspirationContainer>
                  )}
            </>
      );
};

export default Inspiration;
