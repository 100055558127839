import React from 'react';

const UserIcon = () => {
   return (
      <>
         <svg
            version="1.1"
            id="fi_166277"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 404.347 404.347"
            style={{ enableBackground:"new 0 0 404.347 404.347" }}
            xmlSpace="preserve"
         >
            <g>
               <g>
                  <g>
                     <path
                        d="M202.178,209.972c23.96,0,47.357-14.538,65.881-40.936c16.348-23.296,26.107-52.099,26.107-77.048
				C294.167,41.266,252.9,0,202.178,0c-50.722,0-91.988,41.266-91.988,91.988c0,24.949,9.759,53.752,26.107,77.048
				C154.821,195.434,178.218,209.972,202.178,209.972z M202.178,15c42.451,0,76.988,34.537,76.988,76.988
				c0,21.655-8.961,47.876-23.385,68.432c-15.409,21.958-34.946,34.552-53.603,34.552c-18.657,0-38.194-12.594-53.603-34.552
				c-14.424-20.556-23.385-46.777-23.385-68.432C125.19,49.537,159.727,15,202.178,15z"
                     ></path>
                     <path
                        d="M336.974,251.115c-16.208-25.154-39.025-45.233-65.987-58.068c-2.864-1.363-6.274-0.778-8.52,1.461
				c-18.071,18.021-38.92,27.545-60.293,27.545c-21.376,0-42.222-9.524-60.282-27.544c-2.246-2.241-5.658-2.827-8.521-1.463
				c-26.962,12.835-49.782,32.915-65.992,58.068c-16.651,25.837-25.452,55.813-25.452,86.686v0.13c0,2.116,0.894,4.134,2.461,5.556
				c43.276,39.247,99.312,60.861,157.785,60.861s114.508-21.614,157.785-60.861c1.567-1.422,2.461-3.439,2.461-5.556v-0.13
				C362.421,306.927,353.622,276.951,336.974,251.115z M202.174,389.348c-53.621,0-105.055-19.417-145.212-54.743
				c0.583-26.855,8.514-52.847,23.026-75.365c13.767-21.361,32.788-38.686,55.227-50.357c20.045,18.453,43.094,28.17,66.959,28.17
				c23.861,0,46.914-9.719,66.969-28.171c22.437,11.67,41.458,28.996,55.222,50.357c14.509,22.517,22.438,48.508,23.021,75.365
				C307.229,369.93,255.795,389.348,202.174,389.348z"
                     ></path>
                  </g>
               </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
         </svg>
      </>
   );
};

export default UserIcon;
