import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const BannerSlider = styled(Swiper)`
      height: 512px;
      margin-bottom: 3rem;

      @media screen and (max-width: 568px) {
            height: auto;
            margin-bottom: 2rem;

            img {
                  object-fit: contain;
                  width: 100%;
            }
      }
`;
