import { Offcanvas } from 'react-bootstrap';
import styled from 'styled-components';

export const MenuButton = styled.div`
      font-size: 24px;
      cursor: pointer;
      display: none;

      @media screen and (max-width: 568px) {
            display: block;
      }
`;

export const MenuContent = styled(Offcanvas)`
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top: 5px solid ${(props) => props.theme.primary};

      .offcanvas-header {
            img {
                  height: 50px;
                  object-fit: contain;
            }
      }

      .offcanvas-body {
            padding: 0;
      }
`;

export const MobileMenuContent = styled.ul`
      padding: 0;

      li {
            border-bottom: 1px solid #e4e4e4;

            &:first-child {
                  border-top: 1px solid #e4e4e4;
            }
            a {
                  line-height: 1;
                  display: block;
                  padding: 15px 20px;
                  color: #666666;
                  text-decoration: none;
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
            }
      }
`;
