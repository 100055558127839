import React, { useState } from 'react';
import { SubscriptionForm, SubscriptionSection } from '../styles';
import { Button, Form, Spinner } from 'react-bootstrap';
import { WarrentyClaim, WarrentyClaimContent } from '../../warrenty/styles';
import axios from 'axios';
import toast from 'react-hot-toast';
import $ from 'jquery';

const SubcriptionBox = () => {
      const [loading, setLoading] = useState(false);

      const [email, setEmail] = useState('');

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  email: email
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/subscribe`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <SubscriptionSection>
                        <WarrentyClaim>
                              <img
                                    src="/images/img/products/imgback.png"
                                    alt="Subscribe to our Newsletter - Yeti Paints Pvt. Ltd."
                                    className="w-100"
                                    width="100%"
                                    height="100%"
                              />

                              <WarrentyClaimContent>
                                    <h3 className="mb-3">
                                          Subscribe to our Newsletter
                                          <br />
                                    </h3>

                                    <SubscriptionForm>
                                          <Form onSubmit={handleSubmit}>
                                                <Form.Group
                                                      className=" d-flex align-item-center justify-content-between form__group"
                                                      controlId="exampleForm.ControlInput1"
                                                >
                                                      <Form.Control
                                                            type="email"
                                                            placeholder="Email Address"
                                                            onChange={(event) =>
                                                                  setEmail(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                      <Button type="submit">
                                                            <div>Subscribe</div>
                                                            {loading && (
                                                                  <Spinner />
                                                            )}
                                                      </Button>
                                                </Form.Group>
                                          </Form>
                                    </SubscriptionForm>
                              </WarrentyClaimContent>
                        </WarrentyClaim>
                  </SubscriptionSection>
            </>
      );
};

export default SubcriptionBox;
