import styled from "styled-components";
import { AboutButton } from "../../../frontend/home/styles";

export const PolicyHeaderContainer = styled.div``;
export const PolicyHeaderLink = styled.div`
      ${AboutButton} {
            width: 100%;
      }

      @media screen and (max-width: 578px) {
            min-width: fit-content;
      }
`;

export const PolicyHeaderContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      flex-wrap: wrap;

      @media screen and (max-width: 578px) {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: start;
            justify-content: start;
            padding: 10px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                  width: 0;
            }
      }
`;
