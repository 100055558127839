import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItemContainer } from '../../../layout/header/styles';

const MenuItem = ({ title, link, children }) => {
   return (
      <>
         <MenuItemContainer className="list-inline-item px-3  ">
            <Link
               to={link ? link : '#'}
               className={'d-flex align-items-center text-decoration-none  '}
               data-title={title}
            >
               <span>{title}</span>
            </Link>
            {children}
         </MenuItemContainer>
      </>
   );
};

export default MenuItem;
