import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const DealersContainer = styled(Container)`
   margin-bottom: 100px;
`;
export const DealerHeading = styled.h2`
   font-size: 24px;
   color: ${(props) => props.theme.paint};
`;
export const DealerDesc = styled.p`
   text-align: justify;
`;
