import React, { useContext, useEffect, useState } from 'react';
import { CatalogueContainer } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Link } from 'react-router-dom';
import {
      DownloadsContent,
      DownloadsImage,
      DownloadsItem
} from '../download/styles';
import DownloadIcon from '../../components/icons/DownloadIcon';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const Catalogue = () => {
      const [loading, setLoading] = useState(false);
      const [catalogues, setCatalogues] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/catalogues`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCatalogues(response.data.catalogues);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('catalogue');

      return (
            <>
                  <BreadcrumbBox title="Catalogue" background="product" />
                  <CatalogueContainer>
                        <Container>
                              <Row className="g-4">
                                    {!loading
                                          ? catalogues?.length > 0
                                                ? catalogues?.map(
                                                        (catalogue, index) => (
                                                              <Col
                                                                    lg={6}
                                                                    key={index}
                                                              >
                                                                    <DownloadsItem>
                                                                          <DownloadsImage>
                                                                                <img
                                                                                      src={
                                                                                            catalogue.image
                                                                                      }
                                                                                      alt={
                                                                                            catalogue.title
                                                                                      }
                                                                                      className="img-fluid"
                                                                                      width="100%"
                                                                                      height="100%"
                                                                                />
                                                                          </DownloadsImage>
                                                                          <DownloadsContent>
                                                                                <Link
                                                                                      to={`/catalogues/${catalogue.slug}`}
                                                                                      className="text-decoration-none d-flex align-items-center gap-3"
                                                                                >
                                                                                      {
                                                                                            catalogue.title
                                                                                      }
                                                                                </Link>
                                                                                <Link
                                                                                      to={
                                                                                            catalogue.file
                                                                                      }
                                                                                      download={
                                                                                            true
                                                                                      }
                                                                                >
                                                                                      <DownloadIcon />
                                                                                      <span>Download</span>
                                                                                </Link>
                                                                          </DownloadsContent>
                                                                    </DownloadsItem>
                                                              </Col>
                                                        )
                                                  )
                                                : null
                                          : null}
                              </Row>
                        </Container>
                  </CatalogueContainer>
            </>
      );
};

export default Catalogue;
