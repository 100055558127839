import styled from "styled-components";

export const ProductCategoryContainer = styled.div`
      margin: 70px 0px 150px;
      .container {
            padding: 0px 20px;
      }
      .row {
            margin-bottom: 100px;
      }
      .col-lg-5 {
            padding: 0px 20px 0px 60px;
      }

      @media screen and (max-width: 578px) {
            margin-top: 3rem;
            .col-lg-5 {
                  padding: 0 20px;
            }
      }
`;
export const LeftSidebarContainer = styled.div`
      margin-bottom: 100px;
      .col-lg-5 {
            padding: 0px 60px 0px 20px;
      }
      .row {
            margin-bottom: 0px;
      }

      @media screen and (max-width: 578px) {
            .col-lg-5 {
                  order: 1;
                  padding: 0 20px;
            }

            .col-lg-7 {
                  order: 2;
            }
      }
`;
export const ProductCategoryImage = styled.div`
      position: relative;
      transform: translateY(72px);
      margin-right: ${(props) => (props.left ? "0px" : "-100px")};
      margin-left: ${(props) => (props.left ? "-100px" : "0px")};

      @media screen and (max-width: 578px) {
            transform: translateY(0);
            margin-right: 0;
            margin-left: 0;
      }
`;
export const ProductCategoryContent = styled.div`
      padding: ${(props) =>
            props.left ? "61px 130px 61px 0px" : "61px 0px 61px 130px"};

      border-top-left-radius: ${(props) => (props.left ? "0" : "50px")};
      border-top-right-radius: ${(props) => (props.left ? "50px" : "0px")};
      background: #f3f3f3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: ${(props) => (props.left ? "flex-end" : "flex-start")};
      height: 100%;
      h3 {
            font-size: 28px;
            color: ${(props) => props.theme.paint};
            margin-bottom: 1.5rem;
      }
      p {
            width: 75%;
            font-size: 15px;
            color: #666;
            margin-bottom: 2rem;
            text-justify: end;
      }
      span {
            font-size: 60px;
            color: #d3d3d3;
            opacity: 0.8;
      }
      a {
            span {
                  font-size: 24px;
                  color: ${(props) => props.theme.white};
            }
      }

      @media screen and (max-width: 578px) {
            padding: 20px;
            padding-bottom: 40px;
            margin-top: -10px;

            p {
                  width: 100%;
                  margin-bottom: 10px;
            }
      }
`;
