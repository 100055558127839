import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import {
      CategoryProductContainer,
      CategoryProductImage
} from '../../../frontend/home/styles';
import { Link } from 'react-router-dom';

export default function ProductCategorySlider({ categories }) {
      return (
            <>
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                              clickable: true
                        }}
                        breakpoints={{
                              640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40
                              },
                              1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 20
                              }
                        }}
                        className="mySwiper"
                  >
                        {categories?.length > 0 &&
                              categories.map((category, index) => (
                                    <SwiperSlide key={index}>
                                          <CategoryProductContainer>
                                                <Link to={`/${category.slug}`}>
                                                      <CategoryProductImage>
                                                            <img
                                                                  src={
                                                                        category.image
                                                                  }
                                                                  alt={
                                                                        category.title
                                                                  }
                                                                  className="img-fluid"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </CategoryProductImage>
                                                </Link>
                                                <Link className="text-decoration-none">
                                                      <h4>{category.title}</h4>
                                                </Link>
                                          </CategoryProductContainer>
                                    </SwiperSlide>
                              ))}
                  </Swiper>
            </>
      );
}
