import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { FaqContainer, FaqHeading } from './styles';
import FaqAccordion from './components/FaqAccordion';
import FaqForm from './components/FaqForm';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const FaqPage = () => {
      const [loading, setLoading] = useState(false);
      const [faqs, setFaqs] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.faqs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('faqs');

      return (
            <>
                  <BreadcrumbBox title="Faqs" />
                  <FaqContainer>
                        <FaqHeading className="w-100 text-center mb-5">
                              <h2>Frequently Asked Questions</h2>
                        </FaqHeading>
                        <Container>
                              <Row className="g-5">
                                    <Col lg={8}>
                                          <FaqAccordion
                                                loading={loading}
                                                faqs={faqs}
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FaqForm />
                                    </Col>
                              </Row>
                        </Container>
                  </FaqContainer>
            </>
      );
};

export default FaqPage;
