import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { CareerContainer } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import MetaContext from '../../stores/MetaContext';

const Career = () => {
      const [loading, setLoading] = useState(false);
      const [careers, setCareers] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/careers`, {
                        headers: {
                              Authorization: `Bearer YPboZc4SqOxxJmEIFpYxXveEltWByc4V0DczlswJRpGTTDFBFX09mUZRG7QzKLhV`
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareers(response.data.jobs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('careers');

      return (
            <>
                  <BreadcrumbBox
                        title="Career"
                        background="product"
                  ></BreadcrumbBox>
                  <CareerContainer>
                        <section className="career-section my-5">
                              <Container>
                                    <Row className="g-5 ">
                                          {!loading &&
                                                careers?.length > 0 &&
                                                careers?.map(
                                                      (career, index) => (
                                                            <Col
                                                                  lg={6}
                                                                  sm={6}
                                                                  key={index}
                                                            >
                                                                  <Link
                                                                        to={`/careers/${career.slug}`}
                                                                        className="career__item"
                                                                  >
                                                                        <div className="career__item-header">
                                                                              <div className="career__item-title">
                                                                                    <h2>
                                                                                          {
                                                                                                career.title
                                                                                          }
                                                                                    </h2>
                                                                                    <div className="career__item-date">
                                                                                          <div className="career__item-icon">
                                                                                                <i className="bx bx-time"></i>
                                                                                          </div>
                                                                                          <div className="career__item-time">
                                                                                                <time>
                                                                                                      {
                                                                                                            career.date
                                                                                                      }
                                                                                                </time>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                        <div className="career__item-body">
                                                                              <ul className="mb-0">
                                                                                    <li>
                                                                                          Salary:
                                                                                          {
                                                                                                career.salary
                                                                                          }
                                                                                    </li>
                                                                                    <li>
                                                                                          Job
                                                                                          Type:
                                                                                          {
                                                                                                career.type
                                                                                          }
                                                                                    </li>
                                                                                    <li>
                                                                                          Deadline:
                                                                                          {
                                                                                                career.deadline
                                                                                          }
                                                                                    </li>
                                                                              </ul>
                                                                        </div>
                                                                  </Link>
                                                            </Col>
                                                      )
                                                )}
                                    </Row>
                              </Container>
                        </section>
                  </CareerContainer>
            </>
      );
};

export default Career;
