import React, { useContext } from 'react';
import { DealerDesc, DealerHeading, DealersContainer } from './styles';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Row } from 'react-bootstrap';
import MetaContext from '../../stores/MetaContext';

const Dealers = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('dealer');

      return (
            <>
                  <BreadcrumbBox title="Our Dealers"></BreadcrumbBox>
                  <DealersContainer className="mt-5">
                        <Row>
                              <Col lg={6}>
                                    <DealerHeading>
                                          Our Wide Range of Dealer Network
                                    </DealerHeading>
                                    <DealerDesc>
                                          We have an extensive network of
                                          dealers spanning across every district
                                          in Nepal, with a significant presence
                                          in major cities as well. Our
                                          commitment to accessibility and
                                          convenience means that you can find
                                          our products and services within
                                          reach, no matter where you are. With a
                                          robust network of over 2500 dealers
                                          strategically positioned throughout
                                          the country, we ensure that you can
                                          easily connect with the nearest one.
                                          Contact the dealers around you to
                                          experience the excellence of our
                                          products and services firsthand. We
                                          believe in delivering quality and
                                          satisfaction, and our widespread
                                          presence reflects our dedication to
                                          serving you, our valued customers,
                                          with the utmost convenience.
                                    </DealerDesc>
                              </Col>
                              <Col lg={6}>
                                    <img
                                          src="/images/img/commingsoon/viber_image_2023-12-21_16-25-39-877.jpg"
                                          alt="yetipaints dealer "
                                          className="img-fluid"
                                          width="100%"
                                          height="100%"
                                    />
                              </Col>
                        </Row>
                  </DealersContainer>
            </>
      );
};

export default Dealers;
