import React, { useEffect, useState } from "react";
import {
      BottomHeader,
      EntryTool,
      HeaderContact,
      HeaderContainer,
      HeaderContent,
      HeaderInfo,
      HeaderLogo,
      HeaderNav,
      HeaderTool,
      HeaderWrapper,
      HiddenText,
      MenuContainer,
      TopHeader,
} from "./styles";
import { Container } from "react-bootstrap";
import MenuItem from "../../components/layout/header/MenuItem";
import { Link } from "react-router-dom";
import HeaderInfoItem from "./HeaderInfoItem";
import MenuIcon from "../../icons/MenuIcon";
import MobileMenu from "./MobileMenu";
import axios from "axios";
import { Helmet } from "react-helmet";

const Header = () => {
      const [expanded, setExpanded] = useState(false);

      const toggleHeight = () => {
            setExpanded(!expanded);
      };

      const [loading, setLoading] = useState(false);
      const [plugins, setPlugins] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/plugins`)
                  .then((response) => {
                        if (response.data.result === "success") {
                              setPlugins(response.data.plugins);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        {plugins?.map(
                              (plugin, index) =>
                                    plugin.tag_type === "Script" && (
                                          <script key={index}>
                                                {plugin.code}
                                          </script>
                                    )
                        )}
                        {plugins?.map(
                              (plugin, index) =>
                                    plugin.tag_type === "Meta" && (
                                          <mata
                                                name={plugin.title}
                                                content={plugin.code}
                                                key={index}
                                          />
                                    )
                        )}
                  </Helmet>
                  <HeaderContainer>
                        <TopHeader>
                              <HeaderWrapper
                                    className={`expandable-element ${
                                          expanded ? "expanded" : ""
                                    }`}
                              >
                                    <Container className="h-100">
                                          <HeaderNav>
                                                <MenuContainer>
                                                      <MenuItem
                                                            link="/"
                                                            title="Home"
                                                      />
                                                      <MenuItem
                                                            link="/about-us"
                                                            title="About"
                                                      ></MenuItem>
                                                      <MenuItem
                                                            link="products"
                                                            title="Products"
                                                      />
                                                      <MenuItem
                                                            title="Inspiration"
                                                            link="inspiration"
                                                      />
                                                      <MenuItem
                                                            title="Color Visualizer"
                                                            link="visualizer"
                                                      />
                                                      <MenuItem
                                                            link="/dealer"
                                                            title="Dealer"
                                                      />
                                                </MenuContainer>
                                          </HeaderNav>
                                    </Container>
                              </HeaderWrapper>
                        </TopHeader>
                        <BottomHeader>
                              <HeaderTool>
                                    <EntryTool>
                                          <MenuIcon />
                                          <Link
                                                to="#"
                                                className="bx bx-menu
                                text-decoration-none text-white"
                                                onClick={toggleHeight}
                                          >
                                                <HiddenText>
                                                      Yeti Paints Pvt. Ltd.
                                                      Official Page
                                                </HiddenText>
                                          </Link>
                                    </EntryTool>
                              </HeaderTool>
                              <Container fluid>
                                    <HeaderContent>
                                          <HeaderLogo>
                                                <Link to="/">
                                                      <img
                                                            src="/images/png/logo/white-logo.png"
                                                            alt="Yeti Paints Logo png"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </Link>
                                          </HeaderLogo>
                                          <HeaderInfo>
                                                <HeaderInfoItem
                                                      to="tel:977702649540"
                                                      icon="/images/svg/call_1034131.svg"
                                                      title="Call Us"
                                                      info="+977 9702649540"
                                                />
                                                <HeaderInfoItem
                                                      to="mailto:yetipaints@gmail.com"
                                                      icon="/images/svg/email_3894024.svg"
                                                      title="Send Us Email"
                                                      info="yetipaints@gmail.com"
                                                />
                                                <HeaderContact>
                                                      <img
                                                            src="/images/png/yeti-banner3.png"
                                                            alt="Yeti contact us image"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                      <Link
                                                            to="/contact-us"
                                                            className="text-decoration-none text-white"
                                                      >
                                                            Contact Us
                                                      </Link>
                                                </HeaderContact>
                                          </HeaderInfo>
                                          <MobileMenu />
                                    </HeaderContent>
                              </Container>
                        </BottomHeader>
                  </HeaderContainer>
            </>
      );
};

export default Header;
