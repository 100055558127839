import styled from "styled-components";

export const BranchItem = styled.div`
      background-color: #fff;
      padding: 20px;
      border: 1px solid #f5f5f5;
      border-radius: 10px;

      &:hover {
            border: 1px solid #d71a21;
            transition: 0.3s ease-in-out;
      }

      a {
            color: #212529;
            text-decoration: none;
      }
`;

export const BrachItemTitle = styled.h3`
      font-size: 18px;
      margin-bottom: 5px;
`;

export const BrachItemIcon = styled.div`
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 5px;
      padding-left: 10px;

      i {
            line-height: 1;
      }

      div {
            padding-left: 20px;
      }

      @media screen and (max-width: 578px) {
            padding-left: 0;
      }
`;
