import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      WarrentyContainer,
      WarrentyContent,
      WarrentyContentDesc,
      WarrentyContentTitle,
      WarrentySection
} from './styles';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const WarrentyPolicy = () => {
      const [loading, setLoading] = useState(false);
      const [policies, setPolicies] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/warranty_policies`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPolicies(response.data.policies);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('warranty-policy');

      return (
            <>
                  <BreadcrumbBox title="Warranty Policy" background="product" />
                  <WarrentySection>
                        <WarrentyContainer>
                              <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="0"
                              >
                                    <Row>
                                          <Col sm={3}>
                                                <Nav
                                                      variant="pills"
                                                      className="flex-column"
                                                >
                                                      {!loading &&
                                                            policies?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <Nav.Item
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Nav.Link
                                                                                    eventKey={`${index}`}
                                                                              >
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                              </Nav.Link>
                                                                        </Nav.Item>
                                                                  )
                                                            )}
                                                </Nav>
                                          </Col>
                                          <Col sm={9}>
                                                <Tab.Content>
                                                      {!loading &&
                                                            policies?.map(
                                                                  (
                                                                        policy,
                                                                        index
                                                                  ) => (
                                                                        <Tab.Pane
                                                                              eventKey={`${index}`}
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <WarrentyContent>
                                                                                    <WarrentyContentTitle>
                                                                                          <h2>
                                                                                                {
                                                                                                      policy.title
                                                                                                }
                                                                                          </h2>
                                                                                    </WarrentyContentTitle>
                                                                                    <WarrentyContentDesc>
                                                                                          <div
                                                                                                dangerouslySetInnerHTML={{
                                                                                                      __html: policy.description
                                                                                                }}
                                                                                          ></div>
                                                                                    </WarrentyContentDesc>
                                                                              </WarrentyContent>
                                                                        </Tab.Pane>
                                                                  )
                                                            )}
                                                </Tab.Content>
                                          </Col>
                                    </Row>
                              </Tab.Container>
                        </WarrentyContainer>
                  </WarrentySection>
            </>
      );
};

export default WarrentyPolicy;
