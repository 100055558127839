import React from 'react';
import { ProductCardContainer } from './styles';
import {  Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
      return (
            <>
                  <ProductCardContainer className="h-100">
                        <Card className="h-100 border-0">
                              <Link to={`/products/${product.slug}`}>
                                    <Card.Img
                                          variant="top"
                                          src={product.image}
                                          alt={product.title}
                                          width="100%"
                                          height="100%"
                                    />
                              </Link>
                              <Card.Body>
                                    <Link
                                          to={`/products/${product.slug}`}
                                          className="text-decoration-none"
                                    >
                                          <Card.Title>
                                                {product.title}
                                          </Card.Title>
                                    </Link>
                                    <Card.Text>{product.application}</Card.Text>
                              </Card.Body>
                        </Card>
                  </ProductCardContainer>
            </>
      );
};

export default ProductCard;
