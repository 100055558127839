import React from 'react';
import { Link } from 'react-router-dom';

import {
      BlogItem,
      BlogItemContent,
      BlogItemImage,
      BlogItemInfo,
      BlogItemTitle
} from './styles';

const BlogItemBox = ({ blog }) => {
      return (
            <>
                  <BlogItem>
                        <Link to={`/blogs/${blog.slug}`}>
                              <BlogItemImage>
                                    <img
                                          src={blog.image}
                                          alt={blog.title}
                                          class="img-fluid rounded-top-2"
                                          width="100%"
                                          height="100%"
                                    />
                              </BlogItemImage>
                              <BlogItemContent>
                                    <BlogItemTitle>
                                          <h2>{blog.title}</h2>
                                    </BlogItemTitle>
                                    <BlogItemInfo></BlogItemInfo>
                              </BlogItemContent>
                        </Link>
                  </BlogItem>
            </>
      );
};

export default BlogItemBox;
