import styled from "styled-components";

export const BlogItemImage = styled.div`
      overflow: hidden;
      border-radius: 15px;
      height: 280px;

      img {
            transition: transform 1.8s ease-in;
      }

      @media screen and (max-width: 578px) {
            height: auto;
      }
`;

export const BlogItem = styled.div`
      /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
      transition: transform 1s linear;
      height: 100%;
      overflow: hidden;

      a {
            text-decoration: none;
            display: block;
      }

      :hover > ${BlogItemImage} {
            img {
                  transform: scale(1.2);
                  transition: transform 1.8s ease-in;
            }
      }
`;

export const BlogItemContent = styled.div`
      padding: 0 10px;
`;

export const BlogItemDetail = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Nunito Sans", sans-serif;
      font-size: 14px;
      color: #152e3a;
      display: flex;
      align-items: center;
      gap: 1.5rem;
`;

export const BlogItemAuthor = styled.div`
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #e14d49;
      i {
            color: ${(props) => props.theme.secondary};
      }

      span {
            font-family: "Nunito Sans", sans-serif;
            display: flex;
            align-items: center;
            gap: 0.5rem;
      }
`;

export const BlogItemTitle = styled.div`
      margin-top: 10px;

      h2 {
            color: #222;
            font-size: 20px;
            font-family: "Rajdhani", sans-serif;

            &:hover {
                  color: ${(props) => props.theme.paint};
            }
      }
`;

export const BlogItemInfo = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;

      span {
            color: #152e3a;
      }
`;

export const BlogItemButton = styled.div`
      a {
            color: ${(props) => props.theme.secondary};
            font-family: 20px;
            font-weight: 500;
      }
`;
