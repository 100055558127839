import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
      padding: 50px 0;
      background-image: ${(props) =>
            props.backgroundShow === "product"
                  ? "url('/images/img/products/breadcrumb3.jpg')"
                  : "url('/images/img/products/image 6.png')"};
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: 0px;

      a {
            color: var(--bs-breadcrumb-item-active-color);
            text-decoration: none;

            &:hover {
                  color: ${(props) => props.theme.primary};
            }
      }
      li {
            color: var(--bs-breadcrumb-item-active-color);
      }
      h1 {
            display: flex;
            justify-content: flex-end;
            width: 70%;
            font-size: 30px;
      }
      .ltn__breadcrumb-inner {
            display: flex;
            flex-direction: column;
            align-items: end;
      }

      @media screen and (max-width: 578px) {
            h1 {
                  width: 100%;
                  font-size: 24px;
            }
      }
`;
