import React from 'react';

const QuestionIcon = () => {
      return (
            <>
                  <svg
                        id="fi_5592636"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <g id="interrogation_mark_1_">
                              <g>
                                    <path d="m256 512c-140.959 0-256-115.05-256-256 0-140.959 115.05-256 256-256 140.959 0 256 115.049 256 256 0 140.959-115.049 256-256 256zm0-482c-124.617 0-226 101.383-226 226s101.383 226 226 226 226-101.383 226-226-101.383-226-226-226z"></path>
                              </g>
                              <g>
                                    <path d="m256 331c-8.284 0-15-6.716-15-15v-16.054c0-26.691 14.365-51.581 37.489-64.958 13.885-8.031 22.511-22.971 22.511-38.988 0-24.813-20.187-45-45-45s-45 20.187-45 45c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-41.355 33.645-75 75-75s75 33.645 75 75c0 26.69-14.365 51.58-37.489 64.957-13.885 8.032-22.511 22.972-22.511 38.989v16.054c0 8.284-6.716 15-15 15z"></path>
                              </g>
                              <g>
                                    <circle cx="256" cy="376" r="15"></circle>
                              </g>
                        </g>
                  </svg>
            </>
      );
};

export default QuestionIcon;
