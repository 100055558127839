import React from 'react';
import { BrachItemIcon, BrachItemTitle, BranchItem } from './styles';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

const ContactBranchItem = ({ title, address, phone, email }) => {
      return (
            <>
                  <Col lg={4}>
                        <BranchItem>
                              <BrachItemTitle>{title}</BrachItemTitle>
                              <BrachItemIcon>
                                    <i className="bx bx-phone"></i>
                                    <div>
                                          <Link to={`tel:${phone}`}>
                                                {phone}
                                          </Link>
                                    </div>
                              </BrachItemIcon>

                              <BrachItemIcon>
                                    <i className="bx bx-map"></i>
                                    <div class="dealer__location">
                                          {address}
                                    </div>
                              </BrachItemIcon>
                              <BrachItemIcon>
                                    <i class="bx bx-envelope"></i>
                                    <div>
                                          <Link to={`mailto:${email}`}>
                                                {email}
                                          </Link>
                                    </div>
                              </BrachItemIcon>
                        </BranchItem>
                  </Col>
            </>
      );
};

export default ContactBranchItem;
