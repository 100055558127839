import styled from "styled-components";

export const DownloadsContainer = styled.div`
      padding: 50px 0px 100px 0px;
      .table thead th {
            background-color: #04293a;
            color: #fff;
      }
      td svg {
            fill: #212121;
      }
      .form-select:focus {
            border-color: #86b7fe;
            outline: 0;
            box-shadow: none;
      }
`;
export const DownloadsItem = styled.div`
      padding: 70px 40px;
      position: relative;

      @media screen and (max-width: 578px) {
            padding: 0;
            margin-bottom: 5rem;
      }
`;
export const DownloadsImage = styled.div`
      display: inline-block;
      box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 135px;
      top: 0px;
      left: 80px;
      height: auto;
      z-index: 10;

      img {
            width: 100%;
      }

      @media screen and (max-width: 578px) {
            left: 0;
            bottom: 0;
            top: auto;
      }
`;
export const DownloadsContent = styled.div`
      background-image: linear-gradient(to left, #fc840a 1%, #e44b07);
      width: 800px;
      background-image: linear-gradient(to left, #fc840a 1%, #e44b07);
      display: flex;
      position: relative;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px;
      padding: 25px 25px 20px 200px;
      color: #fff;
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
            color: ${(props) => props.theme.white};
            &:hover {
                  color: ${(props) => props.theme.paint};
            }

            span {
                  display: none;
            }
      }
      svg {
            width: 20px;
            height: 18px;
      }

      @media screen and (max-width: 578px) {
            padding-left: 150px;
            flex-direction: column;

            a {
                  text-decoration: none;
                  display: flex;
                  align-items: center;
                  margin-top: 5px;

                  span {
                        padding-left: 10px;
                        display: inline-block;
                  }
            }
      }
`;

export const SearchButton = styled.button`
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 0;
      padding: 8px 20px;
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.primary};
      transition: all 0.3s ease-in;

      div:first-child {
            height: 18px;
            width: 18px;

            i {
                  height: 18px;
                  width: 18px;
            }
      }

      &:hover {
            background-color: ${(props) => props.theme.paint};
      }
`;
