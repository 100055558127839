import styled, { css, keyframes } from "styled-components";
import { WarrentyClaim } from "../warrenty/styles";

//banner section css
export const CenterCss = css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
`;
export const BannerSection = styled.section`
      position: relative;
      --animation-speed: 200ms;
      --animation-delay: 100ms;
      background: url("/images/png/logo/banner.png");
      height: 100vh;
      background-size: contain;
      background-position: top left;
      background-repeat: no-repeat;
      ${(props) =>
            css`
                  --lunfest-primary-color: #9d99f2;
                  --char-index: ${props.charIndex || "0"};
                  --char-total: 15;
                  --word-total: 2;
                  --word-center: calc((var(--word-total) - 1) / 2);
                  --char-center: calc((var(--char-total) - 1) / 2);
                  --line-center: calc((var(--line-total) - 1) / 2);
                  --color-text: var(--lunfest-primary-color);
                  --color-bg-text: #ffffff;
            `}
`;

const colorCycle = keyframes`
        0% {
            color: rgba(255, 255, 255, 0);
        }
        15% {
            color: var(--color-bg-text);
        }
        50% {
            color: var(--color-text);
        }
        80% {
            color: var(--color-text);
        }
        95% {
            color: var(--color-bg-text);
        }
        100% {
            color: rgba(255, 255, 255, 0);
        }
`;
const popOut = keyframes`
0%{
    transform: translate(0em, 0em);
}

35% {
    transform: translate(0.025em, -0.05em);
}
70%{
    transform: translate(0em, 0em);
}
100%{
    transform: translate(0em, 0em);
}
`;
const popOut2 = keyframes`
0%, {
    transform: translate(0em, 0em);
}
35% {
    transform: translate(0.05em, -0.1em);
}
70% {
   transform: translate(0em, 0em);
}
100%{
   transform: translate(0em, 0em);
}
`;
export const WordContainer = styled.div`
      h3 {
            font-family: var(--italic-font);
      }
      .second {
            margin-left: 30px;
      }
      h4 {
            font-size: 27px;
            color: ${(props) => props.theme.primary};
      }
      span {
            font-weight: bold;
            font-style: italic;
            font-family: var(--italicFont);
            font-size: 50px;
            letter-spacing: 3px;
            --char-percent: calc(var(--char-index) / var(--char-total));
            --char-offset: calc(var(--char-index) - var(--char-center));
            --distance: calc(
                  (var(--char-offset) * var(--char-offset)) / var(--char-center)
            );
            --distance-sine: calc(var(--char-offset) / var(--char-center));
            --distance-percent: calc((var(--distance) / var(--char-center)));
            display: inline-block;
            position: relative;
            color: var(--color-text);
            animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1), linear;
            animation-iteration-count: infinite;
            animation-duration: calc(
                  var(--animation-speed) * calc(var(--char-total))
            );
            animation-delay: calc(var(--animation-delay) * var(--char-index));
            animation-name: ${colorCycle}, ${popOut};
            &:before {
                  content: attr(data-char);
                  position: absolute;
                  top: 0;
                  left: 0;
                  transition: inherit;
                  -webkit-user-select: none;
                  user-select: none;
                  color: inherit;
                  visibility: visible;
                  z-index: 1;
                  animation: inherit;
                  animation-name: ${popOut2}, ${colorCycle};
            }
            &:after {
                  content: attr(data-char);
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: #161a1d;
                  z-index: 2;
                  transition: color 0.4s;
                  user-select: none;
                  visibility: visible;
                  animation-name: ${popOut};
            }
      }
`;

export const BannerContainer = styled.div`
      width: 40%;
      position: absolute;
      right: 150px;
      top: 0;
      padding: 130px 0px 0px 0px;

      @media screen and (max-width: 578px) {
            width: 100%;
            position: relative;
            right: 0;
            padding: 50px 20px 20px;
            background-color: white;
      }
`;
export const BannerBackground = styled.div``;
export const BannerContent = styled.div``;
export const BannerQuote = styled.div``;
export const BannerSliderContainer = styled.div`
      position: relative;

      ${BannerBackground} {
            position: absolute;
            top: -5px;
            left: 50px;
            z-index: -1;
      }
`;

// banner slider css
export const SliderContent = styled.div.attrs(() => ({
      className: "w-100 d-flex justfiy-content-center",
}))``;
export const SliderImage = styled.div.attrs(() => ({
      className: "w-100 d-flex justify-content-center",
}))`
      img {
            height: 220px;
            width: auto;
      }
`;

const Rotate = keyframes`
0%{
    transform: rotate(30deg);
}
25%{
    transform: rotate(90deg);
}
50%{
    transform: rotate(100deg);
}
75%{
    transform: rotate(130deg);
}
100%{
    transform: rotate(180deg);
}
`;
export const BannerPalatte = styled.div`
      position: absolute;
      top: 20px;
      right: 0;
      overflow: hidden;

      img {
            width: 100px;
            height: auto;
            animation: ${Rotate} 3s linear infinite;
      }
`;

// about us section
export const AboutSection = styled.div``;
export const AboutImage = styled.div`
      @media screen and (max-width: 568px) {
            text-align: center;

            img {
                  height: 220px;
                  width: 80%;
                  object-fit: contain;
            }
      }
`;
export const AboutTitle = styled.div`
      h2 {
            font-weight: 600;
            text-transform: uppercase;
            color: ${(props) => props.theme.primary};
            line-height: 1em;
            font-size: 18px;
      }
      h3 {
            font-weight: 600;
            font-size: 35px;
            color: ${(props) => props.theme.paint};
            line-height: 1.7em;
      }

      @media screen and (max-width: 568px) {
            h2 {
                  font-size: 16px;
            }
            h3 {
                  font-size: 24px;
            }
      }
`;

export const AboutDescription = styled.div``;

export const AboutButton = styled.div`
      a {
            display: inline-block;
            color: #ffffff !important;
            font-size: 14px;
            font-weight: 600 !important;
            text-transform: uppercase !important;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-blend-mode: normal;
            background-image: url("/images/png/background.png");
            background-color: rgba(0, 0, 0, 0);
            text-decoration: none;
            padding: 16px 40px !important;
            transition: padding 300ms ease 0ms;
            position: relative;

            .material-symbols-outlined {
                  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0,
                        "opsz" 24;
            }
            span {
                  margin-left: -1em;
                  left: auto;
                  top: 50%;
                  transform: translateY(-50%);
                  transition: all 300ms ease 0ms;
                  opacity: 0;
                  position: absolute;
            }
            &:hover {
                  padding-right: 60px !important;
                  span {
                        left: auto;
                        margin-left: 0.3em;
                        opacity: 1;
                  }
            }
      }

      @media screen and (max-width: 578px) {
            a {
                  padding: 8px 20px !important;
            }
      }
`;
export const AboutBottomImage = styled.div`
      ${CenterCss}
      justify-content: flex-start;
      img {
            border-bottom-right-radius: 550px;
      }

      @media screen and (max-width: 568px) {
            display: none;
      }
`;
export const AboutContent = styled.div`
      /* margin-left: 150px; */
      padding-top: 80px;
      ${AboutImage} {
            ${CenterCss}
            justify-content: flex-end;
      }

      ${AboutDescription} {
            width: 90%;
            margin-bottom: 30px;
            p {
                  color: #555;
            }
      }
      ${AboutButton} {
            a {
                  display: inline-block;
                  color: #ffffff !important;
                  font-size: 14px;
                  font-weight: 600 !important;
                  text-transform: uppercase !important;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-blend-mode: normal;
                  background-image: url("/images/png/background.png");
                  background-color: rgba(0, 0, 0, 0);
                  text-decoration: none;
                  padding: 16px 40px !important;
                  transition: padding 300ms ease 0ms;
                  position: relative;

                  .material-symbols-outlined {
                        font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0,
                              "opsz" 24;
                  }
                  span {
                        margin-left: -1em;
                        left: auto;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: all 300ms ease 0ms;
                        opacity: 0;
                        position: absolute;
                  }
                  &:hover {
                        padding-right: 60px !important;
                        span {
                              left: auto;
                              margin-left: 0.3em;
                              opacity: 1;
                        }
                  }
            }
      }

      @media screen and (max-width: 568px) {
            margin-left: 0;
            padding: 0 20px;

            h5 {
                  font-size: 32px;
                  line-height: 1em;
            }

            ${AboutDescription} {
                  text-align: justify;
                  width: 100%;
            }
      }
`;
// Category section css

export const CategorySection = styled.div`
      /* margin-top: 100px;
      padding-left: 0px; */
      margin: 5rem 0;

      @media screen and (max-width: 568px) {
            padding-left: 0;
            padding: 20px;
            margin-top: 20px;
      }
      .nav-link {
            &:focusvisible {
                  outline: 0;
                  box-shadow: none;
            }
      }
      .nav-link {
            &:focus {
                  color: ${(props) => props.theme.white};
            }
      }
`;
export const CategoryHeading = styled.div`
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 568px) {
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: left;
            align-items: start;
      }
`;
export const CategoryTab = styled.div`
      button {
            background-color: #000;
            color: #fff;
            margin-right: 20px;
            width: 150px;
            padding: 13px 0px;
            font-size: 15px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            transition: 0.3s all ease-in-out;
            &:hover {
                  color: #fff;
                  border-top-left-radius: 0px;
                  border-bottom-right-radius: 0px;
                  border-top-right-radius: 10px;
                  border-bottom-left-radius: 10px;
                  transition: 0.3s all ease-in-out;
            }
            &:focusvisible {
                  outline: 0;
                  box-shadow: none;
            }
            &:focus {
                  color: ${(props) => props.theme.white};
            }
      }

      @media screen and (max-width: 568px) {
            .nav {
                  flex-direction: row;
                  flex-wrap: nowrap;
                  overflow: scroll;
                  padding-bottom: 16px;
            }
      }
`;
export const CategoryProductImage = styled.div`
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      height: 220px;
      align-items: center;
      border-radius: 20px;

      img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 20px;
            transition: transform 0.3s ease-in-out;
      }
`;
export const CategoryProductContainer = styled.div`
      a {
            color: #212529;
      }
      /* background: #f1f1f1; */
      padding: 10px;
      h4 {
            font-size: 20px;
            text-align: center;
      }
      &:hover {
            a {
                  color: #820e4d;
            }
            ${CategoryProductImage} {
                  img {
                        /* transform: scale(1.1); */
                        transition: transform 0.3s ease-in-out;
                  }
            }
      }
`;
export const CategoryImage = styled.div`
      margin-top: -100px;
      img {
            border-top-right-radius: 100px;
      }

      @media screen and (max-width: 568px) {
            display: none;
      }
`;

// Visualizer section css

export const VisualizerSection = styled.div`
      background-image: linear-gradient(to left, #df6c74, 1%, #7e2c2e);
      padding: 50px 0px;
`;
export const VisualizerImage = styled.div``;
export const VisualizerContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      gap: 20px;
      p {
            color: #e2d7df;
      }
      a {
            background-color: ${(props) => props.theme.white};
            padding: 14px 20px;
            color: #7e2c2e;
            transition: all 0.3s ease-in;

            &:hover {
                  color: ${(props) => props.theme.primary};
            }
      }

      @media screen and (max-width: 578px) {
            align-items: start;
            justify-content: start;
            margin-top: 20px;
      }
`;
export const VisualizerHeading = styled.div`
      @media screen and (max-width: 578px) {
            h2 {
                  margin: 0px;
            }
      }
`;
export const VisualizerDescription = styled.div``;
export const VisualizerButton = styled.div``;

// Color section css
export const ColorSection = styled.div`
      margin: 5rem 0;

      @media screen and (max-width: 568px) {
            margin: 2rem 0;
            padding: 20px;

            .container {
                  padding: 0;
            }

            .row {
                  gap: 0;

                  .col-sm-12 {
                        padding: 0;
                  }
            }
      }
`;
export const ColorImage = styled.div`
      display: flex;
      gap: 10px;
      img {
            height: 150px;
            width: 150px;
            object-fit: cover;
            border-radius: 50px 0px 50px 0px;

            @media screen and (max-width: 568px) {
                  height: 120px;
                  width: 120px;
            }
      }

      @media screen and (max-width: 568px) {
            gap: 0;
            width: 100%;
      }
`;

export const ColorFirstTop = styled.div`
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 0px 0px 10px 0px;
      height: 263px;

      @media screen and (max-width: 568px) {
            height: 180px;
      }
`;
export const GradientAnimation = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;
export const ColorFirstBottom = styled.div`
      background: ${(props) =>
            props.linearGradient
                  ? " linear-gradient(-45deg, #ee7752, #e73c7e, #7E2C2E, #D90016)"
                  : " linear-gradient(90deg, rgba(246,147,65,1) 4%, rgba(245,173,68,1) 51%, rgba(248,196,78,1) 100%)"};
      background-size: 400% 400%;
      animation: ${GradientAnimation} 15s ease infinite;
      border-radius: 100px 0px 100px 0px;
      height: 263px;
      display: flex;
      justify-content: ${(props) => props.horizontal || "flex-start"};
      align-items: ${(props) => props.vertical || "flex-start"};

      @media screen and (max-width: 568px) {
            height: 180px;
      }
`;
export const ColorSecondImageContainer = styled.div`
      width: 50%;
`;
export const ColorFirstImageContainer = styled.div`
      width: 50%;
`;
export const ColorContainer = styled.div`
      margin-left: 30px;
      ${AboutContent} {
            margin-left: 0px;
      }

      @media screen and (max-width: 568px) {
            margin-left: 0;
            margin-top: 2rem;
      }
`;

export const ColorContent = styled.div`
      margin-top: 40px;
`;
export const ColorCollectionContainer = styled.div`
      display: grid;
      place-items: center;
      overflow-y: hidden;
      button {
            position: relative;
            width: 232px;
            height: 64px;
            line-height: 64px;
            border: 0;
            background: none;
            color: #fff;
            font-weight: 700;
            font-size: 1.25rem;
            font-family: inherit;
            cursor: pointer;
      }

      button svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: visible;
            z-index: -1;
      }
      h4 {
            font-size: 15px;
            color: #666;
            text-align: center;
      }
`;
export const ColorShadeBox = styled.div`
      width: 70px;
      height: 70px;
      border-radius: 50px 0px 50px 0px;
      background-color: ${(props) => props.shade};
      margin-bottom: 10px;
`;
// subscription section css
export const SubscriptionSection = styled.div`
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      ${WarrentyClaim} {
            margin-top: -56px;
            margin-bottom: 0;
      }

      @media screen and (max-width: 578px) {
            img {
                  height: 320px;
                  object-fit: cover;
            }
      }
`;
export const SubscriptionWrapper = styled.div`
      width: 100%;
      height: 100%;
      background: url("/images/abstract-colorful-splash-3d-background-generative-ai-background.jpg");
      background-position: center;
      background-size: cover;
      position: relative;
      z-index: -2;
      padding: 20px 40px;
`;
export const SubscriptionContent = styled.div`
      position: relative;
      padding: 20px 40px;
      &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: -1;
            opacity: 0.3;
      }
`;
export const SubscriptionWrapperForm = styled.div``;
export const SubscriptionHeading = styled.div`
      position: relative;
      &:after {
            content: url(/images/img/color/plane.png);
            position: absolute;
            right: 159px;
            top: -150px;
            width: 20px;
            height: 20px;
      }
      h3 {
            ${"" /* font-size: 30px; */}
            text-transform:uppercase;
            font-weight: 600;
            position: relative;
            z-index: 100;
      }
`;
export const SubscriptionForm = styled.div`
      width: 100%;
      form {
            width: 100%;
      }
      .form__group {
            background: transparent;
            padding: 2px 0px;
            .form-control {
                  padding: 0;
                  border: none;
                  border-radius: 0px;
                  border-bottom: 1px solid rgb(163, 96, 79);
                  background-color: transparent;

                  &:focus {
                        box-shadow: none;
                  }
            }
            .btn-primary {
                  background: rgb(163, 96, 79);
                  color: #fff;
                  padding: 15px 25px 12px;
                  border: none;
                  font-size: 13px;
                  text-transform: uppercase;
                  border-radius: 50px;
                  transition: all 0.3s ease-in;
                  display: flex;
                  align-items: center;

                  div {
                        line-height: auto;
                  }

                  &:hover {
                        opacity: 0.9;
                  }
            }
      }
`;

export const TestimonialSection = styled.section`
      background-image: url("/images/banner.png");
      background-repeat: no-repeat;

      h2 {
            font-size: 30px;
            color: ${(props) => props.theme.white};
            margin-bottom: 16px;
      }

      p {
            color: ${(props) => props.theme.paint};
      }
      .testimonial__slide-content {
            padding: 0 10px;
      }
      .testimonial_wrapper {
            padding-top: 20px;
      }
      .testimonial_card {
            padding: 30px;
            position: relative;
            color: #e5e2e2;
            margin: 40px 0;
            transition: 0.3s transform linear;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 224px;

            &:hover {
                  transform: translateY(-10px);
                  transition: 0.3s transform linear;
            }

            .testimonial__image-container {
                  display: flex;
                  position: absolute;
                  margin: 0 auto;
                  align-items: center;
                  width: auto;
                  height: 100px;
                  gap: 20px;
                  top: -23px;
                  left: 120px;
                  bottom: 0;
                  right: 0;
                  z-index: 1;
                  /* box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */

                  .testimonial__img {
                        width: 100px;
                        height: 100px;
                        border: 3px solid ${(props) => props.theme.primary};
                        border-radius: 50%;

                        img {
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 50%;
                        }
                  }

                  .testimonial__label {
                        text-align: start;
                        width: calc(100% - 100px);

                        h3 {
                              color: azure;
                        }
                  }
            }
      }
      .testimonial__body {
            margin-top: 93px;
            width: 67%;

            p {
                  text-align: justify;
                  font-size: 15px;
                  color: #ede5e5;
                  margin-bottom: 0;
            }

            h6 {
                  text-align: center;
                  font-weight: 600;
                  letter-spacing: 1px;
                  color: #e3a005;
            }
      }
      .testimonial__star i {
            font-size: 22px;
            color: #e3a005;
      }
      .swiper {
            padding: 10px 10px;
      }
      .swiper-pagination-bullet-active {
            opacity: var(--swiper-pagination-bullet-opacity, 1);
            background: ${(props) => props.theme.primary};
      }
      h3 {
            font-size: 20px;
            color: ${(props) => props.theme.paint};
      }
      .testimonial__overlay {
            position: absolute;
            z-index: -1;
            top: 30px;

            img {
                  width: 533px;
            }
      }

      @media screen and (max-width: 578px) {
            .testimonial_card {
                  padding: 0;

                  .testimonial__image-container {
                        left: 0;
                  }
            }
            .testimonial__body {
                  width: 100%;
            }
      }
`;

export const TestimonialSummary = styled.p`
      color: #e2d7df;
      text-align: center;
`;
