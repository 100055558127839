import React from "react";
import { Col, Row } from "react-bootstrap";
import {
      LeftSidebarContainer,
      ProductCategoryContent,
      ProductCategoryImage,
} from "../styles";
import { AboutButton } from "../../home/styles";
import { Link } from "react-router-dom";

const LeftSidebar = ({ image, title, link, desc, num }) => {
      return (
            <>
                  <LeftSidebarContainer left={true}>
                        <Row className="g-0">
                              <Col lg={7} className="true">
                                    <ProductCategoryContent left={true}>
                                          <span>{num}</span>
                                          <h3>{title}</h3>
                                          <p className="text-end">{desc}</p>
                                          <AboutButton>
                                                <Link to={link}>
                                                      View Products
                                                      <span class="material-symbols-outlined">
                                                            arrow_right_alt
                                                      </span>
                                                </Link>
                                          </AboutButton>
                                    </ProductCategoryContent>
                              </Col>
                              <Col lg={5}>
                                    <ProductCategoryImage left={true}>
                                          <img
                                                src={image}
                                                alt={title}
                                                className="img-fluid rounded-5"
                                                width="100%"
                                                height="100%"
                                          />
                                    </ProductCategoryImage>
                              </Col>
                        </Row>
                  </LeftSidebarContainer>
            </>
      );
};

export default LeftSidebar;
