import { Container } from "react-bootstrap";
import styled from "styled-components";

export const InspirationContainer = styled(Container)`
      padding: 50px 0px 100px 0px;

      @media screen and (max-width: 578px) {
            padding-bottom: 20px;
      }
`;
export const InspirationTab = styled.ul`
      padding-left: 0px;

      @media screen and (max-width: 578px) {
            overflow-x: scroll;
            padding: 20px;
            padding-bottom: 0;
            padding-top: 0;

            &::-webkit-scrollbar {
                  width: 0;
            }
      }
`;
export const InspirationTabNav = styled.li`
      min-width: 150px;
      background: ${(props) => props.theme.primary};
      padding: 10px 20px;
      color: ${(props) => props.theme.white};
      display: flex;
      justify-content: center;
      font-size: 15px;
      border-radius: 0px 8px 0px 8px;
      transition: background 0.3s ease-in-out;
      &:hover {
            cursor: pointer;
            background: linear-gradient(to left, #fc840a 1%, #e44b07);
            transition: background 0.3s ease-in-out;
      }

      @media screen and (max-width: 578px) {
            min-width: fit-content;
      }
`;
export const InspirationItem = styled.div`
      img {
            border-radius: 0px 8px 0px 8px;
      }
`;
