import React, { useContext } from "react";
import { VisualizerContainer } from "./styles";
import MetaContext from "../../stores/MetaContext";

const Visualizer = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("color-visualizer");

      return (
            <>
                  <h1 className="d-none">
                        Color Visualizer Coming Soon - Yeti paints Nepal
                  </h1>
                  <VisualizerContainer>
                        <img
                              src="/images/img/commingsoon/commingsoon.png"
                              alt="Color Visualizer Coming Soon - Yeti paints Nepal"
                              className="img-fluid"
                              width="100%"
                              height="100%"
                        />
                  </VisualizerContainer>
            </>
      );
};

export default Visualizer;
