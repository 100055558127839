import React from 'react';
import {
      AboutBottomImage,
      AboutButton,
      AboutContent,
      AboutDescription,
      AboutImage,
      AboutSection,
      AboutTitle
} from '../styles';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AboutBox = () => {
      return (
            <>
                  <AboutSection>
                        <Container className=" px-0">
                              <Row className="g-0">
                                    <Col lg={7}>
                                          <AboutContent>
                                                <AboutTitle>
                                                      <h2>[ ABOUT Yeti ] </h2>
                                                      <h3>
                                                            Committed to Quality
                                                            Since 1994
                                                      </h3>
                                                </AboutTitle>
                                                <AboutDescription>
                                                      <p>
                                                            Yeti Paints Nepal
                                                            Pvt. Ltd is a paint
                                                            manufacturing
                                                            company that was
                                                            established in 1994
                                                            (2051 B.S.) and is
                                                            located in the
                                                            central part of
                                                            Nepal, Hetauda. The
                                                            company has two
                                                            production units in
                                                            Hetauda Industrial
                                                            District and a
                                                            workforce of about
                                                            85 workmen,
                                                            including 50 staff
                                                            and 35 direct
                                                            labors. The
                                                            company’s work
                                                            premises are built
                                                            on a land area of
                                                            12.41 ropani and are
                                                            equipped with a
                                                            laboratory to carry
                                                            out necessary tests
                                                            for paint
                                                            manufacturing. The
                                                            company has an
                                                            annual production
                                                            capacity of 9000
                                                            MT/KL of different
                                                            varieties of paints
                                                            and paint-related
                                                            products.
                                                      </p>
                                                </AboutDescription>
                                                <AboutButton>
                                                      <Link to="/about-us">
                                                            Learn More
                                                            <span class="material-symbols-outlined">
                                                                  arrow_right_alt
                                                            </span>
                                                      </Link>
                                                </AboutButton>
                                          </AboutContent>
                                    </Col>
                                    <Col lg={5}>
                                          <AboutImage>
                                                <img
                                                      src="/images/bg.jpg"
                                                      alt="About Yeti Paints Nepal Pvt. Ltd."
                                                      className="img-fluid"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </AboutImage>
                                    </Col>
                              </Row>
                              <AboutBottomImage></AboutBottomImage>
                        </Container>
                  </AboutSection>
            </>
      );
};

export default AboutBox;
