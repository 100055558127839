import React from "react";
import {
      PolicyHeaderContainer,
      PolicyHeaderContent,
      PolicyHeaderLink,
} from "./styles";
import { Link } from "react-router-dom";
import { AboutButton } from "../../../frontend/home/styles";
import { Col } from "react-bootstrap";

const PolicyHeader = () => {
      return (
            <>
                  <PolicyHeaderContainer className="mb-4 row align-items-center justify-content-center">
                        <Col lg={10}>
                              <PolicyHeaderContent>
                                    <PolicyHeaderLink>
                                          <AboutButton>
                                                <Link to="/terms-and-condition">
                                                      Terms & Conditions
                                                      <span class="material-symbols-outlined">
                                                            arrow_right_alt
                                                      </span>
                                                </Link>
                                          </AboutButton>
                                    </PolicyHeaderLink>
                                    <PolicyHeaderLink>
                                          <AboutButton>
                                                <Link to="/privacy-policy">
                                                      Privacy Policy
                                                      <span class="material-symbols-outlined">
                                                            arrow_right_alt
                                                      </span>
                                                </Link>
                                          </AboutButton>
                                    </PolicyHeaderLink>
                                    <PolicyHeaderLink>
                                          <AboutButton>
                                                <Link to="/dealer-policy">
                                                      Dealer Policy
                                                      <span class="material-symbols-outlined">
                                                            arrow_right_alt
                                                      </span>
                                                </Link>
                                          </AboutButton>
                                    </PolicyHeaderLink>
                              </PolicyHeaderContent>
                        </Col>
                  </PolicyHeaderContainer>
            </>
      );
};

export default PolicyHeader;
