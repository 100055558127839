import styled from 'styled-components';

export const FaqFormContainer = styled.div`
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 50px 30px;
      .form-control:focus {
            border-color: #e238aa;
            outline: 0;
            box-shadow: none;
      }
      input {
            font-size: 14px;
            color: #555;
      }
      textarea {
            font-size: 14px;
            color: #555;
      }
      button {
            color: #ffffff;
            cursor: pointer;
            font-size: 14px;
            padding: 13px 20px;
            border-radius: 5px;
            text-transform: uppercase;
            margin: 10px 0 0 0;
            background: linear-gradient(to right, #d90117, #d90117);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f73c95', endColorstr='#9105ea',GradientType=1 );
            color: #fff;
            border: 0;
            transition: all 300ms linear 0s;
            &:hover {
                  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.3);
                  background: linear-gradient(
                        to right,
                        #d90117 50%,
                        #d90117 100%
                  );
                  transition: all 300ms linear 0s;
            }
      }
`;

export const FaqFormTitle = styled.h3`
      color: #d90117;
      font-size: 20px;
      line-height: 24px;
`;
