import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { ContactFormWrapper, FormIcon } from '../styles';

const ContactFormItem = ({ title, icon, handleChange, type, classes }) => {
      return (
            <>
                  <Form.Group as={Col} lg={classes ? classes : 6}>
                        <ContactFormWrapper
                              className={
                                    type === 'textarea' &&
                                    'align-items-start pt-1'
                              }
                        >
                              <FormIcon>{icon}</FormIcon>
                              <Form.Control
                                    type={type ? type : 'text'}
                                    placeholder={title}
                                    onChange={(event) =>
                                          handleChange(event.target.value)
                                    }
                                    as={
                                          type === 'textarea'
                                                ? 'textarea'
                                                : 'input'
                                    }
                                    rows={type === 'textarea' ? 5 : ''}
                                    className="border-0"
                              />
                        </ContactFormWrapper>
                  </Form.Group>
            </>
      );
};

export default ContactFormItem;
