import React, { useContext } from "react";
import BreadcrumbBox from "../../components/common/BreadcrumbBox";
import {
      ContactUsContainer,
      ContactUsContent,
      ContactUsContentIcon,
      ContactUsContentInfo,
      ContactUsContentWrapper,
      ContactUsHeading,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import LocationIcon from "../../components/icons/LocationIcon";
import MobileIcon from "../../components/icons/MobileIcon";
import MailIcon from "../../components/icons/MailIcon";
import ContactMap from "./ContactMap";
import ContactForm from "./ContactForm";
import ContactBranch from "./ContactBranch";
import MetaContext from "../../stores/MetaContext";

const ContactPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("contact-us");
      return (
            <>
                  <BreadcrumbBox title="Contact Us" background="product" />
                  <ContactUsContainer>
                        <Row className="g-3">
                              <Col lg={6}>
                                    <ContactUsContent>
                                          <ContactUsHeading className="mb-4">
                                                <span>CONTACT US</span>
                                                <h2 className="mt-3">
                                                      Have questions?
                                                      <br />
                                                      Get in touch!
                                                </h2>
                                                <p>
                                                      Reach us to learn more
                                                      about dealers, warrenty
                                                      policy and any type of
                                                      customization
                                                </p>
                                          </ContactUsHeading>
                                          <ContactUsContentWrapper>
                                                <ContactUsContentIcon>
                                                      <LocationIcon />
                                                </ContactUsContentIcon>
                                                <ContactUsContentInfo>
                                                      Hetauda, Nepal
                                                </ContactUsContentInfo>
                                          </ContactUsContentWrapper>
                                          <ContactUsContentWrapper>
                                                <ContactUsContentIcon>
                                                      <MobileIcon />
                                                </ContactUsContentIcon>
                                                <ContactUsContentInfo>
                                                      +977 - 9702649540
                                                </ContactUsContentInfo>
                                          </ContactUsContentWrapper>
                                          <ContactUsContentWrapper>
                                                <ContactUsContentIcon>
                                                      <MailIcon />
                                                </ContactUsContentIcon>
                                                <ContactUsContentInfo>
                                                      yetipaints@gmail.com
                                                </ContactUsContentInfo>
                                          </ContactUsContentWrapper>
                                    </ContactUsContent>
                              </Col>
                              <Col lg={6}>
                                    <ContactForm />
                              </Col>
                        </Row>
                        <ContactBranch />
                        <ContactMap />
                  </ContactUsContainer>
            </>
      );
};

export default ContactPage;
