import React from 'react';
import { Col, Form } from 'react-bootstrap';

const SearchForm = ({ categories, setSearch, loading }) => {
      return (
            <>
                  <Form.Group lg={8} md={8} as={Col}>
                        <Form.Select
                              onChange={(event) =>
                                    setSearch(event.target.value)
                              }
                        >
                              <option>Select Category</option>
                              {!loading ? (
                                    categories?.length > 0 ? (
                                          <>
                                                <option value="All">
                                                      All Files
                                                </option>
                                                {categories?.map(
                                                      (category, index) => (
                                                            <option
                                                                  value={
                                                                        category.id
                                                                  }
                                                                  key={index}
                                                            >
                                                                  {
                                                                        category.title
                                                                  }
                                                            </option>
                                                      )
                                                )}
                                          </>
                                    ) : (
                                          <option>No Category Found</option>
                                    )
                              ) : null}
                        </Form.Select>
                  </Form.Group>
            </>
      );
};

export default SearchForm;
