import React, { useContext, useEffect, useState } from 'react';
import BannerBox from './components/BannerBox';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const AboutUs = () => {
      const [loading, setLoading] = useState(false);
      const [teams, setTeams] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/teams`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTeams(response.data.teams);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('about-us');

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta && metaCtx.meta.seo_title}
                  </h1>
                  <BannerBox loading={loading} teams={teams} />
            </>
      );
};

export default AboutUs;
